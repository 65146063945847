import React from "react";
import { Link } from "react-router-dom";
import colors from "../../colors/colors";
import { Row, Col } from "react-bootstrap";
import menuItemWidth from "./menuItemWidth";

export default class SubMenu extends React.Component {
  constructor(props) {
    super(props);

    if (!window.hideSubMenu) {
      window.hideSubMenu = [];
    }

    window.hideSubMenu.push(function () {
      this.props.setShowBranListNull();
    }.bind(this));


    this.state = {
      showSearch: false,
      brandsA: [],
      brandsB: [],
      brandsC: [],
      showBrandList: false,
      onMouseEnterTimeout: null,
    };
  }

  spliceBrands() {
    // if (this.props.category.brands.length > 5) {
    let brandsA = [], brandsB = [], brandsC = [];
    brandsA = this.props.category.brands;
    brandsA.sort(function (a, b) {
      if (a.brandName < b.brandName) { return -1; }
      if (a.brandName > b.brandName) { return 1; }
      return 0;
    })

    if (this.props.id != 0) {
      brandsB = brandsA.splice(Math.round(brandsA.length / 2));
    } else {
      brandsB = brandsA.slice(Math.ceil(brandsA.length / 3), Math.ceil(brandsA.length / 3 * 2));
      brandsC = brandsA.slice(Math.ceil(Math.ceil(brandsA.length / 3 * 2), brandsA.length));
      brandsA = brandsA.slice(0, Math.ceil(brandsA.length / 3));
    }
    this.setState({ brands: this.props.category.brands, brandsA: brandsA, brandsB: brandsB, brandsC: brandsC })
    // } else {
    //   this.setState({ brandsA: this.props.category.brands })
    // }
  }

  componentDidMount() {
    this.spliceBrands();
  }

  whiteHeight() {
    if (this.state.brandsC.length) {
      return 250;
    }
    switch (this.state.brandsA.length + this.findDoubleworders()) {
      case 8: return 310;
      case 5: return 240;
      case 4: return 210;
      case 3: return 170;
      case 2: return 130;
      default: return 100;
    }
  }

  findDoubleworders() {
    return 0;
    let dw = 0;
    this.state.brandsA.forEach(element => {
      if (element.brandName.includes(' ')) {
        dw++;
      }
    });
    return dw;
  }

  onMouseEnter() {
    this.props.showMenuBackgroundClass();

    setTimeout(() => {
      // debugger;
      let col = window.document.querySelector('#menu-col-' + this.props.id);
      let col2 = window.document.querySelector('#menu-col-' + parseInt(this.props.id + 1));

      if (col) {
        let line = window.document.querySelector('#menu-col-' + this.props.id + ' .brand-lists-container div:nth-child(1)');
        if (line) {
          line.style.width = (col.clientWidth + 25) + 'px';
        }
      }
      if (col2) {
        let line2 = window.document.querySelector('#menu-col-' + this.props.id + ' .brand-lists-container div:nth-child(2)');
        if (line2) {
          line2.style.width = (col2.clientWidth + 25) + 'px';
        }
      }
    }, 50);


    // clearTimeout(window.MenuTimeout);
    for (let i = 0; i < window.hideSubMenu.length; i++) {
      window.hideSubMenu[i]();
    }
    setTimeout(() => {
     this.props.setShowBranListNumber()

    }, 60)

  }

  render() {
    const menuItem = {
      color: this.props.showSubMenuBG == false && this.props.white ? colors.white : colors.black,
      fontSize: window.innerWidth < 1330 ? "15px" : '18px',
      fontFamily: "Montserrat",
      textAlign: "left",
      cursor: "pointer",
      lineHeight: "20px",
      // paddingRight: '47px',
    };

    const subMenuItem = {
      color: colors.black,
      fontSize: window.innerWidth < 1330 ? "12px" : '16px',
      fontFamily: "Montserrat",
      textAlign: "left",
      cursor: "pointer",
      lineHeight: "30px",
      position: 'relative',
      display: 'block',
      width: 'max-content'
      // paddingRight: '47px',
    };

    const menuItemActive = {
      color: this.props.showSubMenuBG == false && this.props.white ? colors.white : colors.black,
      fontSize: "18px",
      fontFamily: "Montserrat",
      textAlign: "left",
      cursor: "pointer",
    };

    const subMenuClass = this.props.showBrandList == this.props.id  ? 'subMenu  subMenuShown' : 'subMenu'
    return (
      <Col className='submenuContainer' id={"menu-col-" + this.props.id} style={{
        marginRight: '25px',
        display: 'inline-block',
        width: window.innerWidth < 1330 ? 'calc(' + menuItemWidth[this.props.id] + 'px + (100% - 501px)/5 - 5%)' : 'calc(' + menuItemWidth[this.props.id] + 'px + (100% - 501px)/5 - 2.9%)',
        padding: 0
      }} key={this.props.category.id} onMouseEnter={() => {

        if (window.menuLeaveCallback) {
          window.menuLeaveCallback = clearTimeout(window.menuLeaveCallback);
        }
        const menuEnterCallback = () => {
          this.onMouseEnter.bind(this);
            // this.onMouseEnter(); 
            setTimeout(this.onMouseEnter.bind(this), 200);
        };

        if (this.props.showSubMenuBG) {
          menuEnterCallback();
        } else {
          this.setState({
            onMouseEnterTimeout: setTimeout(menuEnterCallback, 300)
          })
        }
        

      }}
        onMouseLeave={() => {
          // window.menuLeaveCallback = setTimeout(() => {
            this.setState({ onMouseEnter: clearTimeout(this.state.onMouseEnterTimeout) });
          //   window.MenuTimeout = setTimeout(() => {
          //     this.setState({ showBrandList: false });
          //     this.props.hideMenuBackgroundClass();
          //   }, 0)
          // }, 200)
        }}
      >
        <Link

          style={{ textDecoration: "none" }}
          to={"/products/" + this.props.category.uri}
        >
          <h3

            onClick={() => {
              this.props.setType(this.props.category.type);
              this.props.setDefaultMeta();
              window.MenuTimeout = setTimeout(() => {
                this.props.setShowBranListNull();
                this.props.hideMenuBackgroundClass();
              }, 0)
            }}
            style={
              this.props.currentType == this.props.category.type ? menuItemActive : menuItem
            }
          >
            <span className={'menuItem'} dangerouslySetInnerHTML={{ __html: this.props.category.name.replace(' ', '<br />') }}
            ></span>
          </h3>
        </Link>
        {this.props.showSubMenuBG ? (
          <div
            className={subMenuClass}
            style={{
              width: "400%",
              // overflow: "hidden",
              maxHeight: "90vh",
              position: "relative",
              zIndex: "9",
              paddingTop: "35px",
              height: this.props.showBrandList == this.props.id ? (this.whiteHeight()) + 'px' : '0px',
              transition: '0.3s',
              transform: 'scaleY(0)',
              transformOrigin: 'top',
            }}
          >
            <Row style={{ marginLeft: '0px' }} className="brand-lists-container">
              <div className="width-animation" style={{ display: 'inline-block' }}>
                {this.state.brandsA.map((brand, id) =>
                  <Link
                    key={id}
                    style={{ textDecoration: "none", display: 'block', position: 'relative', whiteSpace: 'nowrap' }}
                    to={"/brand/" + brand.uri}
                  >
                    <h3
                      className= {this.props.showBrandList == this.props.id ? "ribbonMenuItem ribbonMenuItemShown" : "ribbonMenuItem "}
                      onClick={() => {
                        this.props.setCategory(brand);
                        this.props.setType(this.props.category.type);
                        window.MenuTimeout = setTimeout(() => {
                          this.props.setShowBranListNull();
                          this.props.hideMenuBackgroundClass();
                        }, 0)
                      }}
                      style={subMenuItem}
                    >
                      <span className='subMenuItem'>{brand.brandName.toLowerCase()}</span>
                    </h3>
                  </Link>
                )}
              </div>
              {this.state.brandsB.length > 0 ? <div className="width-animation" style={{ display: 'inline-block' }}>
                {this.state.brandsB.map((brand, id) =>
                  <Link
                    key={id}
                    style={{ textDecoration: "none", display: 'block', position: 'relative', whiteSpace: 'nowrap' }}
                    to={"/brand/" + brand.uri}
                  >
                    <h3
                      className= {this.props.showBrandList == this.props.id ? "ribbonMenuItem ribbonMenuItemShown" : "ribbonMenuItem "}
                      onClick={() => {
                        this.props.setCategory(brand);
                        this.props.setType(this.props.category.type);
                        window.MenuTimeout = setTimeout(() => {
                          this.props.setShowBranListNull();
                          this.props.hideMenuBackgroundClass();
                        }, 0)
                      }}
                      style={subMenuItem}
                    >
                      <span className='subMenuItem'>{brand.brandName.toLowerCase()}</span>
                    </h3>
                  </Link>
                )}
              </div> : <></>}
              {this.state.brandsC.length > 0 ? <div style={{ display: 'inline-block' }}  >
                {this.state.brandsC.map((brand, id) =>
                  <Link
                    key={id}
                    style={{ textDecoration: "none", display: 'block', position: 'relative', whiteSpace: 'nowrap' }}
                    to={"/brand/" + brand.uri}
                  >
                    <h3
                      className= {this.props.showBrandList == this.props.id ? "ribbonMenuItem ribbonMenuItemShown" : "ribbonMenuItem "}
                      onClick={() => {
                        this.props.setCategory(brand);
                        this.props.setType(this.props.category.type);
                        window.MenuTimeout = setTimeout(() => {
                          this.props.setShowBranListNull();
                          this.props.hideMenuBackgroundClass();
                        }, 0)
                      }}
                      style={subMenuItem}
                    >
                      <span className='subMenuItem'>{brand.brandName.toLowerCase()}</span>
                    </h3>
                  </Link>
                )}
              </div> : <></>}
            </Row>
          </div>
        ) : (
            <></>
          )}
      </Col>
    );
  }
}
