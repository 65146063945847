import React from 'react';
import { ReactComponent as PayPal } from "../../svg/PayPal.svg";
import { Row, Col, Button } from 'react-bootstrap';
import UniversalInput from '../form/UniversalInput';
import colors from '../../colors/colors';
import CountrySelect from '../form/CountrySelect';
import {
    cartFetched,
    userFetched,
} from "../../actions";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Request from '../../api/Request';
import DeliveryCostCalculator from '../../models/DeliveryCostCalculator';
import { X } from 'react-bootstrap-icons';
import LoaderSpinner from '../general/LoaderSpinner';

class QuickBuyForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showForm: false,
            countryInputValue: '',
            nameInputValue: '',
            surnameInputValue: '',
            addressInputValue: '',
            extraAddressInputValue: '',
            postCodeInputValue: '',
            cityInputValue: '',
            stateInputValue: '',
            emailInputValue: '',
            VATnumberInputValue: '',
            countries: [],
            costs: {},
            exchange: {},
            hasBeenSubmited: false,
            goingToPayment: false,
        };
    }

    componentDidMount() {
        this.setState({
            countryInputValue: this.props.user.shipping_information && this.props.user.shipping_information.length ? this.props.user.shipping_information[0].country : (this.props.locale.country
                ? this.props.locale.country : ''),
            nameInputValue: this.props.user.shipping_information && this.props.user.shipping_information.length ? this.props.user.shipping_information[0].name : '',
            surnameInputValue: this.props.user.shipping_information && this.props.user.shipping_information.length ? this.props.user.shipping_information[0].surname : '',
            addressInputValue: this.props.user.shipping_information && this.props.user.shipping_information.length ? this.props.user.shipping_information[0].address : '',
            extraAddressInputValue: this.props.user.shipping_information && this.props.user.shipping_information.length ? this.props.user.shipping_information[0].extra_address : '',
            postCodeInputValue: this.props.user.shipping_information && this.props.user.shipping_information.length ? this.props.user.shipping_information[0].post_code : '',
            cityInputValue: this.props.user.shipping_information && this.props.user.shipping_information.length ? this.props.user.shipping_information[0].city : '',
            stateInputValue: '',
            emailInputValue: this.props.user.email,
            VATnumberInputValue: this.props.user.vat_number,
        });
        Request.getCountries(response => {
            this.setState({ countries: response });
        });
        Request.getExchange(response => {
            if (response.success) {
                this.setState({ exchange: response.exchangeRates });
            }
        });
    }

    componentDidUpdate(prevProps) {
        Request.getDeliveryCosts(response => {
            if (response.success && (!this.state.costs.length || response.deliveryCosts[0].UPS_price != this.state.costs[0].UPS_price)) {
                this.setState({ costs: response.deliveryCosts });
            }
        }, [{quantity: 1}], this.props.locale.country);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (Object.keys(prevProps.user).length == 0 && Object.keys(this.props.user).length != 0) {
            // console.log(this.props.user);
            this.setState({
                countryInputValue: this.props.user.shipping_information && this.props.user.shipping_information.length ? this.props.user.shipping_information[0].country : (this.props.locale.country
                    ? this.props.locale.country : ''),
                nameInputValue: this.props.user.shipping_information && this.props.user.shipping_information.length ? this.props.user.shipping_information[0].name : '',
                surnameInputValue: this.props.user.shipping_information && this.props.user.shipping_information.length ? this.props.user.shipping_information[0].surname : '',
                addressInputValue: this.props.user.shipping_information && this.props.user.shipping_information.length ? this.props.user.shipping_information[0].address : '',
                extraAddressInputValue: this.props.user.shipping_information && this.props.user.shipping_information.length ? this.props.user.shipping_information[0].extra_address : '',
                postCodeInputValue: this.props.user.shipping_information && this.props.user.shipping_information.length ? this.props.user.shipping_information[0].post_code : '',
                cityInputValue: this.props.user.shipping_information && this.props.user.shipping_information.length ? this.props.user.shipping_information[0].city : '',
                stateInputValue: '',
                emailInputValue: this.props.user.email,
                VATnumberInputValue: this.props.user.vat_number,
            });
        }
    }

    showForm() {
        this.props.onClick();
        this.setState({ showForm: true });
    }

    hideForm() {
        this.setState({ showForm: false });
    }

    handleCountryInputChange(event) {
        const { value } = event.target;
        this.setState({ countryInputValue: value });
    }

    handleNameInputChange(event) {
        const { value } = event.target;
        this.setState({ nameInputValue: value });
    }

    handleSurnameInputChange(event) {
        const { value } = event.target;
        this.setState({ surnameInputValue: value });
    }

    handleAddressInputChange(event) {
        const { value } = event.target;
        this.setState({ addressInputValue: value });
    }

    handleExtraAddressInputChange(event) {
        const { value } = event.target;
        this.setState({ extraAddressInputValue: value });
    }

    handlePostCodeInputChange(event) {
        const { value } = event.target;
        this.setState({ postCodeInputValue: value });
    }

    handleCityInputChange(event) {
        const { value } = event.target;
        this.setState({ cityInputValue: value });
    }

    handleStateInputChange(event) {
        const { value } = event.target;
        this.setState({ stateInputValue: value });
    }

    handleEmailInputChange(event) {
        const { value } = event.target;
        this.setState({ emailInputValue: value });
    }

    handleVATnumberInputChange(event) {
        const { value } = event.target;
        this.setState({ VATnumberInputValue: value });
    }

    validate() {
        return (
            this.state.nameInputValue != null &&
            this.state.nameInputValue.length > 1 &&
            this.state.surnameInputValue != null &&
            this.state.surnameInputValue.length > 1 &&
            this.state.addressInputValue != null &&
            this.state.addressInputValue.length > 1 &&
            this.state.postCodeInputValue != null &&
            this.state.postCodeInputValue.length > 1 &&
            this.state.cityInputValue != null &&
            this.state.cityInputValue.length > 1 &&
            this.state.emailInputValue != null &&
            this.state.emailInputValue.length > 1
        );
    }

    goToPayment() {
        // if (!this.validate()) {
        //     this.setState({ hasBeenSubmited: true });
        //     return;
        //   }
        // let countryCode = '';

        // if (this.state.countries.length) {
        //     let i = 0;
        //     for (i; i < this.state.countries.length; i++) {
        //         if (this.state.countries[i].name == this.props.locale.country) {
        //             countryCode = this.state.countries[i].alpha2Code;
        //             break;
        //         }
        //     }
        // }



        let costCalculator = new DeliveryCostCalculator(this.props.locale, this.state.costs, this.state.exchange);
        let deliveryPriceUPS = costCalculator.calculateCost('ups', false);
        let deliveryPriceDHL = costCalculator.calculateCost('dhl', false);
        const lowestCost = deliveryPriceUPS < deliveryPriceDHL ? deliveryPriceUPS : deliveryPriceDHL;

        let amount = 0.0;
        if (this.props.product) {
            amount = (this.props.product.price * this.props.quantity) + lowestCost
        } else {
            this.props.products.forEach(product => {
                amount += product.price * product.quantity;
            });
            amount += lowestCost;
        }

        this.setState({ goingToPayment: true });

        Request.createPayPalOrder({
            order: this.props.user ? {
                country: this.state.countryInputValue,
                invoice: false,
                delivery: deliveryPriceUPS < deliveryPriceDHL ? 'UPS' : 'DHL',
                payment_type: 'PayPal',
                currency: this.props.locale.currency.toUpperCase(),
            } : {},
            products: this.props.product ? [{ ...this.props.product, quantity: this.props.quantity }] : this.props.products,
        }, response => {
            if (response.success) {
                this.props.cartFetched([]);
                window.location.href = response.paymentButton;
            }
        });

    }

    render() {
        return <>{this.state.showForm ? (
            <div
                style={{
                    width: "100vw",
                    height: "100vh",
                    position: "fixed",
                    top: 0,
                    left: 0,
                    backgroundColor: "rgba(255, 255, 255, 0.5)",
                    zIndex: 999999999,
                }}
            >
                <div
                    style={this.props.isMobile ? {
                        backgroundColor: colors.white,
                        width: "320px",
                        height: "350px",
                        position: "absolute",
                        top: "calc( ( 100vh - 350px ) / 2 )",
                        left: "calc( ( 100vw - 320px ) / 2 )",
                        textAlign: "center",
                        padding: "30px 40px",
                        borderRadius: "10px",
                        zIndex: 999999999,
                        maxWidth: '100vw',
                    } :
                        {
                            backgroundColor: colors.white,
                            width: "700px",
                            height: "100vh",
                            position: "absolute",
                            // top: "calc( ( 100vh - 370px ) / 2 )",s
                            left: "calc( ( 100vw - 700px ) / 2 )",
                            textAlign: "center",
                            padding: "30px 100px",
                            borderRadius: "5px",
                            zIndex: 999999999,
                            overflowY: 'scroll',
                        }}
                >
                    {/* <Row>
                {this.props.noCol ? <></> : <Col md={1}></Col>}
                <Col style={{overflow: 'scroll', height: 'max-content'}}> */}

                    <X onClick={this.hideForm.bind(this)} color={colors.black} size={20} style={{ position: 'absolute', top: '10px', right: '10px', cursor: 'pointer' }} />
                    <CountrySelect
                        label="Country"
                        value={this.state.countryInputValue}
                        onChange={this.handleCountryInputChange.bind(this)}
                    />

                    <Row>
                        <Col>
                            <UniversalInput
                                label="Name"
                                value={this.state.nameInputValue}
                                onChange={this.handleNameInputChange.bind(this)}

                                isError={
                                    this.state.hasBeenSubmited &&
                                    (this.state.nameInputValue == null ||
                                        this.state.nameInputValue.length < 2)
                                }
                                errorMessage="This field cannot be empty"
                            />
                        </Col>
                        <Col>
                            <UniversalInput
                                label="Surname"
                                value={this.state.surnameInputValue}
                                onChange={this.handleSurnameInputChange.bind(this)}
                                isError={
                                    this.state.hasBeenSubmited &&
                                    (this.state.surnameInputValue == null ||
                                        this.state.surnameInputValue.length < 2)
                                }
                                errorMessage="This field cannot be empty"
                            />
                        </Col>
                    </Row>

                    <UniversalInput
                        label="Shipping Address"
                        value={this.state.addressInputValue}
                        onChange={this.handleAddressInputChange.bind(this)}
                        isError={
                            this.state.hasBeenSubmited &&
                            (this.state.addressInputValue == null ||
                                this.state.addressInputValue.length < 2)
                        }
                        errorMessage="This field cannot be empty"
                    />

                    <UniversalInput
                        label="Shipping Address (optional)"
                        value={this.state.extraAddressInputValue}
                        onChange={this.handleExtraAddressInputChange.bind(this)}
                    />
                    <Row>
                        <Col>
                            <UniversalInput
                                label="Postal Code"
                                value={this.state.postCodeInputValue}
                                onChange={this.handlePostCodeInputChange.bind(this)}

                                isError={
                                    this.state.hasBeenSubmited &&
                                    (this.state.postCodeInputValue == null ||
                                        this.state.postCodeInputValue.length < 2)
                                }
                                errorMessage="This field cannot be empty"
                            />
                        </Col>
                        <Col>
                            <UniversalInput
                                label="City"
                                value={this.state.cityInputValue}
                                onChange={this.handleCityInputChange.bind(this)}
                                isError={
                                    this.state.hasBeenSubmited &&
                                    (this.state.cityInputValue == null ||
                                        this.state.cityInputValue.length < 2)
                                }
                                errorMessage="This field cannot be empty"
                            />
                        </Col>
                    </Row>
                    {this.state.countryInputValue == "United States of America" ?
                        <UniversalInput
                            label="State"
                            value={this.state.stateInputValue}
                            onChange={this.handleStateInputChange.bind(this)}
                        /> : <></>}
                    <UniversalInput
                        label="Email"
                        value={this.state.emailInputValue}
                        onChange={this.handleEmailInputChange.bind(this)}
                        isError={
                            this.state.hasBeenSubmited &&
                            (this.state.emailInputValue == null ||
                                this.state.emailInputValue.length < 2)
                        }
                        errorMessage="This field cannot be empty"
                    />
                    <UniversalInput
                        label="VAT/Company number"
                        value={this.state.VATnumberInputValue}
                        onChange={this.handleVATnumberInputChange.bind(this)}
                    />
                    {/* </Col>
                <Col sm={12} style={{
                    padding: this.props.noCol ? 'none' : 'initial'
                }}> */}
                    {this.state.goingToPayment ?
                        <div style={{ display: 'flex' }}><span style={{ display: 'flex', margin: 'auto' }}><LoaderSpinner /><p>Please wait...</p></span></div> :
                        <Button
                            onClick={this.goToPayment.bind(this)}
                            className="addToCartButton"
                            style={{
                                width: "100%",
                                backgroundColor: colors.lightBlue,
                                borderColor: colors.lightBlue,
                                fontFamily: "Montserrat",
                                fontWeight: 900,
                                color: colors.white,
                                float: "right",
                            }}
                        >
                            Buy
                </Button>}
                    {/* </Col>
            </Row>  */}
                </div>
            </div>
        ) : (
                <>
                    {this.props.noCol ? this.theButton() : <Col>
                        {this.theButton()}
                    </Col>}

                </>
            )
        }</>;
    }
    theButton() {
        return (
            this.state.goingToPayment ?
                <div style={{ display: 'flex' }}><span style={{ display: 'flex', margin: 'auto', marginTop: '10px' }}><LoaderSpinner /><p style={{ marginBottom: 0 }}>Please wait...</p></span></div> :
                <Button
                    onClick={this.goToPayment.bind(this)}
                    className="payPalButton"
                    style={{
                        width: this.props.width ? this.props.width : '190px',
                        backgroundColor: colors.white,
                        borderColor: colors.lightBlue,
                        borderWidth: 2,
                        fontFamily: "Montserrat",
                        fontWeight: 900,
                        color: colors.white,
                        boxShadow: '0px 5px 15px -6px rgba(53, 116, 144, 0.66)',
                        height: '38px'
                    }}
                >
                    <PayPal style={{ marginTop: '-2px' }} />
                </Button>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user, // (1)
        productToShow: state.productToShow,
        locale: state.locale,
    };
};
const mapDispatchToProps = {
    userFetched,
    cartFetched,
}; // (2)
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(QuickBuyForm)
);