import React from "react";
import { Row, Col, Button, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import image9 from "../img/Image 9.png";
import image7 from "../img/Image 7.png";
import image8 from "../img/Image 8.png";
import image10 from "../img/Image 10.png";
import image11 from "../img/Image 11.png";
import productGroup from "../img/productGroup.png";
import intraline from "../img/intraline.png";
import colors from "../colors/colors";
import Request from "./../api/Request";
import { Parallax } from "react-scroll-parallax";
import ParalaxImage from "../components/ParalaxImage";
import { connect } from "react-redux";
import { isMobileFetched, whiteHeaderFetched, homePageFetched } from "../actions";
import RecommendetProductOnList from "../components/productList/RecommendetProductOnList";
import ForMan from "../components/home/ForMan";
import CTASlider from "../components/home/CTASlider";
import ProductCarousel from "../components/home/ProductCarousel";

class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
    };
  }

  componentDidMount() {
    this.props.homePageFetched(true);
    this.props.whiteHeaderFetched(true);

    Request.getProducts((response) => {
      if (response.success) {
        const products = response.products.filter(product => product.favouriteInMonth == true)
        this.setState({ products: products });
      }
    });
    
    this.changeOpacity();
  }

  componentWillUnmount() {
    this.props.whiteHeaderFetched(false);
    this.props.homePageFetched(false);
  }

  changeOpacity() {
    window.addEventListener('scroll', function () {
      let offsetElement = document.getElementById('changeOpacity');
      if (!offsetElement) return;
      let elementRect = offsetElement.getBoundingClientRect();
      if (elementRect.top < window.innerHeight * 0.4) {
        let opacity = elementRect.top / (window.innerHeight * 0.4);
        document.getElementById('changeOpacity').style.opacity = opacity;
      }
    });
  }

  render() {
    let date = new Date;
    var month = ["January", "February", "March", "April", "May", "June", "July",
      "August", "September", "October", "November", "December"];

    return (
      <>
        <Row>
          <Col style={{ padding: 0 }}>
            <CTASlider />
          </Col>
        </Row>
        <Row style={this.props.isMobile ? {paddingTop: "50px"} : { width: "90%", margin: "auto", paddingTop: "50px" }}>
          <Col>
            <Image src={intraline} style={this.props.isMobile ? {width: '60%'} : {}} />
            <p id='changeOpacity' style={this.props.isMobile ? {fontSize: '16px', color: colors.graphite } : { fontSize: "24px", color: colors.graphite }}>
              Get to know our new brand
            </p>
          </Col>
        </Row>
        {this.props.isMobile ? 
          <Row>
            <Col>
          <Image src={productGroup} style={{marginTop: '-30px', width: '100%'}}/>
          <div style={{width: '100%', textAlign: 'center'}}>
          <Link to="/products/9-intraline" >
          <Button
              id="collection-button"
              style={{
                border: 'none',
                borderRadius: '26px',
                color: colors.white,
                fontSize: "18px",
                fontWeight: "600",
                padding: "3px 60px 5px",
                background: 'transparent linear-gradient(90deg, #09486F 0%, #6B3E76 100%) 0% 0% no-repeat padding-box',
                position: 'relative',
                zIndex: 2
              }}
              variant="outline-primary"
            >
              more details 
              {/* <a href="/products/9-intraline" style={{textDecoration:'none', color:'inherit'}}>more details </a> */}
            </Button>
            </Link>
            </div>
            </Col>
          </Row>
           : 
        <Row>
          <Col>
            <Parallax
              className="custom-class"
              y={[30, -10]}
              x={[70, 10]}
              tagOuter="figure"
            >
              <Link to='/product/139-intraline-for-men-20mgml-11ml-prefilled-syringe'><ParalaxImage src={image8} /></Link>
            </Parallax>
          </Col>
          <Col>
            <Parallax
              className="custom-class"
              y={[20, -20]}
              x={[60, 5]}
              tagOuter="figure"
            >
              <Link to='/product/36-juvederm-ultra-3'><ParalaxImage src={image9} /></Link>
            </Parallax>
          </Col>
          <Col>
            <Parallax
              className="custom-class"
              y={[10, -30]}
              x={[10, 0]}
              tagOuter="figure"
            >
              <Link to='/product/140-intraline-one-20mgml-1-1ml-prefilled-syringe'><ParalaxImage src={image7} /></Link>
            </Parallax>
          </Col>
          <Col style={{zIndex: 2}}>
            <Parallax
              className="custom-class"
              x={[-20, 5]}
              y={[20, -10]}
              tagOuter="figure"
            >
              <Link to='/product/36-juvederm-ultra-3'><ParalaxImage src={image10} /></Link>
            </Parallax>
          </Col>
          <Col>
            <Parallax
              className="custom-class"
              x={[-70, -30]}
              y={[30, -10]}
              tagOuter="figure"
            >
              <Link to='/product/162-intraline-two-20mgml-1-1ml-prefilled-syringe'><ParalaxImage src={image11} /></Link>
            </Parallax>
            <Link to="/products/9-intraline" >
            <Button
              id="collection-button"
              style={{
                border: 'none',
                borderRadius: '26px',
                color: colors.white,
                fontSize: "18px",
                fontWeight: "600",
                padding: "3px 60px 5px",
                background: 'transparent linear-gradient(90deg, #09486F 0%, #6B3E76 100%) 0% 0% no-repeat padding-box',
                position: 'relative',
                zIndex: 2
              }}
              variant="outline-primary"
            >
              more details
            </Button>
            </Link>
          </Col>
        </Row>}
        <ForMan isMobile={this.props.isMobile} />
        <Row>
          <Col>
            <p style={this.props.isMobile ? {fontSize: "18px", color: colors.graphite, fontWeight: 400, marginBottom: '-20px'} : { fontSize: "24px", color: colors.graphite, fontWeight: 400, }}>
              Our recommendation in {month[date.getMonth()]}
            </p>
          </Col>
        </Row>
        <Row>
          {this.props.isMobile ? <ProductCarousel isMobile={this.props.isMobile} products={this.state.products} /> : 
          this.state.products.slice(0, 5).map((product, key) => {
            return (
              <RecommendetProductOnList key={key} product={product} />
            )
          }) }
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isMobile: state.isMobile, // (1)
    whiteHeader: state.whiteHeader,
  };
};
const mapDispatchToProps = { isMobileFetched, whiteHeaderFetched, homePageFetched }; // (2)
export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
