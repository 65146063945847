import R1A0448a1 from "../img/R1A0448a1.png";
import R1A0473a1 from "../img/R1A0473a1.png";
import R1A5543a1 from "../img/R1A5543a1.png";

// const apiUrl = 'http://localhost:8080/apiV2/';
// const apiUrl = 'http://frayaserverlukasz/apiV2/';
// const apiUrl = "https://server.frayawarehouse.com/";
const apiUrl = "https://server.frayawarehouse.com/apiV2/";
let bearer = "";

let exchange = null;
let deliveryCosts = {};

let countries = null;
const get = (callback, url, authenticated) => {
  return request(callback, {}, url, "GET", authenticated);
};

const post = (callback, params, url, authenticated, noLoader) => {
  return request(callback, params, url, "POST", authenticated, noLoader);
};

const request = (callback, params, url, method, authenticated, noLoader) => {
  // if (!noLoader) {
  // let loader = document.getElementById('loader');
  // loader.classList.add("loadderActive");
  // }
  if (typeof authenticated === "undefined") {
    authenticated = false;
  }
  const requestParams = {
    method: method,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  if (authenticated) {
    requestParams.headers["Authorization"] = "Bearer " + bearer;
  }
  if (method == "POST") {
    requestParams.body = JSON.stringify(params);
  }
  fetch(apiUrl + url, requestParams)
    .then((response) => {
      if (response && response.status >= 200 && response.status < 300) {
        return response.json();
      } else {
        // response.json().then(data => console.log(data));
        return [];
      }
    })
    .then((response) => {
      //wyłącz loader
      // document.getElementById('loader').classList.remove("loadderActive");
      callback(response);
    })
    .catch((error) => console.error("Error:", error));
};

const Request = {
  sendEmail(data, callback) {
    post(
      (response) => {
        callback(response);
      },
      data,
      "mail/send",
      false
    );
  },
  login(email, password, callback) {
    post(
      (response) => {
        if (response.success) {
          bearer = response.access_token;
        }
        callback(response);
      },
      { email: email, password: password },
      "auth/login",
      false
    );
  },
  register(email, password, callback) {
    post(
      (response) => {
        if (response.success) {
          bearer = response.access_token;
        }
        callback(response);
      },
      { email: email, password: password },
      "user/register",
      false
    );
  },
  registerFacebook(response, callback) {
    post(
      (response) => {
        if (response.success) {
          bearer = response.access_token;
        }
        callback(response);
      },
      { response: response },
      "user/register-facebook",
      false
    );
  },
  registerGoogle(response, callback) {
    post(
      (response) => {
        if (response.success) {
          bearer = response.access_token;
        }
        callback(response);
      },
      { response: response },
      "user/register-google",
      false
    );
  },
  // getProducts(callback) {
  //   get((response) => {
  //     callback(response);
  //   }, "product/get-all?frontend=1");
  // },
  saveMyData(data, callback) {
    post(
      (response) => {
        callback(response);
      },
      data,
      "user/update",
      true
    );
  },
  deleteAccount(callback) {
    post(
      (response) => {
        callback(response);
      },
      {},
      "user/delete",
      true
    );
  },
  resetPassword(data, callback) {
    post(
      (response) => {
        callback(response);
      },
      data,
      "auth/reset-password"
    );
  },
  doResetPassword(data, callback) {
    post(
      (response) => {
        callback(response);
      },
      data,
      "auth/do-reset-password"
    );
  },
  getOrderHistory(callback) {
    get((response) => {
      callback(response);
    }, "order/history", true);
  },
  getPaymentButton(id, callback) {
    post(
      (response) => {
        callback(response);
      },
      { orderId: id },
      "order/get-payment-link"
    );
  },
  createOrder(data, callback) {
    data.bearer = bearer;
    post(
      (response) => {
        callback(response);
      },
      data,
      "order/create"
    );
  },
  createPayPalOrder(data, callback) {
    data.bearer = bearer;
    post(
      (response) => {
        callback(response);
      },
      data,
      "order/create-quick"
    );
  },
  orderFeedback(data, callback) {
    post(
      (response) => {
        callback(response);
      },
      data,
      "order/feedback"
    );
  },
  getCategories(callback) {
    get((response) => {
      callback(response);
    }, "category/get-all");
  },
  getPurposes(callback) {
    get((response) => {
      callback(response);
    }, "purpose/get-all");
  },
  realProductImage(url) {
    if (!url) {
      return url;
    }
    if (url.includes("http")) {
      return url;
    }
    return apiUrl.replace('/apiV2', '') + "/uploads/" + url;
  },
  getSearchHints(query, callback) {
    post(response => {
      callback(response);
    }, { query: query }, 'product/hint', true, true);
  },
  // B2B
  getSlides(callback) {
    callback({
      success: true,
      slides: [
        {
          link: "/cokolwiek",
          img: R1A0448a1,
        },
        {
          link: "/kokok",
          img: R1A0473a1,
        },
        {
          link: "/aaa",
          img:
            R1A5543a1,
        },
      ],
    });
  },
  getProducts(callback) {
    get((response) => {
      callback(response);
    }, "product/get-all");
  },
  getCategoryByUri(uri, callback) {
    post((response) => {
      callback(response);
    }, { uri: uri }, "category/get-by-uri");
  },
  getCategoryTypeByUri(uri, callback) {
    post((response) => {
      callback(response);
    }, { uri: uri }, "category/get-type-by-uri");
  },
  getProductByUri(uri, callback) {
    post((response) => {
      callback(response);
    }, { uri: uri }, "product/get-by-uri");
  },
  checkPaymentStatus(id, callback) {
    post(
      (response) => {
        callback(response);
      },
      { orderId: id },
      "order/check-payment-status"
    );
  },
  getExchange(callback) {
    if (exchange) {
      callback(exchange);
    }
    get((response) => {
      if (response.success) {
        exchange = response.exchangeRates;
      }
      callback(response);
    }, "exchange/get");
  },
  getDeliveryCosts(callback, cart, country) {
    let count = 0;
    cart.map(elem => count += elem.quantity);
    if (deliveryCosts[count + country]) {
      callback(deliveryCosts[count + country]);
    }
    post((response) => {
      if (response.success) {
        deliveryCosts[count + country] = response;
      }
      callback(response);
    }, {
      count: count,
      country: country,
    },
      "delivery-cost/get-all-v2");
  },
  setBearerFromStorage(key) {
    bearer = key;
  },
  getCountries(callback) {
    if (countries) {
      callback(countries);
      return;
    }
    const requestParams = {
      method: 'get',
      headers: {
        Accept: "application/json",
        // "Content-Type": "application/json",
      },
    };
    fetch('https://restcountries.eu/rest/v2/all', requestParams)
      .then((response) => {
        if (response && response.status >= 200 && response.status < 300) {
          return response.json();
        } else {
          // response.json().then(data => console.log(data));
          return [];
        }
      })
      .then((response) => {
        //wyłącz loader
        // document.getElementById('loader').classList.remove("loadderActive");
        if (response.length) {
          countries = response;
        }
        callback(response);
      })
      .catch((error) => console.error("Error:", error));
  },
  newsletter(email, callback) {
    post(
      (response) => {
        callback(response);
      },
      { email: email },
      "newsletter/register"
    );
  },
  getUrlParam(name) {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(name);
  },
};

export default Request;
