import React from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import colors from "../colors/colors";
import { connect } from "react-redux";
import {
  isMobileFetched,
  cartFetched,
  userFetched,
  localeFetched,
} from "../actions";
import ListingPage from "./ListingPage";
import Price from "../components/general/Price";
import { ReactComponent as Ups } from "../svg/ups.svg";
import { ReactComponent as Dhl } from "../svg/dhl.svg";
import { ReactComponent as PayPalCard } from "../svg/paypalCard.svg";
import { ReactComponent as VisaCard } from "../svg/visaCard.svg";
import { ReactComponent as BankTransfer } from "../svg/bankTransfer.svg";
import { ReactComponent as P24 } from "../svg/p24.svg";
import UniversalInput from "../components/form/UniversalInput";
import UniversalCheck from "../components/form/UniversalCheck";
import BillingDetail from "../components/form/BillingDetails";
import CountrySelect from "../components/form/CountrySelect";
import DeliveryCost from "../components/general/DeliveryCost";
import DeliveryCostCalculator from "../models/DeliveryCostCalculator";
import Request from "../api/Request";
import BlueButton from "../components/general/BlueButton";
import PhoneInput from "../components/form/PhoneInput";
import countryCodes from "../components/form/countryCodes";
import OrderSummary from "../components/general/OrderSummary";

class Checkout extends ListingPage {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      countryInputValue: "",
      nameInputValue: "",
      surnameInputValue: "",
      companyNameInputValue: "",
      addressInputValue: "",
      extraAddressInputValue: "",
      postCodeInputValue: "",
      cityInputValue: "",
      stateInputValue: "",
      emailInputValue: "",
      phoneInputValue: '',
      countryNumberInputValue: "",
      invoice: false,
      invoiceCompanyName: "",
      invoiceVatNumber: "",
      invoiceCountry: "",
      invoiceBillingAddress: "",
      invoiceExtraBillingAddress: "",
      invoicePostalCode: "",
      invoiceCity: "",
      deliveryOption: "",
      paymentOption: "",
      costs: {},
      exchange: {},
      countries: [],
      requestSent: false,
      acceptTerms: false,
    };
  }

  componentDidMount() {
    this.setState({ locale: this.props.locale })
    this.setState({
      countryInputValue:
        this.props.locale.country
          ? this.props.locale.country :
          this.props.user.shipping_information &&
            this.props.user.shipping_information.length
            ? this.props.user.shipping_information[0].country
            : ""
      ,
      nameInputValue:
        this.props.user.shipping_information &&
          this.props.user.shipping_information.length
          ? this.props.user.shipping_information[0].name
          : "",
      surnameInputValue:
        this.props.user.shipping_information &&
          this.props.user.shipping_information.length
          ? this.props.user.shipping_information[0].surname
          : "",
      companyNameInputValue:
        this.props.user.shipping_information &&
          this.props.user.shipping_information.length
          ? this.props.user.shipping_information[0].company_name
          : "",
      addressInputValue:
        this.props.user.shipping_information &&
          this.props.user.shipping_information.length
          ? this.props.user.shipping_information[0].address
          : "",
      extraAddressInputValue:
        this.props.user.shipping_information &&
          this.props.user.shipping_information.length
          ? this.props.user.shipping_information[0].extra_address
          : "",
      postCodeInputValue:
        this.props.user.shipping_information &&
          this.props.user.shipping_information.length
          ? this.props.user.shipping_information[0].post_code
          : "",
      cityInputValue:
        this.props.user.shipping_information &&
          this.props.user.shipping_information.length
          ? this.props.user.shipping_information[0].city
          : "",
      stateInputValue: "",
      emailInputValue: this.props.user.email,
      phoneInputValue: this.props.user.phone_number,
      countryNumberInputValue: this.props.user.country_number == undefined ? countryCodes[this.props.locale.country] : this.props.user.country_number,

      invoiceCompanyName: this.props.user.company_name,
      invoiceVatNumber: this.props.user.vat_number,
      invoiceCountry: this.props.user.country,
      invoiceBillingAddress: this.props.user.address,
      invoiceExtraBillingAddress: this.props.user.extra_address,
      invoicePostalCode: this.props.user.post_code,
      invoiceCity: this.props.user.city,
    });
    Request.getExchange((response) => {
      if (response.success) {
        this.setState({ exchange: response.exchangeRates });
      }
    });
    Request.getCountries((response) => {
      this.setState({ countries: response });
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    Request.getDeliveryCosts(response => {
      if (response.success && (!this.state.costs.length || response.deliveryCosts[0].UPS_price != this.state.costs[0].UPS_price)) {
        this.setState({ costs: response.deliveryCosts });
      }
    }, this.props.cart, this.props.locale.country);

    if (
      Object.keys(prevProps.user).length == 0 &&
      Object.keys(this.props.user).length != 0
    ) {
      this.setState({
        countryInputValue: this.props.locale.country
          ? this.props.locale.country :
          this.props.user.shipping_information &&
            this.props.user.shipping_information.length
            ? this.props.user.shipping_information[0].country
            : "",
        nameInputValue:
          this.props.user.shipping_information &&
            this.props.user.shipping_information.length
            ? this.props.user.shipping_information[0].name
            : "",
        surnameInputValue:
          this.props.user.shipping_information &&
            this.props.user.shipping_information.length
            ? this.props.user.shipping_information[0].surname
            : "",
        companyNameInputValue:
          this.props.user.shipping_information &&
            this.props.user.shipping_information.length
            ? this.props.user.shipping_information[0].company_name
            : "",
        addressInputValue:
          this.props.user.shipping_information &&
            this.props.user.shipping_information.length
            ? this.props.user.shipping_information[0].address
            : "",
        extraAddressInputValue:
          this.props.user.shipping_information &&
            this.props.user.shipping_information.length
            ? this.props.user.shipping_information[0].extra_address
            : "",
        postCodeInputValue:
          this.props.user.shipping_information &&
            this.props.user.shipping_information.length
            ? this.props.user.shipping_information[0].post_code
            : "",
        cityInputValue:
          this.props.user.shipping_information &&
            this.props.user.shipping_information.length
            ? this.props.user.shipping_information[0].city
            : "",
        stateInputValue: "",
        emailInputValue: this.props.user.email,
        phoneInputValue: this.props.user.phone_number,
        countryNumberInputValue: this.props.user.country_number == undefined ? countryCodes[this.props.locale.country] : this.props.user.country_number,

        invoiceCompanyName: this.props.user.company_name,
        invoiceVatNumber: this.props.user.vat_number,
        invoiceCountry: this.props.user.country,
        invoiceBillingAddress: this.props.user.address,
        invoiceExtraBillingAddress: this.props.user.extra_address,
        invoicePostalCode: this.props.user.post_code,
        invoiceCity: this.props.user.city,
      });
    }
  }

  updateShippingInformation(event) {
    const id = event.target.value;
    if (id == -1) {
      return;
    }
    this.setState({
      countryInputValue:
        this.props.user.shipping_information &&
          this.props.user.shipping_information.length
          ? this.props.user.shipping_information[id].country
          : "",
      nameInputValue:
        this.props.user.shipping_information &&
          this.props.user.shipping_information.length
          ? this.props.user.shipping_information[id].name
          : "",
      surnameInputValue:
        this.props.user.shipping_information &&
          this.props.user.shipping_information.length
          ? this.props.user.shipping_information[id].surname
          : "",
      companyNameInputValue:
        this.props.user.shipping_information &&
          this.props.user.shipping_information.length
          ? this.props.user.shipping_information[id].company_name
          : "",
      addressInputValue:
        this.props.user.shipping_information &&
          this.props.user.shipping_information.length
          ? this.props.user.shipping_information[id].address
          : "",
      extraAddressInputValue:
        this.props.user.shipping_information &&
          this.props.user.shipping_information.length
          ? this.props.user.shipping_information[id].extra_address
          : "",
      postCodeInputValue:
        this.props.user.shipping_information &&
          this.props.user.shipping_information.length
          ? this.props.user.shipping_information[id].post_code
          : "",
      cityInputValue:
        this.props.user.shipping_information &&
          this.props.user.shipping_information.length
          ? this.props.user.shipping_information[id].city
          : "",
    });
  }

  setStepOne() {
    this.setState({ step: 1 });
  }

  setStepTwo() {
    this.setState({ step: 2 });
  }

  setStepThree() {
    this.setState({ step: 3 });
  }

  handleCountryInputChange(event) {
    const { value } = event.target;

    if (this.state.countryNumberInputValue == '' || this.state.countryNumberInputValue == null) {
      this.setState({
        countryInputValue: value,
        countryNumberInputValue: '+' + countryCodes[value],
      });
    } else {
      this.setState({ countryInputValue: value });
    }
  }

  handleNameInputChange(event) {
    const { value } = event.target;
    this.setState({ nameInputValue: value });
  }

  handleSurnameInputChange(event) {
    const { value } = event.target;
    this.setState({ surnameInputValue: value });
  }

  handleCompanyNameInputChange(event) {
    const { value } = event.target;
    this.setState({ companyNameInputValue: value });
  }

  handleAddressInputChange(event) {
    const { value } = event.target;
    this.setState({ addressInputValue: value });
  }

  handleExtraAddressInputChange(event) {
    const { value } = event.target;
    this.setState({ extraAddressInputValue: value });
  }

  handlePostCodeInputChange(event) {
    const { value } = event.target;
    this.setState({ postCodeInputValue: value });
  }

  handleCityInputChange(event) {
    const { value } = event.target;
    this.setState({ cityInputValue: value });
  }

  handleStateInputChange(event) {
    const { value } = event.target;
    this.setState({ stateInputValue: value });
  }

  handleEmailInputChange(event) {
    const { value } = event.target;
    this.setState({ emailInputValue: value });
  }

  handlePhoneInputChange(event) {
    const { value } = event.target;
    this.setState({ phoneInputValue: value });
  }

  handleCountryNumberInputChange(event) {
    const { value } = event.target;
    this.setState({ countryNumberInputValue: value });
  }

  handleInvoiceCompanyNameChange(event) {
    const { value } = event.target;
    this.setState({ invoiceCompanyName: value });
  }

  handleInvoiceVatNumberChange(event) {
    const { value } = event.target;
    this.setState({ invoiceVatNumber: value });
  }

  handleInvoiceCountryChange(event) {
    const { value } = event.target;
    this.setState({ invoiceCountry: value });
  }

  handleInvoiceBillingAddressChange(event) {
    const { value } = event.target;
    this.setState({ invoiceBillingAddress: value });
  }

  handleInvoiceExtraBillingAddressChange(event) {
    const { value } = event.target;
    this.setState({ invoiceExtraBillingAddress: value });
  }

  handleInvoicePostalCodeChange(event) {
    const { value } = event.target;
    this.setState({ invoicePostalCode: value });
  }

  handleInvoiceCityChange(event) {
    const { value } = event.target;
    this.setState({ invoiceCity: value });
  }

  setInvoice() {
    if (this.state.invoice) {
      this.setState({ invoice: false });
    } else {
      this.setState({ invoice: true });
    }
  }

  setDeliveryOption(event) {
    this.setState({ deliveryOption: event.target.value });
  }

  setPaymentOption(event) {
    this.setState({ paymentOption: event.target.value });
  }

  setDeliveryInfo() {
    if (!this.validate()) {
      this.setState({ hasBeenSubmited: true });
      return;
    }
    this.setStepTwo();
    this.setState({ hasBeenSubmited: false });
  }

  validate() {
    return (
      this.state.nameInputValue != null &&
      this.state.nameInputValue.length > 1 &&
      this.state.surnameInputValue != null &&
      this.state.surnameInputValue.length > 1 &&
      this.state.addressInputValue != null &&
      this.state.addressInputValue.length > 1 &&
      this.state.postCodeInputValue != null &&
      this.state.postCodeInputValue.length > 1 &&
      this.state.cityInputValue != null &&
      this.state.cityInputValue.length > 1 &&
      this.state.emailInputValue != null &&
      this.state.emailInputValue.length > 1 &&
      this.state.phoneInputValue != null &&
      this.state.phoneInputValue.length > 1 &&
      this.state.countryNumberInputValue != null &&
      this.state.countryNumberInputValue.length > 1
    );
  }

  validateDeliveryOption() {
    return this.state.deliveryOption.length == 0;
  }

  acceptDeliveryOption() {
    if (this.validateDeliveryOption()) {
      this.setState({ deliveryDasBeenSubmited: true });
      return;
    }

    this.setState({ deliveryDasBeenSubmited: false });

    this.setStepThree();
  }

  isPaymentOptionNotSelected() {
    return this.state.paymentOption.length == 0;
  }

  acceptPaymentOption() {
    if (this.isPaymentOptionNotSelected() || this.isInvoiceDataFilledWrong()) {
      this.setState({ paymentHasBeenSubmited: true });
      return;
    }

    this.setState({ requestSent: true });
    this.setState({ paymentHasBeenSubmited: false });
    let costCalculator = new DeliveryCostCalculator(
      this.props.locale,
      this.state.costs,
      this.state.exchange
    );
    Request.createOrder(
      {
        order: {
          country: this.state.countryInputValue,
          name: this.state.nameInputValue,
          surname: this.state.surnameInputValue,
          company_name: this.state.companyNameInputValue,
          shipping_address: this.state.addressInputValue,
          shipping_address_2: this.state.extraAddressInputValue,
          post_code: this.state.postCodeInputValue,
          city: this.state.cityInputValue,
          state: this.state.stateInputValue,
          email: this.state.emailInputValue,
          phone_number: this.state.phoneInputValue,
          country_number: this.state.countryNumberInputValue,

          invoice: +this.state.invoice,
          invoice_company_name: this.state.invoiceCompanyName,
          invoice_vat_number: this.state.invoiceVatNumber,
          invoice_country: this.state.invoiceCountry,
          invoice_billing_address: this.state.invoiceBillingAddress,
          invoice_billing_address_2: this.state.invoiceExtraBillingAddress,
          invoice_post_code: this.state.invoicePostalCode,
          invoice_city: this.state.invoiceCity,
          delivery: this.state.deliveryOption,
          delivery_price: costCalculator.calculateCostRaw(this.state.deliveryOption.toLowerCase()),
          payment_type: this.state.paymentOption,
          currency: this.props.locale.currency.toUpperCase(),
        },
        products: this.props.cart,
      },
      (response) => {
        if (response.success) {
          this.setState({ requestSent: false });
          this.props.cartFetched([]);
          window.location.href = this.state.paymentOption == 'Bank Transfer' ? '/thank-you' : response.paymentButton;

          // this.goToPayment(response.orderId);
        }
      }
    );
  }

  isInvoiceDataFilledWrong() {
    if (this.state.invoice) {
      return !(
        this.state.invoiceCompanyName != null &&
        this.state.invoiceCompanyName.length > 1 &&
        this.state.invoiceVatNumber != null &&
        this.state.invoiceVatNumber.length > 1 &&
        this.state.invoiceBillingAddress != null &&
        this.state.invoiceBillingAddress.length > 1 &&
        this.state.invoicePostalCode != null &&
        this.state.invoicePostalCode.length > 1 &&
        this.state.cityInputValue != null &&
        this.state.cityInputValue.length > 1 &&
        this.state.invoiceCity != null &&
        this.state.invoiceCity.length > 1
      );
    } else {
      return false;
    }
  }

  // goToPayment(orderId) {
  //   let total = 0.0;
  //   this.props.cart.forEach((product) => {
  //     total += product.price * product.quantity;
  //   });
  //   let costCalculator = new DeliveryCostCalculator(
  //     this.props.locale,
  //     this.state.costs,
  //     this.state.exchange
  //   );
  //   total += costCalculator.calculateCost(
  //     this.state.deliveryOption.toLowerCase(),
  //     false
  //   );

  //   let countryCode = "";

  //   if (this.state.countries.length) {
  //     let i = 0;
  //     for (i; i < this.state.countries.length; i++) {
  //       if (this.state.countries[i].name == this.props.locale.country) {
  //         countryCode = this.state.countries[i].alpha2Code;
  //         break;
  //       }
  //     }
  //   }
  //   // this.props.history.push("/payment");
  // }

  temsCheckClick() {
    this.setState({ acceptTerms: !this.state.acceptTerms })
  }

  render() {
    let costCalculator = new DeliveryCostCalculator(
      this.props.locale,
      this.state.costs,
      this.state.exchange
    );

    return (
      <>
        <Row style={this.props.isMobile ? { paddingTop: "80px", paddingBottom: "100px" } : { paddingTop: "150px", paddingBottom: "0px" }}>
          <Col md={8}>
            <Row>
              <Col>
                <h3
                  style={{
                    fontSize: this.props.isMobile ? '20px' : "32px",
                    color: colors.blue,
                    fontFamily: "Raleway",
                    fontWeight: 900,
                    marginTop: "10px",
                    marginBottom: this.props.isMobile ? '30px' : "40px",
                  }}
                >
                  Checkout
                </h3>
              </Col>
            </Row>
            <Row>
              <Col xs={2} md={1}>
                <h3
                  onClick={this.setStepOne.bind(this)}
                  style={{
                    fontSize: this.props.isMobile ? '16px' : "24px",
                    color: colors.white,
                    fontFamily: "Montserrat",
                    fontWeight: this.props.isMobile ? 700 : 900,
                    backgroundColor:
                      this.state.step == 1 ? colors.pink : colors.lightBlue,
                    borderRadius: "50%",
                    padding: this.props.isMobile ? '10px' : "14px",
                    height: this.props.isMobile ? '40px' : "55px",
                    width: this.props.isMobile ? '40px' : "55px",
                    margin: this.props.isMobile ? '0' : "auto",
                    textAlign: "center",
                    cursor: "pointer",
                    boxShadow: this.state.step == 1 ? '0px 5px 15px -6px rgba(230, 90, 124, 0.66)' : '0px 5px 15px -6px rgba(53, 116, 144, 0.66)',
                  }}
                >
                  1
                </h3>
              </Col>
              <Col className="my-auto" style={this.props.isMobile ? {} : { paddingLeft: "40px" }}>
                <h3
                  onClick={this.setStepOne.bind(this)}
                  style={{
                    fontSize: this.props.isMobile ? '20px' : "24px",
                    color: this.state.step == 1 ? colors.pink : colors.blue,
                    fontFamily: "Raleway",
                    fontWeight: this.props.isMobile ? 700 : 900,
                    marginTop: this.state.step == 1 ? this.props.isMobile ? '6px' : " 15px" : this.props.isMobile ? '0px' : '10px',
                    cursor: "pointer",
                    marginBottom: this.state.step == 1 ? "40px" : '0px',
                  }}
                >
                  Shipping details
                </h3>
              </Col>
            </Row>
            {this.state.step == 1 ? (
              <Row style={{ marginBottom: '-20px' }}>
                {this.props.isMobile ? <></> : <Col md={1}></Col>}
                <Col style={this.props.isMobile ? {} : { paddingLeft: "40px", marginTop: '-10px' }}>
                  {this.props.user.shipping_information &&
                    this.props.user.shipping_information.length ? (
                      <Form.Group controlId="exampleForm.ControlSelect1">
                        <Form.Label
                          style={{
                            fontSize: "18px",
                            fontFamily: "Montserrat",
                            fontWeight: 500,
                          }}
                        >Shipping data</Form.Label>
                        <Form.Control
                          style={{ backgroundColor: "#f5f5f5", border: 'none', cursor: 'pointer' }}
                          as="select"
                          onChange={this.updateShippingInformation.bind(this)}
                        >
                          {[<option value="-1" key='empty'>
                            Please select
                              </option>].concat(this.props.user.shipping_information.map(
                            (shipping_information, id) => {
                              return (
                                <option value={id} key={id}>
                                  {shipping_information.name},{" "}
                                  {shipping_information.surname}
                                </option>
                              );
                            }
                          ))}
                        </Form.Control>
                      </Form.Group>
                    ) : (
                      <></>
                    )}
                  <CountrySelect
                    label="Country"
                    value={this.state.countryInputValue}
                    onChange={this.handleCountryInputChange.bind(this)}
                  />
                  <Row>
                    <Col md={6} xs={12}>
                      {" "}
                      <UniversalInput
                        label="Name"
                        value={this.state.nameInputValue}
                        onChange={this.handleNameInputChange.bind(this)}
                        isError={
                          this.state.hasBeenSubmited &&
                          (this.state.nameInputValue == null ||
                            this.state.nameInputValue.length < 2)
                        }
                        errorMessage="This field cannot be empty"
                      />
                    </Col>
                    <Col md={6} xs={12}>
                      <UniversalInput
                        label="Surname"
                        value={this.state.surnameInputValue}
                        onChange={this.handleSurnameInputChange.bind(this)}
                        isError={
                          this.state.hasBeenSubmited &&
                          (this.state.surnameInputValue == null ||
                            this.state.surnameInputValue.length < 2)
                        }
                        errorMessage="This field cannot be empty"
                      />
                    </Col>
                  </Row>
                  <UniversalInput
                    label="Company name (optional)"
                    value={this.state.companyNameInputValue}
                    onChange={this.handleCompanyNameInputChange.bind(this)}
                  />
                  <UniversalInput
                    label="Shipping Address"
                    value={this.state.addressInputValue}
                    onChange={this.handleAddressInputChange.bind(this)}
                    isError={
                      this.state.hasBeenSubmited &&
                      (this.state.addressInputValue == null ||
                        this.state.addressInputValue.length < 2)
                    }
                    errorMessage="This field cannot be empty"
                  />
                  <UniversalInput
                    label="Shipping Address (optional)"
                    value={this.state.extraAddressInputValue}
                    onChange={this.handleExtraAddressInputChange.bind(this)}
                  />
                  <Row>
                    <Col md={6} xs={12}>
                      <UniversalInput
                        label="Postal Code"
                        value={this.state.postCodeInputValue}
                        onChange={this.handlePostCodeInputChange.bind(this)}
                        isError={
                          this.state.hasBeenSubmited &&
                          (this.state.postCodeInputValue == null ||
                            this.state.postCodeInputValue.length < 2)
                        }
                        errorMessage="This field cannot be empty"
                      />
                    </Col>
                    <Col md={6} xs={12}>
                      <UniversalInput
                        label="City"
                        value={this.state.cityInputValue}
                        onChange={this.handleCityInputChange.bind(this)}
                        isError={
                          this.state.hasBeenSubmited &&
                          (this.state.cityInputValue == null ||
                            this.state.cityInputValue.length < 2)
                        }
                        errorMessage="This field cannot be empty"
                      />
                    </Col>
                  </Row>
                  {this.state.countryInputValue == "United States of America" ?
                    <UniversalInput
                      label="State"
                      value={this.state.stateInputValue}
                      onChange={this.handleStateInputChange.bind(this)}
                    /> : <></>}
                  <UniversalInput
                    isEmail={true}
                    label="Email"
                    value={this.state.emailInputValue}
                    onChange={this.handleEmailInputChange.bind(this)}
                    isError={
                      this.state.hasBeenSubmited &&
                      (this.state.emailInputValue == null ||
                        this.state.emailInputValue.length < 2)
                    }
                    errorMessage="This field cannot be empty"
                  />
                  <PhoneInput
                    value={this.state.phoneInputValue}
                    onChange={this.handlePhoneInputChange.bind(this)}
                    handleCountryNumberChange={this.handleCountryNumberInputChange.bind(this)}
                    countryNumber={this.state.countryNumberInputValue}
                    label="Phone number"
                    isError={this.state.hasBeenSubmited && (this.state.phoneInputValue == null || this.state.phoneInputValue.length < 2 || this.state.countryNumberInputValue == null || this.state.countryNumberInputValue.length < 2)}
                    errorMessage='This field cannot be empty'
                  />


                  <Form.Check
                    onChange={this.temsCheckClick.bind(this)}
                    // checked={this.state.invoice}
                    value={this.state.acceptTerms}
                    className='invoiceCheckBox'
                    type="checkbox"
                    label="*I agree to the processing of data under the terms of the privacy policy."
                    style={{
                      fontSize: this.props.isMobile ? '16px' : "18px",
                      fontFamily: "Montserrat",
                      fontWeight: 500,
                      marginBottom: "20px",
                      marginLeft: this.props.isMobile ? '75px' : 'inherit',
                    }}
                  />


                  <div style={this.props.isMobile ? { textAlign: 'center' } : { textAlign: "right", height: '0px' }}>
                    <Button
                      disabled={!this.state.acceptTerms}
                      onClick={this.setDeliveryInfo.bind(this)}
                      className="payPalButton"
                      style={{
                        width: "200px",
                        backgroundColor: colors.white,
                        borderColor: colors.blue,
                        borderWidth: 2,
                        fontFamily: "Montserrat",
                        fontWeight: 700,
                        color: colors.blue,
                        marginBottom: "20px",
                        position: this.props.isMobile ? 'inherit' : "relative",
                        top: "-68px",
                        right: "-44%",
                        zIndex: 3,
                        boxShadow: "0px 3px 6px #35749033",
                      }}
                    >
                      Accept
                    </Button>
                    {this.state.hasBeenSubmited ? (
                      <p
                        style={this.props.isMobile ?
                          {
                            height: 0,
                            margin: 0,
                            color: colors.darkPink,
                          }
                          : {
                            color: colors.darkPink,
                            position: this.props.isMobile ? 'inherit' : "relative",
                            top: "-72px",
                            right: "-40%",
                          }}
                      >
                        Please check your data
                      </p>
                    ) : (
                        <></>
                      )}
                  </div>
                </Col>
                <Col md={1}></Col>
              </Row>
            ) : (
                <></>
              )}
            <Row style={{ marginTop: "50px" }}>
              <Col xs={2} md={1}>
                <h3
                  onClick={this.setDeliveryInfo.bind(this)}
                  style={{
                    fontSize: this.props.isMobile ? '16px' : "24px",
                    color: colors.white,
                    fontFamily: "Montserrat",
                    fontWeight: this.props.isMobile ? 700 : 900,
                    backgroundColor:
                      this.state.step == 2 ? colors.pink : colors.lightBlue,
                    borderRadius: "50%",
                    padding: this.props.isMobile ? '10px' : "14px",
                    height: this.props.isMobile ? '40px' : "55px",
                    width: this.props.isMobile ? '40px' : "55px",
                    margin: this.props.isMobile ? '0' : "auto",
                    textAlign: "center",
                    cursor: "pointer",
                    boxShadow: this.state.step == 2 ? '0px 5px 15px -6px rgba(230, 90, 124, 0.66)' : '0px 5px 15px -6px rgba(53, 116, 144, 0.66)',
                  }}
                >
                  2
                </h3>
              </Col>
              <Col style={this.props.isMobile ? {} : { paddingLeft: "40px" }} className="my-auto">
                <h3
                  onClick={this.setDeliveryInfo.bind(this)}
                  style={{
                    fontSize: this.props.isMobile ? '20px' : "24px",
                    color: this.state.step == 2 ? colors.pink : colors.blue,
                    fontFamily: "Raleway",
                    fontWeight: this.props.isMobile ? 700 : 900,
                    marginTop: this.props.isMobile ? '6px' : "10px",
                    cursor: "pointer",
                  }}
                >
                  Delivery
                </h3>
              </Col>
            </Row>
            {this.state.step == 2 ? (
              <Row style={{ marginTop: '15px' }}>
                <Col md={1}></Col>
                <Col style={this.props.isMobile ? {} : { paddingLeft: "40px" }}>
                  <p style={{
                    fontSize: "16px",
                    color: colors.graphite,
                    fontFamily: "Montserrat",
                    fontWeight: 500,
                    marginBottom: '35px',
                  }}>
                    Please select a delivery method
                  </p>
                  <div onChange={this.setDeliveryOption.bind(this)}>
                    <UniversalCheck
                      label={"UPS "}
                      value={"UPS"}
                      name="carrier"
                      image={<Ups />}
                      deliverCost={costCalculator.calculateCost("ups")}
                    />
                    <UniversalCheck
                      marginTop='20px'
                      label={"DHL "}
                      value={"DHL"}
                      name="carrier"
                      image={<Dhl />}
                      deliverCost={costCalculator.calculateCost("dhl")}
                    />
                  </div>
                  <div style={this.props.isMobile ? { textAlign: 'center', marginTop: '30px', marginLeft: '-20px' } : { textAlign: "right", height: '0px' }}>
                    <Button
                      onClick={this.acceptDeliveryOption.bind(this)}
                      className="payPalButton"
                      style={{
                        width: "200px",
                        backgroundColor: colors.white,
                        borderColor: colors.blue,
                        borderWidth: 2,
                        fontFamily: "Montserrat",
                        fontWeight: 900,
                        color: colors.blue,
                        marginBottom: "20px",
                        position: this.props.isMobile ? 'inherit' : "relative",
                        top: "-33px",
                        right: "-44%",
                        zIndex: 9,
                        boxShadow: "0px 3px 6px #35749033",
                      }}
                    >
                      Accept
                    </Button>
                    {this.state.deliveryDasBeenSubmited ? (
                      <p
                        style={this.props.isMobile ?
                          {
                            height: 0,
                            margin: 0,
                            color: colors.darkPink,
                          }
                          : {
                            color: colors.darkPink,
                            position: "relative",
                            top: "-72px",
                            right: "-40%",
                          }}
                      >
                        Please check your data
                      </p>
                    ) : (
                        <></>
                      )}
                  </div>
                </Col>
                <Col md={1}></Col>
              </Row>
            ) : (
                <></>
              )}
            <Row style={{ marginTop: "50px" }}>
              <Col xs={2} md={1}>
                <h3
                  onClick={this.acceptDeliveryOption.bind(this)}
                  style={{
                    fontSize: this.props.isMobile ? '16px' : "24px",
                    color: colors.white,
                    fontFamily: "Montserrat",
                    fontWeight: this.props.isMobile ? 700 : 900,
                    backgroundColor:
                      this.state.step == 3 ? colors.pink : colors.lightBlue,
                    borderRadius: "50%",
                    padding: this.props.isMobile ? '10px' : "14px",
                    height: this.props.isMobile ? '40px' : "55px",
                    width: this.props.isMobile ? '40px' : "55px",
                    margin: this.props.isMobile ? '0' : "auto",
                    textAlign: "center",
                    cursor: "pointer",
                    boxShadow: this.state.step == 3 ? '0px 5px 15px -6px rgba(230, 90, 124, 0.66)' : '0px 5px 15px -6px rgba(53, 116, 144, 0.66)',
                  }}
                >
                  3
                </h3>
              </Col>
              <Col style={this.props.isMobile ? {} : { paddingLeft: "40px" }} className="my-auto">
                <h3
                  onClick={this.acceptDeliveryOption.bind(this)}
                  style={{
                    fontSize: this.props.isMobile ? '20px' : "24px",
                    color: this.state.step == 3 ? colors.pink : colors.blue,
                    fontFamily: "Raleway",
                    fontWeight: this.props.isMobile ? 700 : 900,
                    marginTop: this.props.isMobile ? '6px' : "10px",
                    cursor: "pointer",
                  }}
                >
                  Payment
                </h3>
              </Col>
            </Row>
            {this.state.step == 3 ? (
              <Row style={{ marginTop: '30px' }}>
                <Col md={1}></Col>
                <Col
                  style={this.props.isMobile ? {} : { paddingLeft: "40px" }}
                  onChange={this.setPaymentOption.bind(this)}
                >
                  <UniversalCheck
                    image={<PayPalCard />}
                    name="payment"
                    label="PayPal"
                  />
                  <UniversalCheck
                    image={<VisaCard />}
                    name="payment"
                    label="CreditCard"
                  />
                  {/* {this.props.locale.country == 'Poland' ?
                    <UniversalCheck
                      image={<P24 style={{
                        width: '49px',
                        backgroundColor: '#DFE3E8',
                        paddingTop: '8px',
                        paddingBottom: '8px',
                        borderRadius: '5px',
                        paddingLeft: '4px',
                        paddingRight: '4px',
                      }} />}
                      name="payment"
                      label="Przelewy 24"
                    /> : <></>} */}
                  <UniversalCheck
                    image={<BankTransfer />}
                    name="payment"
                    label="Bank Transfer"
                  />
                  <Form.Check
                    onChange={this.setInvoice.bind(this)}
                    checked={this.state.invoice}
                    className='invoiceCheckBox'
                    type="checkbox"
                    label="I need an invoice"
                    style={{
                      fontSize: this.props.isMobile ? '16px' : "18px",
                      fontFamily: "Montserrat",
                      fontWeight: 500,
                      marginTop: "50px",
                      marginLeft: this.props.isMobile ? '75px' : 'inherit',
                    }}
                  />
                  {this.state.invoice ? (
                    <BillingDetail
                      hasBeenSubmited={this.state.paymentHasBeenSubmited}
                      invoiceCompanyName={this.state.invoiceCompanyName}
                      handleInvoiceCompanyNameChange={this.handleInvoiceCompanyNameChange.bind(
                        this
                      )}
                      invoiceVatNumber={this.state.invoiceVatNumber}
                      handleInvoiceVatNumberChange={this.handleInvoiceVatNumberChange.bind(
                        this
                      )}
                      invoiceCountry={this.state.invoiceCountry}
                      handleInvoiceCountryChange={this.handleInvoiceCountryChange.bind(
                        this
                      )}
                      invoiceBillingAddress={this.state.invoiceBillingAddress}
                      handleInvoiceBillingAddressChange={this.handleInvoiceBillingAddressChange.bind(
                        this
                      )}
                      invoiceExtraBillingAddress={
                        this.state.invoiceExtraBillingAddress
                      }
                      handleInvoiceExtraBillingAddressChange={this.handleInvoiceExtraBillingAddressChange.bind(
                        this
                      )}
                      invoicePostalCode={this.state.invoicePostalCode}
                      handleInvoicePostalCodeChange={this.handleInvoicePostalCodeChange.bind(
                        this
                      )}
                      invoiceCity={this.state.invoiceCity}
                      handleInvoiceCityChange={this.handleInvoiceCityChange.bind(
                        this
                      )}
                    />
                  ) : (
                      <></>
                    )}
                  <div style={{ textAlign: "right" }}></div>
                  {this.state.requestSent ? <p style={{ textAlign: 'center' }}>Generating payment form.</p> :
                    <BlueButton
                      onClick={this.acceptPaymentOption.bind(this)}
                      width={this.props.isMobile ? '190px' : "40%"}
                      content="Proceed"
                      margin={this.props.isMobile ? "25px 0 15px calc((100% - 190px) / 2)" : "25px 0 15px calc(100% - 230px)"}
                    />}
                  {this.state.paymentHasBeenSubmited ? (
                    <p
                      style={{
                        color: colors.darkPink,
                        position: "relative",
                        right: "8.5%",
                        textAlign: "right",
                      }}
                    >
                      Please check your data
                    </p>
                  ) : (
                      <></>
                    )}
                </Col>
                <Col md={1}></Col>
              </Row>
            ) : (
                <></>
              )}
          </Col>
          {this.props.isMobile ? <></> :
            <OrderSummary
              rawTotal={this.rawTotal.bind(this)}
              cart={this.props.cart}
              loggedIn={this.state.loggedIn}
              hideButtons={true}
            />}
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isMobile: state.isMobile, // (1)
    cart: state.cart,
    user: state.user,
    locale: state.locale,
  };
};
const mapDispatchToProps = {
  isMobileFetched,
  cartFetched,
  userFetched,
  localeFetched,
}; // (2)
export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
