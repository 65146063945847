import React from "react";
import logo from "./logo.svg";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import MainHeader from "./components/headers/MainHeader";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Container } from "react-bootstrap";
import HomePage from "./pages/HomePage";
import MainFooter from "./components/footers/MainFooter";
import { ParallaxProvider } from "react-scroll-parallax";
import OurStoryPage from "./pages/OurStoryPage";
import CategoryProductListPage from "./pages/CategoryProductListPage";
import BrandProductListPage from "./pages/BrandProductListPage";
import ProductPage from "./pages/ProductPage";
import YourCartPage from "./pages/YourCartPage";
import PreCheckoutPage from "./pages/PreCheckoutPage";
import CheckoutPage from "./pages/CheckoutPage";
import MyAccountPage from "./pages/MyAccountPage";
import HistoricalOrder from "./pages/HistoricalOrder";
import ContactPage from "./pages/ContactPage";
import DeliveryInfoPage from "./pages/DeliveryInfoPage";
import CompanyInfoPage from "./pages/CompanyInfoPage";
import LogInPage from "./pages/LogInPage";
import CurrencyPopup from "./components/general/CurrencyPopup";
import { connect } from "react-redux";
import { isMobileFetched, userFetched, showCurrencyPopupFetched, showNewsletterPopupFetched, localeFetched, cartFetched } from "./actions";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import CookiesPolicy from "./pages/CookiesPolicy";
import TermsAndConditions from "./pages/TermAndCondition";
import PaymentInfoPage from "./pages/PaymentInfoPage";
import NewsletterPopup from "./components/general/NewsletterPopup";
import Request from "./api/Request";
import CookieBar from "./components/general/CookieBar";
import MensProductListPage from "./pages/MensProductListPage";
import MobileMenu from "./components/mobile/MobileMenu";
import ThankYouPage from "./pages/ThankYouPage";
import FailedPaymentPage from "./pages/FailedPaymentPage";
import RestoreProductListPage from "./pages/RestoreProductListPage";
import RefreshProductListPage from "./pages/RefreshProductListPage";
import ReactPixel from 'react-facebook-pixel';
import PageChange from "./components/general/PageChange";

export class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      acceptCookies: false,
      password: '',
      passwordInput: '',
    };
  }

  componentDidMount() {
    this.props.isMobileFetched(window.innerWidth < 900);
    let locale = JSON.parse(window.localStorage.getItem('locale'));
    let user = JSON.parse(window.localStorage.getItem('user'));
    let cart = JSON.parse(window.localStorage.getItem('cart'));
    if (locale) {
      this.props.showCurrencyPopupFetched(false);
      this.props.localeFetched(locale);
    }
    if (user) {
      this.props.userFetched(user);
      Request.setBearerFromStorage(user.access_token);
    }
    if (cart) {
      this.props.cartFetched(cart);
    }

    let cookies = JSON.parse(window.localStorage.getItem('cookies'));
    if (cookies) {
      this.setState({ acceptCookies: true });
    }
    
    let pwd = JSON.parse(window.localStorage.getItem('password'));
    if (pwd) {
      this.setState({ password: pwd });
    }

    const options = {
      autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
      debug: false, // enable logs
    };
    ReactPixel.init('2092347764242773', {}, options);
    ReactPixel.pageView();
  }


  setAcceptCookies() {
    this.setState({ acceptCookies: true });
    window.localStorage.setItem('cookies', JSON.stringify(true));
  }

  setPassword() {
    this.setState({ password: this.state.passwordInput });
    window.localStorage.setItem('password', JSON.stringify(this.state.passwordInput));
  }

  render() {
    // if (this.state.password != 'Fraya!@2020') {
    //   return <div>
    //       <input type="text" value={this.state.passwordInput} onChange={e=> this.setState({passwordInput: e.target.value})}></input>
    //       <button onClick={this.setPassword.bind(this)}>submit</button>
    //   </div>
    // }
    return (
      <Router>
        <PageChange />
        <Container
          fluid
          style={{ maxWidth: "100vw", position: "absolute", zIndex: 4 }}
        >
          <MainHeader white={false} />
          {this.props.showCurrencyPopup ? <CurrencyPopup /> : <></>}
          {this.props.showNewsletterPopup ? <NewsletterPopup /> : <></>}
          {this.props.isMobile ? <MobileMenu /> : <></>}
        </Container>
        <Container onMouseMove={() => {
          if (window.closeMenu) {
            window.closeMenu();
          }
        }} style={{ maxWidth: "100vw", marginBottom: this.props.isMobile ? '100px' : '0' }}>
          <ParallaxProvider>
            <Route exact path="/" component={HomePage} />
            <Route exact path="/about" component={OurStoryPage} />
            <Route exact path="/products/:typeUri" component={CategoryProductListPage} />
            <Route exact path="/brand/:brandUri" component={BrandProductListPage} />
            <Route exact path="/product/:productUri" component={ProductPage} />
            <Route exact path="/cart" component={YourCartPage} />
            <Route exact path="/pre-checkout" component={PreCheckoutPage} />
            <Route exact path="/checkout" component={CheckoutPage} />
            <Route exact path="/account" component={MyAccountPage} />
            <Route exact path="/history" component={HistoricalOrder} />
            <Route exact path="/contact" component={ContactPage} />
            <Route exact path="/delivery-info" component={DeliveryInfoPage} />
            <Route exact path="/company-info" component={CompanyInfoPage} />
            <Route exact path="/login" component={LogInPage} />
            <Route exact path="/reset-password" component={ResetPasswordPage} />
            <Route exact path="/privacy-policy" component={PrivacyPolicy} />
            <Route exact path="/cookies-policy" component={CookiesPolicy} />
            <Route exact path="/terms-and-conditions" component={TermsAndConditions} />
            <Route exact path="/payment-info" component={PaymentInfoPage} />
            <Route exact path="/mens-range" component={MensProductListPage} />
            <Route exact path="/thank-you" component={ThankYouPage} />
            <Route exact path="/payment-error" component={FailedPaymentPage} />
            <Route exact path="/restore" component={RestoreProductListPage} />
            <Route exact path="/refresh" component={RefreshProductListPage} />
          </ParallaxProvider>
          {this.props.isMobile ? <></> : <MainFooter />}
        </Container>
        {this.state.acceptCookies ? <></> : <CookieBar setAcceptCookies={this.setAcceptCookies.bind(this)} />}
      </Router>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isMobile: state.isMobile, // (1)
    user: state.user,
    showCurrencyPopup: state.showCurrencyPopup,
    showNewsletterPopup: state.showNewsletterPopup,
    locale: state.locale,
  };
};
const mapDispatchToProps = { isMobileFetched, userFetched, showCurrencyPopupFetched, showNewsletterPopupFetched, localeFetched, cartFetched }; // (2)

export const AppContainer = connect(mapStateToProps, mapDispatchToProps)(App); // (3)
