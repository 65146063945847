import React from 'react';
import { localeFetched, cartFetched } from "../../actions";
import { connect } from "react-redux";
import Request from '../../api/Request';
import DeliveryCostCalculator from '../../models/DeliveryCostCalculator';

class DeliveryCost extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            costs: {},
            exchange: {},
        }
    }

    componentDidMount() {
        Request.getExchange(response => {
            if (response.success) {
                this.setState({ exchange: response.exchangeRates });
            }
        });
    }

    componentDidUpdate(prevProps) {
        Request.getDeliveryCosts(response => {
            if (response.success && (!this.state.costs.length || response.deliveryCosts[0].UPS_price != this.state.costs[0].UPS_price)) {
                this.setState({ costs: response.deliveryCosts });
            }
        }, this.props.cart, this.props.locale.country);
    }

    render() {
        let calculator = new DeliveryCostCalculator(this.props.locale, this.state.costs, this.state.exchange);
        return <> {this.props.onlyNumber ? '' : this.props.locale.country + ':'} {calculator.calculateCost('lowest_price', this.props.locale.currency)} </>
    }
}

const mapStateToProps = (state) => {
    return {
        locale: state.locale,
        cart: state.cart,
    };
};
const mapDispatchToProps = { localeFetched, cartFetched }; // (2)
export default connect(mapStateToProps, mapDispatchToProps)(DeliveryCost);