import React from "react";
import { Carousel, Button } from "react-bootstrap";
import R1A0448a1 from "../../img/R1A0448a1.jpg";
import R1A0473a1 from "../../img/R1A0473a1.jpg";
import R1A5543a1 from "../../img/R1A5543a1.jpg";
import { Link } from "react-router-dom";
import colors from "../../colors/colors";
import { connect } from "react-redux";
import { isMobileFetched, whiteHeaderFetched, currentCategoryFetched, currentTypeFetched, searchValueFetched  } from "../../actions";
import { ReactComponent as Refresh } from "../../svg/refresh.svg";


class CTASlider extends React.Component {
  slideChange(slideNumber) {
    switch (slideNumber) {
      case 0:
        this.props.whiteHeaderFetched(true);
        if(this.props.isMobile == false){
        let darkPrevOne =  document.getElementsByClassName('carousel-control-prev-icon');
        let darkNextOne =  document.getElementsByClassName('carousel-control-next-icon');
        darkPrevOne[0].classList.remove("enhanceCarouselItem");
        darkNextOne[0].classList.remove("enhanceCarouselItem");}
        break;
      case 1:
        this.props.whiteHeaderFetched(false);
        if(this.props.isMobile == false){
        let prevIcon =  document.getElementsByClassName('carousel-control-prev-icon');
        prevIcon[0].classList.add("enhanceCarouselItem");

        let nextIcon =  document.getElementsByClassName('carousel-control-next-icon');
        nextIcon[0].classList.add("enhanceCarouselItem");}
        break;
      case 2:
        this.props.whiteHeaderFetched(true);
        if(this.props.isMobile == false){
        let darkPrev =  document.getElementsByClassName('carousel-control-prev-icon');
        let darkNext =  document.getElementsByClassName('carousel-control-next-icon');
        darkPrev[0].classList.remove("enhanceCarouselItem");
        darkNext[0].classList.remove("enhanceCarouselItem");}
        break;
    }
  }

  setCategory() {
    this.props.currentTypeFetched(3);
    this.props.currentCategoryFetched({
      brandId: 1,
      brandName: 'Teosyal',
      brandDescription: '<p>This is description of Teosyal brand</p>',
    });
    this.props.searchValueFetched("");
  }

  render() {
    return (
      <Carousel controls={this.props.isMobile ? false : true} indicators={false} onSlid={this.slideChange.bind(this)}>
        {/* SLIDE ONE */}
        <Carousel.Item interval={6000}>
          <div
            className="sliderContainer"
            style={{
              width: "100%",
              height: this.props.isMobile ? '70vh' : "100vh",
              background:
                "url(" + R1A0448a1 + ") no-repeat center center",
              backgroundSize: "cover",
              backgroundPosition: 'top',
            }}
          ></div>
          <Carousel.Caption style={this.props.isMobile ? {zIndex: 2} : { bottom: "13%", zIndex: 2, width: 'max-content', position: 'absolute', top: '15%', left: '2%', }}>
            <h2
              style={{
                fontFamily: "Libel-Suit",
                fontSize: "96px",
                color: "#91D4E4",
                marginBottom: 0,
              }}
            >
              restore
            </h2>
            <h5
              style={{
                fontFamily: "Montserrat",
                fontSize: "18px",
                color: colors.white,
                marginBottom: this.props.isMobile ? '120px' : '30px',
              }}
            >
              brand new body
            </h5>
            <Link to="/restore">
              <Button
                // onClick={this.setCategory.bind(this)}
                id="restore-button"
                style={{
                  border: "none",
                  borderRadius: "18px",
                  color: colors.white,
                  fontSize: "18px",
                  fontWeight: "600",
                  padding: "2px 60px 5px",
                  //   width: '190px',
                  background:
                    "transparent linear-gradient(98deg, rgba(145, 212, 228, 0.9) 0%, rgba(169, 157, 188, 0.55) 100%) 0% 0% no-repeat padding-box",
                  transition: '1s',
                  position: this.props.isMobile ? 'absolute' : 'inherit',
                  left: this.props.isMobile ? 'calc( 50% - 105px )' : 'inherit',
                  bottom: this.props.isMobile ? '0px' : 'inherit',
                }}
                variant="outline-primary"
              >
                show more
              </Button>
            </Link>{" "}
          </Carousel.Caption>
        </Carousel.Item>
        {/* SLIDE TWO */}
        <Carousel.Item interval={6000}>
          <div
            className="sliderContainer"
            style={{
              width: "100vw",
              height: this.props.isMobile ? '70vh' : "100vh",
              background:
                "url(" + R1A0473a1 + ") no-repeat center center",
              backgroundSize: "cover",
            }}
          ></div>
          <Carousel.Caption style={this.props.isMobile ? { zIndex: 2, width: '250px', height: '100%', margin: 'auto'} :{ bottom: "13%", zIndex: 2, width: 'max-content', position: 'absolute', top: 'calc(50% - 20vh)', left: '6%', textAlign: 'left' }}>
            <h2
              style={
                this.props.isMobile ? {
                fontFamily: "Migha",
                fontSize: "65px",
                color: "#49556D",
                marginBottom: 0,
                letterSpacing: '5.4px',
                position: 'absolute',
                top: '100px'
                } :
                {
                fontFamily: "Migha",
                fontSize: "90px",
                color: "#49556D",
                marginBottom: 0,
                letterSpacing: '5.4px',
              }}
            >
              enhance
            </h2>
            <h5
              style={
                this.props.isMobile ? {
                  fontFamily: "Quicksand",
                  fontSize: "18px",
                  color: colors.graphite,
                  marginBottom: '30px',
                  position: 'absolute',
                  top: '165px',
                } :
                {
                fontFamily: "Quicksand",
                fontSize: "25px",
                color: colors.graphite,
                marginBottom: '30px',
              }}
            >
              Revitalise your appearance
            </h5>
            <Link to="/products/8-enhance">
              <Button
                // onClick={this.setCategory.bind(this)}
                id="enhance-button"
                style={{
                  padding: "3px 0px 5px",
                  border: "none",
                  borderRadius: "18px",
                  color: colors.white,
                  fontSize: "18px",
                  fontWeight: "600",
                  width: '250px',
                  background:
                    "transparent linear-gradient(97deg, rgba(158, 63, 121, 0.8) 0%, rgba(73, 85, 109, 0.7)  100%) 0% 0% no-repeat padding-box",
                  position: this.props.isMobile ? 'absolute' : 'inherit',
                  left: this.props.isMobile ? '0px' : 'inherit',
                  bottom: this.props.isMobile ? '0px' : 'inherit',
                }}
                variant="outline-primary"
              >
                view our products
              </Button>
            </Link>{" "}
          </Carousel.Caption>
        </Carousel.Item>
        {/* SLIDE THREE */}
        <Carousel.Item interval={6000}>
          <div
            className="sliderContainer"
            style={{
              width: "100vw",
              height: this.props.isMobile ? '70vh' : "100vh",
              background:
                "url(" + R1A5543a1 + ") no-repeat center center",
              backgroundSize: "cover",
            }}
          ></div>
          <Carousel.Caption style={this.props.isMobile ? {height: '100%', zIndex: 0 } : { bottom: "13%", zIndex: 0 }}>
            <h2><Refresh style={this.props.isMobile ? {width: '125%', position: 'relative', left: '-10%'}: {}} /></h2>
            <h5
              style={{
                fontFamily: "Montserrat",
                fontSize: "18px",
                color: colors.white,
                marginBottom: '90px',
                position: 'relative',
                top: this.props.isMobile ? '-90px' : '-60px',
                left: this.props.isMobile ? '50px' : '40px',
                fontWeight: 'Bold',
              }}
            >
              rejuvenate your skin
            </h5>
            <Link style={{ position: 'absolute', bottom: '0%' }} to="/refresh">
              <Button
                // onClick={this.setCategory.bind(this)}
                id="slider-button"
                style={{
                  border: "none",
                  borderRadius: "18px",
                  color: '#968C95',
                  fontSize: "18px",
                  fontWeight: "600",
                  padding: "3px 0px 5px",
                  width: '250px',
                  backgroundColor: 'rgba(255, 255, 255, 0.85)',
                  margin: '0 calc( (100% - 250px) / 2)'
                }}
                variant="outline-primary"
              >
                view our products
              </Button>
            </Link>{" "}
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isMobile: state.isMobile, // (1)
    whiteHeader: state.whiteHeader,
  };
};
const mapDispatchToProps = { isMobileFetched, whiteHeaderFetched, currentCategoryFetched, currentTypeFetched, searchValueFetched  }; // (2)
export default connect(mapStateToProps, mapDispatchToProps)(CTASlider);
