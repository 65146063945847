import React from 'react';
import { Col, Row, InputGroup, Form } from 'react-bootstrap';
import colors from '../../colors/colors';
import { ReactComponent as Cart } from "../../svg/cart.svg";
import { ReactComponent as Search } from "../../svg/search.svg";
import { ReactComponent as User } from "../../svg/user.svg";
import { ReactComponent as Menu } from "../../svg/menu.svg";
import { ReactComponent as Info } from "../../svg/info.svg";
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import { currentCategoryFetched, searchValueFetched, whiteHeaderFetched, currentTypeFetched, homePageFetched } from "../../actions";
import Request from '../../api/Request';
import { withRouter } from "react-router-dom";

class MobileMenu extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
          showInfoMenu: false,
          showSearchScreen: false,
          showShopMenu: false,
          brands: [],
          hints: [],
        };
      }

    componentDidMount() {
        Request.getCategories(response => {
          if (response.success) {
            this.setState({ brands: response.categories });
          }
        });     
    }

    setShowInfoMenuValue(){
      if(this.state.showInfoMenu){
        this.setState({showInfoMenu: false});
      }else{
        this.setState({showInfoMenu: true, showSearchScreen: false, showShopMenu: false});
      }
    }

    setShowSearchScreenValue(){
      if(this.state.showSearchScreen){
        this.setState({showSearchScreen: false});
      }else{
        this.setState({showSearchScreen: true, showInfoMenu: false, showShopMenu: false});
      }
    }

    setShowShopMenuValue(){
      if(this.state.showShopMenu){
        this.setState({showShopMenu: false});
      }else{
        this.setState({showShopMenu: true, showInfoMenu: false, showSearchScreen: false});
      }
    }

    closeAll(){
      this.setState({
        showInfoMenu: false,
        showSearchScreen: false,
        showShopMenu: false,
      });
      if(window.showAccountMenu){
      window.showAccountMenu();
     }
    }

    setCategory(id) {
        this.props.currentCategoryFetched(id);
        this.props.searchValueFetched("");
      }
    
    setType(type) {
        this.props.currentTypeFetched(type);
        this.props.searchValueFetched("");
    }

    handleSearchValueChange(event) {
        const { value } = event.target;
        this.setState({ searchInputValue: value });
        if (value.length > 2) {
          Request.getSearchHints(value, (response) => {
            this.setState({ hints: response.hints });
          });
        }
      }
    

    render(){
        const infoMenuClass = this.state.showInfoMenu ? 'infoMenu' : 'infoMenu infoMenuHidden'
        const shopMenuClass = this.state.showShopMenu ? 'shopMenu' : 'shopMenu shopMenuHidden'
        const searchScreenClass = this.state.showSearchScreen ? 'searchScreenMobile' : 'searchScreenMobile searchScreenMobileHidden'

        const menuItem ={
            fontFamily: "Raleway",
            fontWeight: 900,
            color: colors.blue,
            fontSize: '20px',
            marginBottom: '25px',
        }

        return(
            <>
            <Row style={{background: colors.white, height: '64px', position: 'fixed', bottom: 0, width: '100%', zIndex: 99999}}>
                <Col className='my-auto text-center'><Menu onClick={this.setShowShopMenuValue.bind(this)} /></Col>
                <Col className='my-auto text-center'><Search onClick={this.setShowSearchScreenValue.bind(this)} /></Col>
                <Col className='my-auto text-center'><Link to='/cart'><Cart onClick={this.closeAll.bind(this)} /></Link></Col>
                <Col className='my-auto text-center'><Link to='/account'><User onClick={this.closeAll.bind(this)} /></Link></Col>
                <Col className='my-auto text-center'><Info onClick={this.setShowInfoMenuValue.bind(this)} /></Col>
            </Row>
            <div className={infoMenuClass} style={{width: '100vw', height: '100vh', position: 'fixed', top: 0, left: 0, transition: '1s', background: colors.white, padding: '80px 20px 20px'}} >
                <ul style={{listStyle: 'none', padding: 0}}>
                    <Link style={{textDecoration: 'none'}} to='/contact'><li style={menuItem} onClick={this.setShowInfoMenuValue.bind(this)}>Contact</li></Link>
                    <Link style={{textDecoration: 'none'}} to='/about'><li style={menuItem} onClick={this.setShowInfoMenuValue.bind(this)}>Our story</li></Link>
                    <Link style={{textDecoration: 'none'}} to='/company-info'><li style={menuItem} onClick={this.setShowInfoMenuValue.bind(this)}>Company information</li></Link>
                    <Link style={{textDecoration: 'none'}} to='/delivery-info'><li style={menuItem} onClick={this.setShowInfoMenuValue.bind(this)}>Delivery</li></Link>
                    <Link style={{textDecoration: 'none'}} to='/payment-info'><li style={menuItem} onClick={this.setShowInfoMenuValue.bind(this)}>Payment</li></Link>
                    <Link style={{textDecoration: 'none'}} to='/terms-and-conditions'><li style={menuItem} onClick={this.setShowInfoMenuValue.bind(this)}>Terms & conditions</li></Link>
                    <Link style={{textDecoration: 'none'}} to='/privacy-policy'><li style={menuItem} onClick={this.setShowInfoMenuValue.bind(this)}>Privacy policy</li></Link>
                    <Link style={{textDecoration: 'none'}} to='/cookies-policy'><li style={menuItem} onClick={this.setShowInfoMenuValue.bind(this)}>Cookies policy</li></Link>    
                </ul>
            </div>
            <div className={shopMenuClass} style={{width: '100vw', height: '100vh', position: 'fixed', top: 0, left: 0, transition: '1s', background: colors.white, padding: '80px 20px 20px'}} >
                <ul style={{listStyle: 'none', padding: 0}}>
                {this.state.brands.map((category, id) => {
                    return(
                    <Link 
                        key={id} 
                        style={{textDecoration: 'none'}} 
                        to={"/products/" + category.uri}>
                            <li 
                                style={menuItem} 
                                onClick={() => {
                                    this.setShowShopMenuValue();
                                    this.setType(category.type)
                                 }}>
                        {category.name}</li></Link>
                    )
                })}
                </ul>
            </div>
            <div className={searchScreenClass} style={{width: '100vw', height: '100vh', position: 'fixed', top: 0, left: 0, transition: '1s', background: colors.white, padding: '80px 20px 20px'}} >
            <form onSubmit={() => {
              return false;
            }}>
              <Form.Group controlId="search">
                <InputGroup className="searchInput" >
                  <Form.Control
                    type="text"
                    value={this.state.searchInputValue}
                    onChange={this.handleSearchValueChange.bind(this)}
                  />
                </InputGroup>
                <div className="tips" style={{overflow: 'auto'}} >
                  {
                    this.state.hints.map((hint) => (
                      <span
                        className='searchHint'
                        onClick={() => {
                        this.setState({ searchInputValue: '' }, () => {
                          this.setShowSearchScreenValue();
                          this.setState({ hints: [] });
                          this.props.history.push('product/' + hint.uri);
                        })
                      }} style={{ marginTop: '20px', color: colors.graphite, cursor: 'pointer', position: 'relative', display: 'block', width: 'max-content' }}>{hint.name}</span>
                    ))
                  }
                </div>
                <Search
                  style={{
                    width: "25px",
                    marginTop: "23px",
                    position: "absolute",
                    top: '66px',
                    left: "6vw",
                  }}
                />
              </Form.Group>
            </form>
            </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
      currentCategory: state.currentCategory,
      white: state.whiteHeader,
      currentType: state.currentType,
      homePage: state.homePage,
    };
  };
  const mapDispatchToProps = {currentCategoryFetched, searchValueFetched, whiteHeaderFetched, currentTypeFetched, homePageFetched}; // (2)
  export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MobileMenu));