import React from "react";
import { Col, Row, Image, Form } from "react-bootstrap";
import colors from "../../colors/colors";
import { Plus, Dash } from "react-bootstrap-icons";
import Price from "../../components/general/Price";
import Request from "../../api/Request";
import DeliveryCost from "../../components/general/DeliveryCost";
import QuickBuyForm from "../../components/payment/QuickBuyForm";
import BlueButton from "../../components/general/BlueButton";
import ProductCarousel from "../home/ProductCarousel";

export default class MobileProductPage extends React.Component {
  render() {
    const paragraph = {
      fontSize: "16px",
      color: colors.graphite,
      fontFamily: "Montserrat",
      fontWeight: 500,
    };

    const paragraphBold = {
      fontSize: "16px",
      color: colors.graphite,
      fontFamily: "Montserrat",
      fontWeight: 600,
    };
    
    return (
      <>
        <Row style={{ paddingTop: "80px" }}>
          <Col xs={8}>
            <h3
              style={{
                fontSize: "20px",
                color: colors.blue,
                fontFamily: "Raleway",
                fontWeight: 900,
                marginTop: "10px",
                marginBottom: "20px",
              }}
            >
              {this.props.product.name}
            </h3>
          </Col>
          <Col xs={4} style={{ textAlign: "right" }}>
            <h3
              style={{
                fontSize: "20px",
                color: colors.black,
                fontFamily: "Montserrat",
                fontWeight: 700,
                marginTop: "10px",
                marginBottom: "20px",
              }}
            >
              <Price price={this.props.product.price} />
            </h3>
          </Col>
        </Row>
        <Row>
          <Col style={{ textAlign: "center" }}>
            <Image
              style={{ maxWidth: "100%" }}
              alt={this.props.product.alt_tag}
              src={Request.realProductImage(this.props.product.small_image)}
            />
            <div
              style={{
                boxShadow: "0px 3px 6px #35749066",
                border: "1px solid #35749033",
                borderRadius: "5px",
                height: "38px",
                width: "190px",
                padding: "0 20px",
                margin: "0 auto 20px",
              }}
            >
              <div style={{ display: "flex" }}>
                <Dash
                  onClick={this.props.quantityMinus}
                  style={{ marginTop: "12px" }}
                  color={colors.lightBlue}
                />
                <Form.Group>
                  <Form.Control
                    value={this.props.quantity}
                    onChange={this.props.handleQuantityChange}
                    type="number"
                    className="no-spinner addToCartQuantity addToCartQuantityMobile"
                    min={1}
                  />
                </Form.Group>
                <Plus
                  onClick={this.props.quantityPlus}
                  style={{ marginTop: "12px" }}
                  color={colors.lightBlue}
                />
              </div>
            </div>
            {this.props.hideAddToCart ? (
              <></>
            ) : (
              <BlueButton
                content="Add to cart"
                onClick={() => {
                  this.props.addToCart();
                }}
                margin="0px calc(50% - 95px) 20px"
              />
            )}
            <QuickBuyForm
              noCol={true}
              product={this.props.product}
              quantity={this.props.quantity}
              onClick={() => this.props.hideAddToCartMobile()}
            />
          </Col>
        </Row>
        <Row style={{ marginTop: "10px" }}>
          <Col>
            <p
              style={{
                color: "#2E2E2EBF",
                fontSize: "14px",
                fontFamily: "Montserrat",
                marginTop: "20px",
                marginBottom: "0px",
              }}
            >
              Shipping to {this.props.locale.country} from:{" "}
              <DeliveryCost onlyNumber={true} />
            </p>
            <p
              style={{
                color: "#2E2E2EBF",
                fontSize: "14px",
                fontFamily: "Montserrat",
                marginTop: "5px",
                marginBottom: "30px",
              }}
            >
              Our products are sold for professional use only
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p style={paragraph}>{this.props.product.capacity}</p>
            <p style={paragraphBold}>Compositions:</p>
            <ul style={paragraph}>
              {this.props.product.compositions ? (
                this.props.product.compositions.map((point, id) => {
                  return <li key={id}>{point}</li>;
                })
              ) : (
                <></>
              )}
            </ul>
            <p style={paragraphBold}>Contains:</p>
            <ul style={paragraph}>
              {this.props.product.contains ? (
                this.props.product.contains.map((point, id) => {
                  return <li key={id}>{point}</li>;
                })
              ) : (
                <></>
              )}
            </ul>
            <div
              style={{
                fontSize: "14px",
                color: colors.gray,
                fontFamily: "Montserrat",
                fontWeight: 400,
              }}
            ></div>
            <div
              style={paragraph}
              dangerouslySetInnerHTML={{
                __html: this.props.product.description,
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <p
              style={{
                fontSize: "18px",
                color: colors.graphite,
                fontWeight: 700,
                fontFamily: "Montserrat",
                marginBottom: "-30px",
              }}
            >
              You might be also interested in:
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <ProductCarousel isMobile={true} products={this.props.products} />
          </Col>
        </Row>
      </>
    );
  }
}
