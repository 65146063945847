import React from "react";
import colors from "../../colors/colors";
import CountrySelect from "../form/CountrySelect";
import { Form } from "react-bootstrap";
import { connect } from "react-redux";
import { isMobileFetched, localeFetched, showCurrencyPopupFetched } from "../../actions";
import { X } from "react-bootstrap-icons";
import BlueButton from "./BlueButton";

class CurrencyPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      countryValue: "",
      currencyValue: "",
    };
  }

  getCountryFromBrowser() {
    switch (navigator.language.toLowerCase()) {
      case 'pl-pl': return 'Poland';
      case 'en-us': return 'United States of America';
      case 'en-gb': return 'United Kingdom';
      default: return '';
    }
  }

  componentDidMount() {
    if (!this.props.locale.country) {
      this.setState({ countryValue: this.getCountryFromBrowser() });
    } else {
      this.setState({ countryValue: this.props.locale.country });
    }
    if (!this.props.locale.currency) {
      if (this.getCountryFromBrowser() == 'Poland') {
        this.setState({ currencyValue: 'pln' })
      } else if (this.getCountryFromBrowser() == 'United States of America') {
        this.setState({ currencyValue: 'usd' })
      } else if (this.getCountryFromBrowser() == 'United Kingdom') {
        this.setState({ currencyValue: 'gbp' })
      } else {
        this.setState({ currencyValue: 'eur' })
      }
    } else {
      this.setState({ currencyValue: this.props.locale.currency })
    }
  }

  hendleCountryChange(event) {
    const { value } = event.target;
    this.setState({ countryValue: value });
  }

  hendleCurrencyChange(event) {
    const { value } = event.target;
    this.setState({ currencyValue: value });
  }

  saveLocale() {
    const locale = {
      country: this.state.countryValue,
      currency: this.state.currencyValue,
    }
    this.props.localeFetched(locale);
    this.closeCurrencyPopup();
    window.localStorage.setItem('locale', JSON.stringify(locale));
  }

  closeCurrencyPopup() {
    this.props.showCurrencyPopupFetched(false);
  }


  render() {
    return (
      <div
        style={{
          width: "100vw",
          height: "100vh",
          position: "fixed",
          top: 0,
          left: 0,
          backgroundColor: "rgba(255, 255, 255, 0.5)",
          zIndex: 999999999,
        }}
      >
        <div
          style={this.props.isMobile ? {
            backgroundColor: colors.white,
            width: "320px",
            height: "350px",
            position: "absolute",
            top: "calc( ( 100vh - 350px ) / 2 )",
            left: "calc( ( 100vw - 320px ) / 2 )",
            textAlign: "center",
            padding: "30px 40px",
            borderRadius: "10px",
            zIndex: 999999999,
            maxWidth: '100vw',
            }:
            {
            backgroundColor: colors.white,
            width: "500px",
            height: "370px",
            position: "absolute",
            top: "calc( ( 100vh - 370px ) / 2 )",
            left: "calc( ( 100vw - 500px ) / 2 )",
            textAlign: "center",
            padding: "30px 100px",
            borderRadius: "5px",
            zIndex: 999999999,
          }}
        >
          <h3
            style={{
              fontSize: "16px",
              fontWeight: "700",
              fontFamily: "Montserrat",
              textDecoration: "none",
              color: colors.graphite,
              marginBottom: "20px",
            }}
          >
            We ship worldwide!
          </h3>
          <CountrySelect
            label="Choose your country:"
            value={this.state.countryValue}
            onChange={this.hendleCountryChange.bind(this)}
          />
          <Form.Group style={{ marginBottom: "20px" }}>
            <Form.Label
              style={{
                fontSize: "15px",
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              And Currency
            </Form.Label>
            <Form.Control
              style={{
                backgroundColor: "#f5f5f5",
                border: 'none',
                borderRight: '10px solid #f5f5f5',
                cursor: 'pointer',
              }}
              value={this.state.currencyValue}
              onChange={this.hendleCurrencyChange.bind(this)}
              id="CountrySelect"
              as="select"
            >
              <option value="pln">PLN</option>
              <option value="eur">EUR</option>
              <option value="usd">USD</option>
              <option value="gbp">GBP</option>
            </Form.Control>{" "}
          </Form.Group>
          <BlueButton
            content='Save'
            onClick={this.saveLocale.bind(this)}
            margin= "20px auto"
            style={this.props.isMobile ?{left: "calc( ( 100vw - 190px ) / 2 ),"}:{}}
          />
          <X color={colors.black} size={20} style={{ position: 'absolute', top: '10px', right: '10px', cursor: 'pointer' }} onClick={this.saveLocale.bind(this)} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isMobile: state.isMobile, // (1)
    locale: state.locale,
    showCurrencyPopup: state.showCurrencyPopup
  };
};
const mapDispatchToProps = { isMobileFetched, localeFetched, showCurrencyPopupFetched }; // (2)
export default connect(mapStateToProps, mapDispatchToProps)(CurrencyPopup);

