import React from "react";
import { Form, Row, Col, Image, Container, Button } from "react-bootstrap";
import queryString from 'query-string';
import PasswordInput from "../components/form/PasswordInput";
import colors from "../colors/colors";
import { connect } from "react-redux";
import { isMobileFetched } from "../actions";
import Request from "../api/Request";

class ResetPasswordPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newPasswordInputValue: "",
      confirmPasswordInputValue: "",
      showPassword: true,
      isMobile: false,
      token: '',
      errorNumber: 1,
    };
  }

  componentDidMount() {
    this.setState({
      token: queryString.parse(this.props.location.search).token
    })
  }

  handleNewPasswordChange(event) {
    const { value } = event.target;
    this.setState({ newPasswordInputValue: value });
  }

  handleConfirmPasswordChange(event) {
    const { value } = event.target;
    this.setState({ confirmPasswordInputValue: value });
  }

  eyeClick() {
    this.setState({ showPassword: !this.state.showPassword });
  }

  forgotPasswordClick() {
    this.setState({ forgotPassword: !this.state.forgotPassword });
  }

  confirmNewPassword() {
    this.setState({ error: false });
    if (!this.state.newPasswordInputValue && !this.state.confirmPasswordInputValue) {
      this.setState({ errorNumber: 3, error: true });
      return;
    }
    if (this.state.newPasswordInputValue == this.state.confirmPasswordInputValue) {
      Request.doResetPassword({ token: this.state.token, password: this.state.newPasswordInputValue }, response => {
        if (response.success) {
          this.props.history.push('/')
        } else {
          this.setState({ errorNumber: 2, error: true });
        }
      })
    } else {
      this.setState({ errorNumber: 1, error: true });
    }
  }

  render() {
    const errorText = this.state.errorNumber == 1 ? 'The values are not the same' : (this.state.errorNumber == 2 ? 'Something went wrong, please try again later' : 'Please fill all inputs');
    return (
      <Container style={{ maxWidth: "100vw" }}>
        <Row
          style={
            this.props.isMobile
              ? {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: colors.white,
                height: "100vh",
                padding: "50px"
              }
              : {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: colors.white,
                paddingTop: '200px'
              }
          }
        >
          <Col md='4' sm='12' style={this.props.isMobile ? { minHeight: '170px' } : {}}>
            <Image
              style={
                this.props.isMobile
                  ? {
                    position: "fixed",
                    width: "115px",
                    top: "40px",
                    left: "calc(50% - 60px)"
                  }
                  : {
                    position: "fixed",
                    top: "40px",
                    left: "40px"
                  }
              }
            />
          </Col>
          <Col md='4' sm='12' >
            <Row>
              <Col md="2"></Col>
              <Col>
                <div style={{ textAlign: "center" }}>
                  <h2
                    style={{
                      textAlign: 'center',
                      color: colors.graphite,
                      fontSize: "21px",
                      fontFamily: "Raleway",
                      fontWeight: "600",
                      marginBottom: "30px",
                      width: '100%'
                    }}
                  >
                    Reset Password.
                    </h2>
                  <Form onSubmit={this.confirmNewPassword.bind(this)}>
                    <PasswordInput
                      type={this.state.showPassword}
                      password={this.state.newPasswordInputValue}
                      onChange={this.handleNewPasswordChange.bind(this)}
                      eyeClick={this.eyeClick.bind(this)}
                      label="New Password"
                    />
                    <PasswordInput
                      type={this.state.showPassword}
                      password={this.state.confirmPasswordInputValue}
                      onChange={this.handleConfirmPasswordChange.bind(this)}
                      eyeClick={this.eyeClick.bind(this)}
                      label="Confirm new Password"
                    />
                    <div style={{ textAlign: 'center' }}>
                      <Button
                        onClick={this.confirmNewPassword.bind(this)}
                        style={{
                          width: "60%",
                          backgroundColor: colors.lightBlue,
                          borderColor: colors.lightBlue,
                          fontFamily: "Montserrat",
                          fontWeight: 900,
                          color: colors.white,
                        }}
                      >Confirm</Button></div>
                    {this.state.error && <p style={{ color: 'red' }}>{errorText}</p>}
                  </Form>
                </div>
              </Col>
              <Col md="2"></Col>
            </Row>
          </Col>
          <Col></Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isMobile: state.isMobile, // (1)
  };
};
const mapDispatchToProps = { isMobileFetched }; // (2)
export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordPage);