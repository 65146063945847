import React from "react";
import UniversalInput from "./UniversalInput";
import { Form, Button } from "react-bootstrap";
import colors from "../../colors/colors";
import BlueButton from "../general/BlueButton";
import { connect } from "react-redux";
import { isMobileFetched } from "../../actions";
import UniversalCheck from "./UniversalCheck";
import Request from "../../api/Request";

class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      validated: false,
    };
  }

  sendMail() {

    if (!this.validate()) {
      this.setState({ hasBeenSubmited: true });
      return;
    }

    Request.sendEmail({name: this.props.name, email: this.props.email, text: this.props.text}, response => {
      // console.log(response);
    });
    this.setState({ hasBeenSubmited: false });
  }

  inputValidation() {
    
    if(this.props.text !== null && this.props.text.length > 2 ){
      this.setState({validated: true});
    }else{
      this.setState({validated: false});
    }
  }

  
  validate(){
    return (
      this.props.name != null &&
      this.props.name.length > 1 &&
      this.props.email != null && 
      this.props.email.length > 1 &&
      this.props.text != null &&
      this.props.text.length > 1 
    )  
  }

  render() {
    return (
      <>
        <UniversalInput onChange={this.props.handleNameChange} value={this.props.name} label="Full name"  
                        isError={ this.state.hasBeenSubmited && (this.props.name == null || this.props.name.length < 2)}
                        errorMessage="This field cannot be empty" />
        <UniversalInput onChange={this.props.handleEmailChange} isEmail={true} value={this.props.email} label="E-mail" 
                isError={this.state.hasBeenSubmited &&  (this.props.email == null || this.props.email.length < 2) }
                errorMessage="This field cannot be empty" />
        <Form.Group style={{ marginBottom: "20px" }}>
          <Form.Label
            style={this.props.isMobile ?{
              fontSize: "16px",
              fontFamily: "Montserrat",
              fontWeight: 500,
            }:
              {
              fontSize: "18px",
              fontFamily: "Montserrat",
              fontWeight: 500,
            }}
          >
            How can we help you?
          </Form.Label>
          <Form.Control
            as="textarea"
            style={this.props.isMobile ? {
               backgroundColor: "#f5f5f5", height: "108px", marginRight:"auto", marginLeft:"auto", paddingBottom:"8px", border: this.state.validated ? 'solid 1px #35749066' : 'none' }:
              { backgroundColor: "#f5f5f5", height: "150px", 
              border: this.state.validated ? 'solid 1px #35749066' : this.state.hasBeenSubmited &&  (this.props.text == null || this.props.text.length < 2)  ? 'solid 1px #f19cb0'  :'none' }}
            type={this.props.type}
            value={this.props.text}
            onChange={this.props.handleTextChange}
            onBlur={this.inputValidation.bind(this)}
          />
        </Form.Group>
        {this.state.hasBeenSubmited &&  (this.props.text == null || this.props.text.length < 2) ? <p style={{
        fontSize: '10px',
         color: colors.darkPink, 
         width: '100%', 
         marginBottom: '4px',
         marginTop: '-16px',
         marginLeft: '10px',
         }}>This field cannot be empty</p> : <></>}

        <Form.Check
                    onChange={this.props.temsCheckClick}
                    // checked={this.state.invoice}
                    value={this.props.acceptTerms}
                    className='invoiceCheckBox'
                    type="checkbox"
                    label="*I agree to the processing of data under the terms of the privacy policy."
                    style={{
                      fontSize: this.props.isMobile ? '16px' : "18px",
                      fontFamily: "Montserrat",
                      fontWeight: 500,
                      marginBottom: "20px",
                      marginLeft: this.props.isMobile ? '75px' : 'inherit',
                    }}
                  />

        <div style={{textAlign: 'center'}}>
          <BlueButton 
            onClick={this.sendMail.bind(this)}
           disabled={!this.props.acceptTerms}
           margin='0 0 20px'
           content='Send'
           width= {this.props.isMobile ? '190px' : '40%'}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isMobile: state.isMobile, // (1)
  };
};
const mapDispatchToProps = { isMobileFetched}; // (2)
export default connect(mapStateToProps, mapDispatchToProps)(ContactForm);
