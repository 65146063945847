import React from "react";
import {
  Row,
  Col,
  Image,
  FormControl,
  Button
} from "react-bootstrap";
import { Dash, Plus, ThreeDotsVertical, Trash } from "react-bootstrap-icons";
import colors from "../../colors/colors";
import Request from "../../api/Request";
import Price from "../general/Price";

export default class HistoricalProductOnList extends React.Component {
  render() {
    const paragraph = {
      fontSize: "16px",
      fontFamily: "Montserrat",
      fontWeight: 600,
      margin: 0,
    };

    const verticalAlign = {
      padding: "45px 15px"
    };

    return (
      <Row style={this.props.isMobile ? {
        marginTop: "10px", boxShadow: '0px 3px 6px #35749066',
        border: '1px solid rgba (54, 118, 145, 0.2)',
        borderRadius: '5px',
        opacity: '1',
        width:'100%',
        marginRight:'auto',
        marginLeft:'auto',
      } : { border: 'solid 1px rgba(54, 118, 145, 0.2)', borderRadius: '5px', margin: 0, marginBottom: '20px' }}>
        <Col className='my-auto' md="6" xs='12'>
          <Row>
            {!this.props.isMobile ? <Col className='my-auto' md="1" xs="1"><p style={{ fontSize: '17px' }}>{this.props.number + 1}.</p></Col> : <></>}
            <Col className='my-auto' md="4" xs="4" style={{ padding: 0 }}>
              <Image style={{ width: '100%' }}
                src={ this.props.product.current == null ? 'https://dummyimage.com/288x332/000000/ff0000.jpg&text=Product+not+available' :
                  Request.realProductImage(this.props.product.current.small_image)
                } />
            </Col>
            <Col
              className='my-auto'
              md="7"
              xs="8"
            >
            <div style={{marginBottom: '10px', textAlign: 'center'}}>
               <h5 style={{color: colors.blue,  fontFamily: "Montserrat", fontWeight: 600, fontSize: '12px', marginBottom: 0}}>Name</h5> 
              <p style={paragraph}>
                { this.props.product.current ? this.props.product.current.name : this.props.product.historical_name}
              </p>
            </div>
            {this.props.product.current && this.props.product.current.name != this.props.product.historical_name ?
            <div style={{marginBottom: '10px', textAlign: 'center'}}>
               <h5 style={{color: colors.blue,  fontFamily: "Montserrat", fontWeight: 600, fontSize: '12px', marginBottom: 0}}>Historical name</h5> 
              <p style={paragraph}>
                {this.props.product.historical_name}
              </p>
            </div> : <></> }
          
              {this.props.isMobile ? 
              <>
              <Row>
                <Col> 
                  <p style={paragraph, { display: 'flex', margin: 0 }}>
                  <form>
                          <Button className='ipmb-qty-button'
                            style={{
                              width: 'max-content',
                              height: 'max-content',
                              background: 'none',
                              border: 'none',
                              padding: 0,
                              margin: 0,
                              cursor: 'pointer',}}
                            >
                    <Dash
                      className='dashCircleHover'
                      color={colors.blue}
                      style={{ marginTop: "13px", cursor: 'pointer'  }}
                      onClick={() => this.props.quantityMinus(this.props.productId)}
                    />
                    </Button>
                    </form>
                    <FormControl
                      style={{ width: '65px', margin: '0px 0px 5px 0px', border:'none', textAlign:'center', fontSize:'18px', fontWeight:'500',}}
                      className="productQuantityCart no-spinner"
                      value={this.props.product.quantity}
                      onChange={event =>
                        this.props.handleInputChange(event, this.props.productId)
                      }
                      aria-describedby="basic-addon1"
                      type="number"
                    />
                    <form>
                          <Button className='ipmb-qty-button'
                            style={{
                              width: 'max-content',
                              height: 'max-content',
                              background: 'none',
                              border: 'none',
                              padding: 0,
                              margin: 0,
                              cursor: 'pointer',}}
                            >
                                 <Plus
                                    className='plusCircleHover'
                                    color={colors.blue}
                                    style={{ marginTop: "13px", cursor: 'pointer'  }}
                                    onClick={() => this.props.quantityPlus(this.props.productId)}
                                  />
                            </Button>
                    </form>

                  </p>
                </Col>
                <Col className='my-auto' style={{textAlign: 'right'}}>
                  <Trash color={colors.pink} style={{ marginLeft: '10px', marginTop: '-6px', cursor: 'pointer'  }} onClick={this.props.removeProduct} />
                </Col>
              </Row>
               <p style={({ fontWeight: '600' }, paragraph)}>
                  <Price price={this.props.product.historical_price} />
                </p>
              </>
              : <></>}
            </Col>
          </Row>
        </Col>
        {this.props.isMobile ? <></> : <>
        <Col className='my-auto' md="3" xs="5">
          <p style={paragraph, { display: 'flex', margin: 0 }}>
          <form>
             <Button className='ipmb-qty-button'
               style={{
                  width: this.props.product.current ? 'max-content' : '16px',
                  height: 'max-content',
                  background: 'none',
                  border: 'none',
                  padding: 0,
                  margin: 0,
                  cursor: 'pointer',}}
              >
                {this.props.product.current ? 
                <Dash
                  className='dashCircleHover'
                  color={colors.blue}
                  style={{ marginTop: "13px", cursor: 'pointer'  }}
                  onClick={() => this.props.quantityMinus(this.props.productId)}
                /> : <></> }
              </Button>
            </form>
            <FormControl
              style={{ width: '65px', margin: '0px 0px 5px 0px', border:'none', textAlign:'center', fontSize:'18px', fontWeight:'500',}}
              className="productQuantityCart no-spinner"
              value={this.props.product.quantity}
              onChange={event =>
                this.props.handleInputChange(event, this.props.productId)
              }
              aria-describedby="basic-addon1"
              type="number"
              disabled={!this.props.product.current}
            />
             <form>
             <Button className='ipmb-qty-button'
               style={{
                 width: 'max-content',
                  height: 'max-content',
                  background: 'none',
                  border: 'none',
                  padding: 0,
                  margin: 0,
                  cursor: 'pointer',}}
              >
            {this.props.product.current ? 
            <Plus
              className='plusCircleHover'
              color={colors.blue}
              style={{ marginTop: "13px", cursor: 'pointer'  }}
              onClick={() => this.props.quantityPlus(this.props.productId)}
            /> : <></> }
            </Button>
            </form>
          </p>
        </Col>
        <Col
          className='my-auto'
          md="2"
          style={verticalAlign}
        >

        
        <div style={{marginBottom: '10px', textAlign: 'center'}}>
          <h5 style={{color: colors.blue,  fontFamily: "Montserrat", fontWeight: 600, fontSize: '12px', marginBottom: 0}}>Price</h5> 
          <p style={({ fontWeight: '600' }, paragraph)}>
            <Price price={this.props.product.current ? this.props.product.current.price : this.props.product.historical_price} />
          </p>
        </div> 
        {this.props.product.current  && this.props.product.current.price != this.props.product.historical_price ?
        <div style={{marginBottom: '10px', textAlign: 'center'}}>
          <h5 style={{color: colors.blue,  fontFamily: "Montserrat", fontWeight: 600, fontSize: '12px', marginBottom: 0}}>Historical price</h5> 
          <p style={({ fontWeight: '600' }, paragraph)}>
            <Price price={this.props.product.historical_price} />
          </p>
        </div>   : <></> }


       


        </Col>
        <Col className='my-auto' md={1}><Trash color={colors.pink} style={{marginLeft: '10px', marginTop: '-6px', cursor: 'pointer'  }} onClick={this.props.removeProduct} /></Col>
          </>  }
      </Row>
    );
  }
}