export const isMobileFetched = (isMobile) => ({
    type: 'FETCH_IS_MOBILE',
    isMobile
  });

export const cartFetched = (cart) => { 
  window.localStorage.setItem('cart', JSON.stringify(cart));
  return {
  type: 'FETCH_CART',
  cart,
  }
}

export const userFetched = (user) => ({
  type: 'FETCH_USER',
  user
})

export const currentCategoryFetched = (currentCategory) => ({
  type: 'FETCH_CURRENT_CATEGORY',
  currentCategory
})

export const searchValueFetched = (searchValue) => ({
  type: 'FETCH_SEARCH_VALUE',
  searchValue
})

export const historicalOrderFetched = (historicalOrder) => ({
  type: 'FETCH_HISTORICAL_ORDER',
  historicalOrder
})

export const deliveryInfoFetched = (deliveryInfo) => ({
  type: 'FETCH_DELIVERY_INFO',
  deliveryInfo
})

export const lastOrderIdFetched = (lastOrderId) => ({
  type: 'FETCH_LAST_ORDER_ID',
  lastOrderId
})

export const whiteHeaderFetched = (whiteHeader) => ({
  type: 'FETCH_WHITE_HEADER',
  whiteHeader
})

export const currentTypeFetched = (currentType) => ({
  type: 'FETCH_CURRENT_TYPE',
  currentType
})

export const productToShowFetched = (productToShow) => ({
  type: 'FETCH_PRODUCT_TO_SHOW',
  productToShow
})

export const cartPageFetched = (cartPage) => ({
  type: 'FETCH_CART_PAGE',
  cartPage
})

export const homePageFetched = (homePage) => ({
  type: 'FETCH_HOME_PAGE',
  homePage
})

export const userPageFetched = (userPage) => ({
  type: 'FETCH_USER_PAGE',
  userPage
})

export const localeFetched = (locale) => ({
  type: 'FETCH_LOCALE',
  locale
})

export const showCurrencyPopupFetched = (showCurrencyPopup) => ({
  type: 'FETCH_SHOW_CURRENCY_POPUP',
  showCurrencyPopup
})

export const showNewsletterPopupFetched = (showNewsletterPopup) => ({
  type: 'FETCH_SHOW_NEWSLETTER_POPUP',
  showNewsletterPopup
})
