import React from "react";
import { Form, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import colors from "../colors/colors";
import { connect } from "react-redux";
import { isMobileFetched, cartFetched, userFetched } from "../actions";
import ListingPage from "./ListingPage";
import Price from "../components/general/Price";
import { ReactComponent as FacebookF } from "../svg/facebook-f.svg";
import { ReactComponent as Google } from "../svg/google.svg";
import BlueButton from "../components/general/BlueButton";
import GoogleLogin from "react-google-login";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import UniversalInput from "../components/form/UniversalInput";
import PasswordInput from "../components/form/PasswordInput";
import Request from "../api/Request";
import OrderSummary from "../components/general/OrderSummary";

class PreCheckout extends ListingPage {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      showLoginPassword: true,
      login_email: '',
      login_password: '',
      login_email_error: 0,
      login_password_error: 0,
      login_message: '',
      forgotPassword: false,
      resetPasswordMessage: '',
    };
  }

  componentDidMount() {
    if (this.props.user && this.props.user.email) {
      this.props.history.push('/checkout');
    }
  }

  componentDidUpdate() {
    if (this.props.user && this.props.user.email) {
      this.props.history.push('/checkout');
    }
  }

  login() {
    this.setState({ login_email_error: !!!this.state.login_email, login_password_error: !!!this.state.login_password, });
    if (!this.state.login_email || !this.state.login_password) {
      return;
    }
    Request.login(this.state.login_email, this.state.login_password, response => {
      if (response.success) {
        this.props.userFetched({ ...response.me });
        window.localStorage.setItem('user', JSON.stringify({ ...response.me }));
        this.props.history.push("/account");
      } else {
        this.setState({ login_message: 'Please check email and password.', error: true });
      }
    });
  }

  responseFacebook(response) {
    if (!response.accessToken) {
      return;
    }
    Request.registerFacebook(response, result => {
      if (result.success) {
        this.props.userFetched({ ...result.me });
        window.localStorage.setItem('user', JSON.stringify({ ...result.me }));
        this.props.history.push("/account");
      }
    });
  }

  responseGoogle(response) {
    if (!response.googleId) {
      return;
    }
    Request.registerGoogle(response, result => {
      if (result.success) {
        this.props.userFetched({ ...result.me });
        window.localStorage.setItem('user', JSON.stringify({ ...result.me }));
        this.props.history.push("/account");
      }
    });
  }

  setForgotPassword() {
    this.setState({ forgotPassword: !this.state.forgotPassword, resetPasswordMessage: '' });
  }

  resetPassword() {
    this.setState({ error: false, resetPasswordMessage: '' });
    this.setState({ login_email_error: !!!this.state.login_email });
    if (!this.state.login_email) {
      return;
    }
    this.setState({ resetPasswordMessage: 'Please wait.' });
    Request.resetPassword({ email: this.state.login_email }, response => {
      this.setState({ resetPasswordMessage: 'Check your inbox.' });
    });
  }

  eyeLoginClick() {
    this.setState({ showLoginPassword: !this.state.showLoginPassword });
  }

  render() {
    return (
      <>
        <Row style={this.props.isMobile ? { paddingTop: "150px", letterSpacing: '0.5px' } : { paddingTop: "150px" }}>
          <>
            <Col md="4" xs="12">
              <h3
                style={this.props.isMobile ? {
                  fontSize: "24px",
                  color: colors.blue,
                  fontFamily: "Montserrat",
                  fontWeight: 700,
                  marginTop: "-30px",
                  marginBottom: "40px",
                  textAlign: 'center',
                } :
                  {
                    fontSize: "24px",
                    color: colors.blue,
                    fontFamily: "Montserrat",
                    fontWeight: 700,
                    marginTop: "19px",
                    marginBottom: "21px",
                  }}
              >
                Sign in
            </h3>
              <div
                style={this.props.isMobile ? {
                  border: "solid 1px rgba(54, 118, 145, 0.2)",
                  borderRadius: "5px",
                  margin: 0,
                  marginBottom: "20px",
                  padding: "20px",
                } :
                  {
                    border: "solid 1px rgba(54, 118, 145, 0.2)",
                    borderRadius: "5px",
                    margin: 0,
                    marginBottom: "20px",
                    padding: "20px",
                  }}
              >
                <Form>
                  <UniversalInput
                    isEmail={true}
                    type="email"
                    value={this.state.login_email}
                    onChange={(event) => this.setState({ login_email: event.target.value })}
                    label='Email address'
                    errorMessage={this.state.forgotPassword ? this.state.resetPasswordMessage : this.state.login_message}
                    isError={this.state.error}
                  />
                  {this.state.forgotPassword ? <>
                    <BlueButton
                      onClick={this.resetPassword.bind(this)}
                      content='Reset password'
                      margin="25px calc(50% - 95px) 15px"
                    />
                    {this.state.resetPasswordMessage}
                  </>
                    : <>
                      <PasswordInput
                        label='Password'
                        value={this.state.login_password}
                        onChange={(event) => this.setState({ login_password: event.target.value })}
                        type={this.state.showLoginPassword}
                        eyeClick={this.eyeLoginClick.bind(this)}
                        errorMessage={this.state.forgotPassword ? this.state.resetPasswordMessage : this.state.login_message}
                        isError={this.state.error}
                      />
                      <Form.Group controlId="formBasicCheckbox">
                        <Form.Check
                          className='rememberMeCheck'
                          style={{
                            fontSize: "15px",
                            fontFamily: "Montserrat",
                            fontWeight: 500,
                          }}
                          type="checkbox"
                          label="Remember me"
                        />
                      </Form.Group>
                      <BlueButton
                        onClick={this.login.bind(this)}
                        content='Log in'
                        margin="25px calc(50% - 95px) 15px"
                      />
                    </>}
                </Form>
                <div style={{ width: "100%", textAlign: "center" }}>
                  <Link
                    onClick={this.setForgotPassword.bind(this)}
                    style={{
                      fontSize: "12px",
                      fontFamily: "Montserrat",
                      fontWeight: 500,
                      color: colors.blue,
                      textDecoration: 'none',
                    }}
                  >
                    {this.state.forgotPassword ? 'Log in' : 'Forgot Password?'}
                  </Link>
                  {this.state.forgotPassword ? <></> : <>
                    <h4
                      style={{
                        fontSize: "14px",
                        fontFamily: "Montserrat",
                        fontWeight: 700,
                        marginTop: "15px",
                      }}
                    >
                      Or sign in with
                </h4>
                    <Row style={this.props.isMobile ? { width: "45%", margin: "15px auto", } : { width: "33%", margin: "15px auto", }}>
                      <Col xs={6}>
                        <FacebookLogin
                          appId="1038837456514519"
                          callback={this.responseFacebook.bind(this)}
                          fields="name,email"
                          render={renderProps => (
                            <FacebookF
                              style={{ cursor: 'pointer' }}
                              onClick={renderProps.onClick} />
                          )}
                        />
                      </Col>
                      <Col xs={6}>
                        <GoogleLogin
                          clientId="794548790556-rd5iscb6evjueslq22jf5q823tc9krg5.apps.googleusercontent.com"
                          render={renderProps => (
                            <Google
                              style={{ cursor: 'pointer' }}
                              onClick={renderProps.onClick} />
                          )}
                          buttonText="Login"
                          onSuccess={this.responseGoogle.bind(this)}
                          onFailure={this.responseGoogle.bind(this)}
                          cookiePolicy={'single_host_origin'}
                        />
                      </Col>
                    </Row>
                    <p
                      style={{
                        fontSize: "16px",
                        fontFamily: "Montserrat",
                        fontWeight: 500,
                      }}
                    >
                      Don't have an account?
                  </p>
                    <Link to='/login'><h4
                      style={{
                        fontSize: "16px",
                        fontFamily: "Montserrat",
                        fontWeight: 700,
                        color: colors.blue,
                      }}
                    >
                      Create one
                  </h4></Link>
                  </>}
                </div>
              </div>
            </Col>
            <Col md="4" xs="12">
              <h3
                style={this.props.isMobile ? {
                  fontSize: "24px",
                  color: colors.blue,
                  fontFamily: "Raleway",
                  fontWeight: 700,
                  marginTop: "19px",
                  marginBottom: "21px",
                  textAlign: 'center',
                } :
                  {
                    fontSize: "24px",
                    color: colors.blue,
                    fontFamily: "Raleway",
                    fontWeight: 700,
                    marginTop: "19px",
                    marginBottom: "21px",
                  }}
              >
                Check out as guest
              </h3>
              <div
                style={this.props.isMobile ? {
                  border: "solid 1px rgba(54, 118, 145, 0.2)",
                  borderRadius: "5px",
                  margin: 0,
                  marginBottom: "20px",
                  padding: "20px",
                } :
                  {
                    border: "solid 1px rgba(54, 118, 145, 0.2)",
                    borderRadius: "5px",
                    margin: 0,
                    marginBottom: "20px",
                    padding: "20px",
                  }}
              >
                <p
                  style={{
                    fontSize: "15px",
                    fontFamily: "Montserrat",
                    fontWeight: 500,
                  }}
                >
                  Choose this option if you don’t want to sign up.
                </p>
                <p
                  style={{
                    fontSize: "15px",
                    fontFamily: "Montserrat",
                    fontWeight: 500,
                  }}
                >
                  Please note that you won’t be able to view your order history.
                </p>
                <p
                  style={{
                    fontSize: "15px",
                    fontFamily: "Montserrat",
                    fontWeight: 500,
                  }}
                >
                  We recommend creating an account so you can get the complete Fräya experience.
                </p>
                <Link to="/checkout">
                  <BlueButton
                    content='Continue'
                    margin="25px calc(50% - 95px) 15px"
                  />
                </Link>
              </div>
              <p
                style={{
                  color: '#2E2E2EBF',
                  fontSize: "14px",
                  fontFamily: "Montserrat",
                }}
              >
                Our products are sold for professional use only
              </p>
            </Col>
          </>

          <OrderSummary
            rawTotal={this.rawTotal.bind(this)}
            cart={this.props.cart}
            loggedIn={this.state.loggedIn}
            hideButtons={true}
          />
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isMobile: state.isMobile, // (1)
    cart: state.cart,
    user: state.user,
  };
};
const mapDispatchToProps = { isMobileFetched, cartFetched, userFetched }; // (2)
export default connect(mapStateToProps, mapDispatchToProps)(PreCheckout);
