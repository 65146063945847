import React from "react";
import { Carousel, Col, Row } from "react-bootstrap";
import RecommendetProductOnList from "../productList/RecommendetProductOnList";

export default class ProductCarousel extends React.Component {


  sortProducts() {
    let unsortedProducts = this.props.products;
    let sortedProducts = [];
    let i, j, temparray, chunk = 2;
    for (i = 0, j = unsortedProducts.length; i < j; i += chunk) {
      temparray = unsortedProducts.slice(i, i + chunk);
      sortedProducts.push(temparray);
     
    }
    // console.log(sortedProducts);
    return sortedProducts;
  }

  render() {
    return (
      <Carousel style={{marginTop: '30px'}} controls={this.props.isMobile ? false : true}>
        
        {this.sortProducts().map((chunk) =>{
          return(
            <Carousel.Item> <Row>
             <RecommendetProductOnList product={chunk[0]} />
             {chunk.length == 2 ? 
             <RecommendetProductOnList product={chunk[1]} />
             :
             <Col></Col> }
          </Row>
          </Carousel.Item>
          )
        }
          )}
      </Carousel>
    );
  }
}
