import React from 'react';


export default class ListingPage extends React.Component{
    rawTotal(){
        let total = 0;
        const products = this.props.cart;
        for(let i=0; i < products.length; i++){
            total = total + products[i].price * products[i].quantity;
        }
    
        return total;
      }
}
