import React from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import colors from "../colors/colors";
import ProductInCart from "../components/cart/ProductInCart";
import { connect } from "react-redux";
import {
  isMobileFetched,
  cartFetched,
  userFetched,
  cartPageFetched,
} from "../actions";
import ListingPage from "./ListingPage";
import { ChevronLeft, DistributeVertical } from "react-bootstrap-icons";
import OrderSummary from "../components/general/OrderSummary";


class YourCart extends ListingPage {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: true,
      headerID: 1,
      checkoutPage: true,
      loggedIn: false,
      products: [],
    };
  }

  componentDidMount() {
    this.props.cartPageFetched(true);
    this.setState({ products: this.props.cart });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.cart.length == 0 && this.props.cart.length != 0) {
      this.setState({ products: this.props.cart });
    }
  }

  componentWillUnmount() {
    this.props.cartPageFetched(false);
  }

  handleQuantityChange(event, id) {
    const { value } = event.target;
    this.setState(
      (state) => {
        if (parseInt(value) > state.products[id].stock) {
          state.products[id].quantity = state.products[id].stock;
        } else if (parseInt(value) < 0) {
          state.products[id].quantity = 0;
        } else {
          state.products[id].quantity = parseInt(value);
        }
        return state;
      },
      () => {
        window.localStorage.setItem(
          "cart",
          JSON.stringify(this.state.products)
        );
      }
    );
    this.props.cartFetched(this.state.products);
    window.localStorage.setItem('cart', JSON.stringify(this.state.products));
  }

  quantityPlus(id) {
    this.setState(
      (state) => {
        if (
          parseInt(state.products[id].quantity) + 1 >
          state.products[id].stock
        ) {
          if(state.products[id].stock < 0){
            state.products[id].quantity = 0;
          }else{
          state.products[id].quantity = state.products[id].stock;
          }
        } else {
          state.products[id].quantity =
            parseInt(state.products[id].quantity) + 1;
        }
        return state;
      },
      () => {
        this.props.cartFetched(this.state.products);
        window.localStorage.setItem('cart', JSON.stringify(this.state.products));
      }
    );
  }

  quantityMinus(id) {
    this.setState(
      (state) => {
        if (state.products[id].quantity - 1 < 0) {
          state.products[id].quantity = 0;
        } else {
          state.products[id].quantity -= 1;
        }
        return state;
      },
      () => {
        this.props.cartFetched(this.state.products);
        window.localStorage.setItem('cart', JSON.stringify(this.state.products));
      }
    );
  }

  removeProduct(id) {
    const products = this.state.products;
    products.splice(id, 1);

    this.setState({ products: products }, () => {
      this.props.cartFetched(this.state.products);
      window.localStorage.setItem('cart', JSON.stringify(this.state.products));
    });
  }

  cagoryUri(){
    if(this.state.products.length){
    const lastProduct = this.state.products[this.state.products.length -1]
    return "products/" + lastProduct.type_uri;
    }
    return '';
  }

  render() {
    return (
      <>
        <Row style={this.props.isMobile ?{ paddingTop: "80px" , letterSpacing:'0.5px', marginBottom: '60px'}:{ paddingTop: "150px" , letterSpacing:'0.5px'}}>
          <Col md={8}>
            <Row>
              <Col>
                <h3
                  style={this.props.isMobile ?
                    {
                      fontSize: "26px",
                      color: colors.blue,
                      fontFamily: "Raleway",
                      fontWeight: 900,
                      marginTop: "0px",
                      marginBottom: "20px",
                      textAlign:'center',
                    }:
                    {
                    fontSize: "32px",
                    color: colors.blue,
                    fontFamily: "Raleway",
                    fontWeight: 900,
                    marginTop: "10px",
                    marginBottom: "20px",
                  }}
                >
                  Your Cart <span style={{color: colors.blue, fontWeight: 700, margin: '0 10px'}}>({this.state.products.length})</span>
                </h3>
              </Col>
            </Row>
            {this.state.products.length > 0 ? (
              <div>
                {this.state.products.map((product, id) => {
                  return (
                    <ProductInCart
                      key={id}
                      number={id}
                      name={product.name}
                      image={product.small_image}
                      price={product.price * product.quantity }
                      productQuantity={product.quantity}
                      handleInputChange={this.handleQuantityChange.bind(this)}
                      productId={id}
                      quantityPlus={this.quantityPlus.bind(this)}
                      quantityMinus={this.quantityMinus.bind(this)}
                      isMobile={this.props.isMobile}
                      removeProduct={() => {
                        this.removeProduct(id);
                      }}
                    />
                  );
                })}
              </div>
            ) : (
              <div>
                <Row style={{ margin: "50px 0% 50px 0%" }} >
                  <Col>
                    <h4
                      style={{
                        fontFamily: "Montserrat",
                        fontSize: "20px",
                        fontWeight: "600",
                        color: colors.graphite,
                      }}
                    >
                      Your cart is empty
                    </h4>
                  </Col>
                </Row>
              </div>
            )}
            <div style={{ display: "flex" }}>
              <Link className='continueShopping' style={{ marginTop: '20px', textDecoration: 'none' }} to={"/"  + this.cagoryUri()}>
                <ChevronLeft color={colors.blue} style={{ float: "left" }} />
                <span
                  style={{
                    fontSize: "16px",
                    color: colors.blue,
                    fontFamily: "Montserrat",
                    fontWeight: 700,
                    cursor: "pointer",
                    position: 'relative',
                    top: '-4px',
                  }}
                >
                  Continue shopping
                </span>
              </Link>
            </div>
          </Col>
          <OrderSummary
            rawTotal={this.rawTotal.bind(this)}
            cart={this.props.cart}
            loggedIn={this.state.loggedIn}
           />
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isMobile: state.isMobile, // (1)
    cart: state.cart,
    user: state.user,
    cartPage: state.cartPage,
  };
};
const mapDispatchToProps = {
  isMobileFetched,
  cartFetched,
  userFetched,
  cartPageFetched,
}; // (2)
export default connect(mapStateToProps, mapDispatchToProps)(YourCart);

