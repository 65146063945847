import React from "react";
import { Row, Col } from "react-bootstrap";
import colors from "../colors/colors";
import { connect } from "react-redux";
import { isMobileFetched, localeFetched } from "../actions";
import Request from "../api/Request";

class ThankYouPage extends React.Component {
  constructor(params) {
    super(params);

    this.STATUS_NEW = 0;
    this.STATUS_SUCCESS = 1;
    this.STATUS_ERROR = 2;

    this.state = {
      orderId: Request.getUrlParam("orderId"),
      status: this.STATUS_NEW,
    };
  }

  componentDidMount() {
    this.checkStatus();
  }

  checkStatus() {
    Request.checkPaymentStatus(this.state.orderId, (response) => {
      // if (response.success) {
      if (response.status == 1) {
        setTimeout(this.checkStatus.bind(this), 500);
      } else {
        this.setState({
          status:
            response.status != 6 ? this.STATUS_SUCCESS : this.STATUS_ERROR,
        });
      }
      // }
    });
  }

  render() {
    return (
      <div>
        <Row style={{ marginBottom: "0px" }}>
          <Col
            style={
              this.props.isMobile
                ? { paddingTop: "100px" }
                : { paddingTop: "230px" }
            }
          >
            <h1
              style={
                this.props.isMobile
                  ? {
                      fontSize: "26px",
                      color: colors.blue,
                      fontFamily: "Raleway",
                      fontWeight: 900,
                      paddingBottom: "10px",
                      textAlign: "center",
                    }
                  : {
                      fontSize: "90px",
                      color: colors.blue,
                      fontFamily: "Raleway",
                      fontWeight: 900,
                      paddingBottom: "50px",
                    }
              }
            >
              {this.state.status == this.STATUS_NEW
                ? "Checking payment status ..."
                : this.state.status == this.STATUS_SUCCESS
                ? "Thank You"
                : "Payment error"}
            </h1>
          </Col>
        </Row>
        <Row>
          <Col md={2}></Col>
          {!this.state.orderId ? (
            <Col>
              <h3
                style={{
                  fontSize: "18px",
                  color: colors.blue,
                  fontFamily: "Montserrat",
                  fontWeight: 700,
                  marginTop: "10px",
                  marginBottom: "20px",
                }}
              >
                Bank transfer details
              </h3>
              <p
                style={{
                  fontSize: "18px",
                  color: colors.graphite,
                  fontFamily: "Montserrat",
                  fontWeight: 500,
                  letterSpacing: "0.5px",
                }}
              >
                <strong>Company:</strong><br/>
                Fräya Group Sp. z o.o. (Ltd.)<br/>
                43/18 Swietojanska Street<br/>
                81 391 Gdynia, Poland <br/>
                VAT No.: PL586 235 06 92 <br/>
                Contact: +48 730 205 444
              </p>
              <p
                style={{
                  fontSize: "18px",
                  color: colors.graphite,
                  fontFamily: "Montserrat",
                  fontWeight: 500,
                  letterSpacing: "0.5px",
                }}
              >
                <strong>Bank account:</strong><br/>
               {this.props.locale.currency == 'pln' ? 'PL39194010763207843500000000' : <></>}
               {this.props.locale.currency == 'eur' ? 'PL29194010763207844800000000' : <></>}
               {this.props.locale.currency == 'usd' ? 'PL54194010763207846400000000' : <></>}
               {this.props.locale.currency == 'gbp' ? 'PL64194010763207845100000000' : <></>}
              </p>
            </Col>
          ) : (
            <Col>
              <h3
                style={{
                  fontSize: "18px",
                  color: colors.blue,
                  fontFamily: "Montserrat",
                  fontWeight: 700,
                  marginTop: "10px",
                  marginBottom: "20px",
                }}
              >
                Packaging process first!
              </h3>
              <p
                style={{
                  fontSize: "18px",
                  color: colors.graphite,
                  fontFamily: "Montserrat",
                  fontWeight: 500,
                  letterSpacing: "0.5px",
                }}
              >
                But, get ready – Your products will be delivered soon.
              </p>
            </Col>
          )}
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isMobile: state.isMobile, // (1)
    locale: state.locale,
  };
};
const mapDispatchToProps = { isMobileFetched, localeFetched }; // (2)
export default connect(mapStateToProps, mapDispatchToProps)(ThankYouPage);
