let locale = null;
let cost = null;
let exchange = null;
export default class DeliveryCostCalculator {
    constructor(locales, costs, exchanges) {
        locale = locales;
        cost = costs;
        exchange = exchanges
    }

    calcPrice(price) {
        return parseFloat(this.doCalcPrice(price).toFixed(2));
    }

    doCalcPrice(price) {
        switch (locale.currency) {
            case 'pln': return price * exchange.exchangePLN;
            case 'usd': return price * exchange.exchangeUSD;
            case 'gbp': return price * exchange.exchangeGBP;
            default: return price;
        }
    }

    getSymbol() {
        // console.log(locale.currency);
        switch (locale.currency) {
            case 'pln': return 'zł';
            case 'usd': return '$';
            case 'gbp': return '£';
            default: return '€';
        }
    }

    getThePrice(cost, carrier) {
        switch (carrier) {
            case 'lowest_price': return cost.UPS_price < cost.DHL_price ? cost.UPS_price : cost.DHL_price;
            case 'dhl': return cost.DHL_price;
            case 'ups': return cost.UPS_price;
            default: return 0;
        }
    }

    calculateCost(carrier, withSymbol) {
        withSymbol = typeof withSymbol == undefined ? true : withSymbol;
        carrier = carrier || 'lowest_price';
        let i = 0;
        let defaultLocale;
        for (i; i < cost.length; i++) {
            if (cost[i].country == 'Default') {
                defaultLocale = cost[i];
            }
            if (cost[i].country == locale.country) {

                return  this.calcPrice(this.getThePrice(cost[i], carrier)) + (withSymbol ? this.getSymbol() : 0.0);
            }
        }
        if (!defaultLocale) {
            return '';
        }
        return  this.calcPrice(this.getThePrice(defaultLocale, carrier)) + (withSymbol ? this.getSymbol() : 0.0);
    }
    calculateCostRaw() {
        let i = 0;
        let defaultLocale;
        for (i; i < cost.length; i++) {
            if (cost[i].country == 'Default') {
                defaultLocale = cost[i];
            }
            if (cost[i].country == locale.country) {

                return  this.getThePrice(cost[i], 'lowest_price');
            }
        }
        if (!defaultLocale) {
            return '';
        }
        return  this.getThePrice(defaultLocale, 'lowest_price');
    }
}