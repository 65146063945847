import React from "react";
import { Row, Col } from "react-bootstrap";
import colors from "../colors/colors";
import { connect } from "react-redux";
import { isMobileFetched } from "../actions";

class DeliveryInfoPage extends React.Component {
  render() {
    return (
      <div>
        <Row style={{ marginBottom: "0px" }}>
          <Col style={this.props.isMobile ?{ paddingTop: "100px" }:{ paddingTop: "230px" }}>
            <h1
              style={this.props.isMobile ?{
                fontSize: "26px",
                color: colors.blue,
                fontFamily: "Raleway",
                fontWeight: 900,
                paddingBottom: "10px",
                textAlign:'center',
              }:
                {
                fontSize: "90px",
                color: colors.blue,
                fontFamily: "Raleway",
                fontWeight: 900,
                paddingBottom: "50px",
              }}
            >
              Delivery
            </h1>
          </Col>
        </Row>
        <Row>
          <Col md={2}></Col>
          <Col>
            <h3
              style={{
                fontSize: "16px",
                color: colors.blue,
                fontFamily: "Montserrat",
                fontWeight: 700,
                marginTop: "10px",
                marginBottom: "20px",
              }}
            >
              Get your order right away
            </h3>
            <p
              style={this.props.isMobile ?{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
                letterSpacing:'0.5px',
                paddingTop:'10px',
              }:
                {
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              Taste our competitive shipping experience. We will process and
              dispatch your order immediately after you place it. Relax and
              comfortably track the whole process by receiving e-mail
              confirmations.
            </p>
            <p
              style={this.props.isMobile ?{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
                letterSpacing:'0.5px',
              }:
                {
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
                letterSpacing:'0.5px',
              }}
            >
              We carry a large stock of the most popular medical products. We
              are therefore in a position to ship your order the latest next
              working day. The same day as your order is shipped you will
              receive a sequent e-mail with tracking number. Each shipment is
              organized uniquely and in line with the order requirements and
              your demand by choosing the speediest and most secure delivery
              methods possible.{" "}
            </p>
            <h3
              style={this.props.isMobile ?{
                fontSize: "16px",
                color: colors.blue,
                fontFamily: "Montserrat",
                fontWeight: 700,
                marginTop: "30px",
                marginBottom: "30px",
                letterSpacing:'0.5px',
              }:
                {
                fontSize: "16px",
                color: colors.blue,
                fontFamily: "Montserrat",
                fontWeight: 700,
                marginTop: "30px",
                marginBottom: "30px",
                letterSpacing:'0.5px',
              }}
            >
              We deliver worldwide
            </h3>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
                letterSpacing:'0.5px',
              }}
            >
              We only use trusted carriers (DHL & DPD) so your product is in
              safe hands through every step of the process. The delivery time is
              normally up to 3 working days (US), 2 working days (Europe).
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
                letterSpacing:'0.5px',
              }}
            >
              Exact shipping charges are determined at the time of checkout
              based on the number of items, item type, weight and volume of all
              the items in the cart.
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
                letterSpacing:'0.5px',
                marginBottom: this.props.isMobile ? '80px' : "inherit" ,
              }}
            >
              All prices are exclusive of VAT, custom duties and taxes. The
              Customer is responsible for collection and payment of any charges
              according to regulations of a destination country.
            </p>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isMobile: state.isMobile, // (1)
  };
};
const mapDispatchToProps = { isMobileFetched}; // (2)
export default connect(mapStateToProps, mapDispatchToProps)(DeliveryInfoPage);