import { withController } from 'react-scroll-parallax';
import React from 'react';
 
class ParalaxImage extends React.Component {
    handleLoad = () => {
        // updates cached values after image dimensions have loaded
        this.props.parallaxController.update();
    };
 
    render() {
        return <img style={this.props.style} src={this.props.src} onLoad={this.handleLoad} />;
    }
}
 
export default withController(ParalaxImage);