import React from "react";
import { Form, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { isMobileFetched, localeFetched} from "../../actions";

class UniversalCheck extends React.Component {
  imageClick(event) {
    document.querySelector('#' + this.theId()).click();
  }

  theId() {
    return this.props.label.replace(/\W/g, '').replace(' ', '-');
  }

  priceExchange(price) {
    if (this.props.locale.currency == "eur") {
      return price + "€";
    } else if (this.props.locale.currency == "pln") {
      return price + "zł";
    } else if (this.props.locale.currency == "usd") {
      return price + "$";
    } else if (this.props.locale.currency == "gbp") {
      return price + "£";
    }
  }

  render() {
    return (
      <Row style={this.props.isMobile ? {marginTop: this.props.marginTop ? this.props.marginTop : '10px', marginLeft: '60px' } : { marginTop: this.props.marginTop ? this.props.marginTop : '10px' }}>
        <Col className='my-auto' md={3} xs={this.props.deliverCost ? 3 : 7}>
          {" "}
          <Form.Check
            className='universalCheck'
            style={{
              fontSize: "16px",
              fontFamily: "Montserrat",
              fontWeight: 500,
            }}
            type="radio"
            label={this.props.label}
            value={this.props.value || this.props.label}
            checked={this.props.checked}
            name={this.props.name}
            id={this.theId()}
          />
        </Col>
          {this.props.deliverCost ? <Col xs={2} className='my-auto' >
            <p onClick={this.imageClick.bind(this)} style={{ fontSize: "16px", fontFamily: "Montserrat", fontWeight: 500, margin: 0 }}>{this.priceExchange(this.props.deliverCost)}</p>
          </Col> : <></> }
        <Col className='my-auto'>
          <span style={{cursor: 'pointer',}} onClick={this.imageClick.bind(this)}>
            {this.props.image}
          </span>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isMobile: state.isMobile, // (1)
    locale: state.locale,
  };
};
const mapDispatchToProps = { isMobileFetched, localeFetched}; // (2)
export default connect(mapStateToProps, mapDispatchToProps)(UniversalCheck);
