import React from "react";
import { Col, Form, InputGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import colors from "../../colors/colors";
import { X } from "react-bootstrap-icons";
import { connect } from "react-redux";
import { searchValueFetched, cartFetched } from "../../actions";
//svg
// import { ReactComponent as Sygnet } from "../../svg/logoFraya.svg";
import { ReactComponent as Cart } from "../../svg/cart.svg";
import { ReactComponent as Search } from "../../svg/search.svg";
import { ReactComponent as User } from "../../svg/user.svg";
import { withRouter } from "react-router-dom";
import Request from "../../api/Request";
import Icon from "../Icon";

class SearchScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      whiteIcon: false,
      showList: false,
      alwaysWhiteIcon: false,
      searchInputValue: "",
      hints: [],
    };
  }

  hideMobileList() {
    this.setState({ showList: false });
  }

  showHideMobileList() {
    if (this.state.showList) {
      this.setState({ showList: false, whiteIcon: false });
    } else if (!this.setState.showList) {
      this.setState({ showList: true, whiteIcon: true });
    }
  }

  handleSearchValueChange(event) {
    const { value } = event.target;
    this.setState({ searchInputValue: value });
    if (value.length > 2) {
      Request.getSearchHints(value, (response) => {
        this.setState({ hints: response.hints });
      });
    }
  }

  render() {
    const searchScreenClass = this.props.showSearch
      ? "searchScreen"
      : "searchScreen searchScreenHidden";

      const cartNumber = {
        fontSize: "10px",
        borderRadius: "28px",
        textAlign: "center",
        letterSpacing: 0,
        fontWeight: "bold",
        width: "15px",
        position: "relative",
        top: "-30px",
        left: "15px",
        background: this.props.cartPage ? colors.pink : this.props.white ? colors.white : colors.graphite,
        color: this.props.white ? colors.graphite :  colors.white,
        textDecoration: 'none',
      };

    return (
      <div
        className={searchScreenClass}
        style={{
          position: "fixed",
          width: "100vw",
          height: "max-content",
          background: colors.white,
          top: "0",
          left: "0",
          transition: "1s",
          zIndex: "9",
        }}
      >
        <div
          className={searchScreenClass}
          style={{
            position: "fixed",
            width: "100vw",
            height: "max-content",
            background: colors.white,
            top: "0",
            left: "0",
            textAlign: "center",
            transition: "1s",
          }}
        >
          <Col md="2" style={{ textAlign: "left" }}>
            <Link to="/">
              <Icon style={{
                marginTop: "10px",
                width: '200px',
                margin: '0 auto',
              }} />
              {/* <Sygnet style={{ marginTop: "10px" }} /> */}
            </Link>
          </Col>
          <div>
            <form onSubmit={() => {
              return false;
            }}>
              <Form.Group controlId="search">
                <InputGroup
                  className="searchInput"
                  style={{
                    width: "70vw",
                    margin: "auto",
                    top: "-50px",
                  }}
                >
                  <Form.Control
                    type="text"
                    value={this.state.searchInputValue}
                    onChange={this.handleSearchValueChange.bind(this)}
                  />
                </InputGroup>
                <div
                  className="tips"
                  style={{
                    color: colors.white,
                    width: "70vw",
                    margin: "auto",
                    textAlign: "left",
                    marginTop: "-30px",
                    fontSize: "18px",
                  }}
                >
                  {
                    this.state.hints.map((hint) => (
                      <span
                        className='searchHint'
                        onClick={() => {
                        this.setState({ searchInputValue: '' }, () => {
                          this.props.hide();
                          this.setState({ hints: [] });
                          this.props.history.push('product/' + hint.uri);
                        })
                      }} style={{ color: colors.graphite, cursor: 'pointer', position: 'relative', display: 'block', width: 'max-content' }}>{hint.name}</span>
                    ))
                  }
                </div>
                <Search
                  style={{
                    width: "25px",
                    marginTop: "23px",
                    position: "absolute",
                    top: 0,
                    left: "15vw",
                  }}
                />
              </Form.Group>
            </form>
            <X
              className='navIconIPMB'
              onClick={this.props.hide}
              color={colors.graphite}
              size={45}
              style={{
                position: "absolute",
                top: "14px",
                right: "10%",
                cursor: "pointer",
              }}
            />
            <Link className="cartNumber" to="/cart">
              <Cart
                className='navIconIPMB'
                style={{
                  width: "25px",
                  marginTop: "24px",
                  position: "absolute",
                  top: 0,
                  right: "7%",
                }}
              />
              {/* {this.props.cart.length > 0 ? (
                <p style={cartNumber}>{this.props.cart.length}</p>
              ) : (
                <></>
              )} */}
            </Link>
            <Link to="/account">
              <User
                className='navIconIPMB'
                style={{
                  width: "25px",
                  marginTop: "24px",
                  position: "absolute",
                  top: 0,
                  right: "2.7%",
                }}
              />
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

// export default withRouter(SearchScreen);
const mapStateToProps = (state) => {
  return {
    searchValue: state.searchValue, // (1)
    cart: state.cart,
  };
};
const mapDispatchToProps = { searchValueFetched, cartFetched }; // (2)
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SearchScreen)
);
