import React from "react";
import { Form } from "react-bootstrap";
import colors from "../../colors/colors";
import Request from "../../api/Request";
import { connect } from "react-redux";
import { isMobileFetched } from "../../actions";

class CountrySelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      countries: [],
    };
  }
  componentDidMount() {
    Request.getCountries(response => {
      this.setState({ countries: response });
    });
  }
  render() {
    return (
      <>
        <Form.Group style={this.props.isMobile ?{ marginBottom: "20px" }:{ marginBottom: "30px" }}>
          <Form.Label
            style={{
              fontSize: "16px",
              fontFamily: "Montserrat",
              fontWeight: 500,
            }}
          >
            {this.props.label}
          </Form.Label>
          <Form.Control
            name={this.props.name}
            style={{
              backgroundColor: "#f5f5f5",
              borderColor: this.props.isError ? colors.darkPink : '#ced4da',
              border: this.props.isError ? 'solid 1px #f19cb0' : 'none',
              borderRight: '10px solid #f5f5f5',
              cursor: 'pointer',
            }}
            value={this.props.value}
            onChange={this.props.onChange}
            id="CountrySelect"
            as="select"
          >
            <option value="">Please Select</option>
            {this.state.countries.map((country) => {
              return <option key={country.alpha2Code} value={country.name}>{country.name}</option>
            })}
          </Form.Control>{" "}
        </Form.Group>
        {this.props.isError ? <p style={{
          fontSize: '10px',
          color: colors.darkPink,
          width: '100%',
          marginBottom: '4px',
          marginTop: '-16px',
          marginLeft: '10px',
        }}>{this.props.errorMessage}</p> : <></>}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isMobile: state.isMobile, // (1)
  };
};
const mapDispatchToProps = { isMobileFetched}; // (2)
export default connect(mapStateToProps, mapDispatchToProps)(CountrySelect);