const colors = {
    white: "#fff",
    black: "#000",
    blue: '#357490',
    lightBlue: '#75A5BA',
    pink: '#f19cb0',
    graphite: '#2e2e2e',
    footerBlue: '#d6e3e9',
    gray: '#626262',
    darkPink: '#E75A7C',
  };
  
  export default colors;
  