import React from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import colors from "../../colors/colors";
import { ReactComponent as Facebook } from "../../svg/facebook.svg";
import { ReactComponent as Instagram } from "../../svg/instagram.svg";
import { ReactComponent as Linkedin } from "../../svg/linkedin.svg";

export default class MainFooter extends React.Component {
  render() {
    const link = {
      fontSize: "16px",
      fontWeight: "600",
      fontFamily: "Montserrat",
      textDecoration: "none",
      color: colors.graphite,
    };

    const linkWrapper = {
      textDecoration: 'none',
      display: 'block',
    }

    return (
      <div
        style={{
          backgroundColor: colors.footerBlue,
          marginLeft: "-15px",
          marginRight: "-15px",
          paddingBottom: '50px',
        }}
      >
        <Row style={{ backgroundColor: colors.footerBlue, maxWidth: '100%', marginTop: '100px' }}>
          <Col></Col>
          <Col>
            <Row style={{padding: '50px', width: '60%', margin: 'auto'}}>
              <Col sm={4}>
                <Link to="#">
                  <Linkedin className='footerIcon' />
                </Link>
              </Col>
              <Col sm={4}>
                <a target="_blank" href="https://www.facebook.com/Fr%C3%A4ya-Medsupply-100400801861029/" >
                  <Facebook className='footerIcon' />
                </a>
              </Col>
              <Col sm={4}>
                <a target="_blank" href="https://www.instagram.com/frayamedsupply/" >
                  <Instagram  className='footerIcon'/>
                </a>
              </Col>
            </Row>
          </Col>

          <Col></Col>
        </Row>
        <Row
          className="footer-links"
          style={{
            backgroundColor: colors.footerBlue,
            width: "65%",
            margin: "auto",
          }}
        >
          <Col style={{marginLeft: '8%'}}>
            <h4
              style={{
                fontSize: "16px",
                fontWeight: "900",
                fontFamily: "Montserrat",
                marginBottom: '20px'
              }}
            >
              About
            </h4>
            <Link to='/about' style={linkWrapper}><span style={link} className='footer-link'>Our story</span></Link><br/>
            <Link to='/company-info' style={linkWrapper}><span style={link} className='footer-link'>Company information</span></Link>
          </Col>
          <Col>
            <h4
              style={{
                fontSize: "16px",
                fontWeight: "900",
                fontFamily: "Montserrat",
                marginBottom: '20px',
              }}
            >
              Customer service
            </h4>
            <Link to='/delivery-info' style={linkWrapper}><span style={link} className='footer-link'>Delivery</span></Link><br/>
            <Link to="/payment-info" style={linkWrapper}><span style={link} className='footer-link'>Payment</span></Link><br/>
            <Link to="/privacy-policy" style={linkWrapper}><span style={link} className='footer-link'>Privacy policy</span></Link><br/>
            <Link to="/terms-and-conditions" style={linkWrapper}><span style={link} className='footer-link'>Terms & conditions</span></Link><br/>
          </Col>
          <Col style={{marginLeft:'8%', marginRight: '-8%',}}>
            <h4
              style={{
                fontSize: "16px",
                fontWeight: "900",
                fontFamily: "Montserrat",
                marginBottom: '20px',
              }}
            >
              Contact
            </h4>
            <Link to='/contact' style={linkWrapper}><span style={link} className='footer-link'>Get in touch with us</span></Link> <br/>
          </Col>
        </Row>
      </div>
    );
  }
}