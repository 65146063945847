import React from "react";
import { Form, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import colors from "../colors/colors";
import { connect } from "react-redux";
import { isMobileFetched, cartFetched, userFetched } from "../actions";
import { ReactComponent as FacebookF } from "../svg/facebook-f.svg";
import { ReactComponent as Google } from "../svg/google.svg";
import Request from "../api/Request";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import GoogleLogin from 'react-google-login';
import { withRouter } from "react-router-dom";
import BlueButton from "../components/general/BlueButton";
import UniversalInput from "../components/form/UniversalInput";
import PasswordInput from "../components/form/PasswordInput";

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      login_email: '',
      login_password: '',
      register_email: '',
      register_password: '',
      register_message: '',
      register_made: 0,
      register_email_error: 0,
      register_password_error: 0,
      login_email_error: 0,
      login_password_error: 0,
      login_message: '',
      forgotPassword: false,
      resetPasswordMessage: '',
      showLoginPassword: true,
      showRegisterPassword: true,
    };
  }

  componentDidMount() {
    if (this.props.user && this.props.user.email) {
      this.props.history.push('/account');
    }
  }

  signup() {
    this.setState({ register_email_error: !!!this.state.register_email, register_password_error: !!!this.state.register_password, });
    if (!this.state.register_email || !this.state.register_password) {
      return;
    }
    Request.register(this.state.register_email, this.state.register_password, result => {
      if (result.success) {
        this.props.userFetched({ ...result.me });
        window.localStorage.setItem('user', JSON.stringify({ ...result.me }));
        this.props.history.push("/account");
        this.setState({ register_message: 'Account created. Now you can login!', registerError: false });
      } else {
        this.setState({ register_message: 'Account not created. Please try again later.', registerError: true });
      }
      this.setState({ register_made: 1 });
      // console.log(result);
    });
  }

  responseFacebook(response) {
    if (!response.accessToken) {
      return;
    }
    Request.registerFacebook(response, result => {
      if (result.success) {
        this.props.userFetched({ ...result.me });
        window.localStorage.setItem('user', JSON.stringify({ ...result.me }));
        this.props.history.push("/account");
      }
    });
  }

  responseGoogle(response) {
    if (!response.googleId) {
      return;
    }
    Request.registerGoogle(response, result => {
      if (result.success) {
        this.props.userFetched({ ...result.me });
        window.localStorage.setItem('user', JSON.stringify({ ...result.me }));
        this.props.history.push("/account");
      }
    });
  }

  login() {
    this.setState({ login_email_error: !!!this.state.login_email, login_password_error: !!!this.state.login_password, });
    if (!this.state.login_email || !this.state.login_password) {
      return;
    }
    Request.login(this.state.login_email, this.state.login_password, response => {
      if (response.success) {
        this.props.userFetched({ ...response.me });
        window.localStorage.setItem('user', JSON.stringify({ ...response.me }));
        this.props.history.push("/account");
      } else {
        this.setState({ login_message: 'Please check email and password.', error: true });
      }
    });
  }

  setForgotPassword() {
    this.setState({ forgotPassword: !this.state.forgotPassword, resetPasswordMessage: '' });
  }

  resetPassword() {
    this.setState({ error: false, resetPasswordMessage: '' });
    this.setState({ login_email_error: !!!this.state.login_email });
    if (!this.state.login_email) {
      return;
    }
    this.setState({ resetPasswordMessage: 'Please wait.' });
    Request.resetPassword({ email: this.state.login_email }, response => {
      this.setState({ resetPasswordMessage: 'Check your inbox.' });
    });
  }

  eyeLoginClick() {
    this.setState({ showLoginPassword: !this.state.showLoginPassword });
  }

  eyeRegisterClick() {
    this.setState({ showRegisterPassword: !this.state.showRegisterPassword });
  }

  render() {
    return (
      <>
        <Row style={this.props.isMobile ?{paddingTop:'80px', letterSpacing:'0.7px'}:{ paddingTop: "150px" }}>
          <Col md={2} xs={12}></Col>
          <Col md={4} xs={12}>
            <h3
              style={this.props.isMobile ?{
                fontSize: "20px",
                color: colors.blue,
                fontFamily: "Montserrat",
                fontWeight: 800,
                marginTop: "19px",
                marginBottom: "40px",
                textAlign:'center',
                }:
                {
                fontSize: "24px",
                color: colors.blue,
                fontFamily: "Montserrat",
                fontWeight: 700,
                marginTop: "19px",
                marginBottom: "21px",
              }}
            >
              Welcome back!
            </h3>
            <div
              style={this.props.isMobile ?{
                border: "solid 1px rgba(54, 118, 145, 0.2)",
                borderRadius: "5px",
                margin: 0,
                marginBottom: "20px",
                padding: "20px",
                }:
                {
                border: "solid 1px rgba(54, 118, 145, 0.2)",
                borderRadius: "5px",
                margin: 0,
                marginBottom: "20px",
                padding: "20px",
                minHeight: '505px',
              }}
            >
              <Form>
                <UniversalInput
                  isEmail={true}
                  type="email"
                  value={this.state.login_email}
                  onChange={(event) => this.setState({ login_email: event.target.value })}
                  label='Email address'
                  errorMessage={this.state.forgotPassword ? this.state.resetPasswordMessage : this.state.login_message}
                  isError={this.state.error}
                />
                {this.state.forgotPassword ?<>
                  <BlueButton 
                    onClick={this.resetPassword.bind(this)}
                    content = 'Reset password'
                    margin= "25px calc(50% - 95px) 15px"
                  />
                  {this.state.resetPasswordMessage}
                 </>
                  : <>
                    <PasswordInput 
                      label='Password'
                      value={this.state.login_password}
                      onChange={(event) => this.setState({ login_password: event.target.value })}
                      type={this.state.showLoginPassword}
                      eyeClick={this.eyeLoginClick.bind(this)}
                      errorMessage={this.state.forgotPassword ? this.state.resetPasswordMessage : this.state.login_message}
                      isError={this.state.error}
                    />
                    <Form.Group controlId="formBasicCheckbox">
                      <Form.Check
                        className='rememberMeCheck'
                        style={this.props.isMobile ?{
                          fontSize: "15px",
                          fontFamily: "Montserrat",
                          fontWeight: 500,
                          marginBottom:'-10px',
                          }:
                          {
                          fontSize: "15px",
                          fontFamily: "Montserrat",
                          fontWeight: 500,
                        }}
                        type="checkbox"
                        label="Remember me"
                      />
                    </Form.Group>
                    <BlueButton 
                    onClick={this.login.bind(this)}
                    content = 'Log in'
                    margin= "0px calc(50% - 95px) 15px"
                  />
                    </>}
              </Form>
              <div style={{ width: "100%", textAlign: "center" }}>
                <Link
                  onClick={this.setForgotPassword.bind(this)}
                  style={this.props.isMobile ?{
                    fontSize: "14px",
                    fontFamily: "Montserrat",
                    fontWeight: 500,
                    color: colors.blue,
                    textDecoration: 'none',
                    }:
                    {
                    fontSize: "14px",
                    fontFamily: "Montserrat",
                    fontWeight: 500,
                    color: colors.blue,
                    textDecoration: 'none',
                  }}
                >
                  {this.state.forgotPassword ? 'Log in' : 'Forgot Password?'}
                </Link>
                {this.state.forgotPassword ? <></> : <>
                  <h4
                    style={this.props.isMobile ?{
                      fontSize: "16px",
                      fontFamily: "Montserrat",
                      fontWeight: 700,
                      marginTop: "25px",
                    }:
                    {
                      fontSize: "16px",
                      fontFamily: "Montserrat",
                      fontWeight: 700,
                      marginTop: "15px",
                    }}
                  >
                    Or sign in with
                </h4>
                  <Row style={this.props.isMobile ?{ width: "45%", margin: "15px auto", marginTop:'20px' }:{ width: "33%", margin: "15px auto" }}>
                    <Col xs={6}>
                      <FacebookLogin
                        appId="1038837456514519"
                        callback={this.responseFacebook.bind(this)}
                        fields="name,email"
                        render={renderProps => (
                          <FacebookF 
                          style={{cursor: 'pointer'}}
                          onClick={renderProps.onClick} />
                        )}
                      />
                    </Col>
                    <Col xs={6}>
                      <GoogleLogin
                        clientId="794548790556-rd5iscb6evjueslq22jf5q823tc9krg5.apps.googleusercontent.com"
                        render={renderProps => (
                          <Google 
                          style={{cursor: 'pointer'}}
                          onClick={renderProps.onClick} />
                        )}
                        buttonText="Login"
                        onSuccess={this.responseGoogle.bind(this)}
                        onFailure={this.responseGoogle.bind(this)}
                        cookiePolicy={'single_host_origin'}
                      />
                    </Col>
                  </Row>
                </>}
              </div>
            </div>
          </Col>
          <Col md={4} xs={12}>
            <h3
              style={this.props.isMobile ?{
                fontSize: "20px",
                color: colors.blue,
                fontFamily: "Montserrat",
                fontWeight: 800,
                marginTop: "20px",
                marginBottom: "32px",
                textAlign:'center',
                }:
                {
                fontSize: "24px",
                color: colors.blue,
                fontFamily: "Montserrat",
                fontWeight: 700,
                marginTop: "19px",
                marginBottom: "21px",
              }}
            >
              Don't have an account?
            </h3>
            <div
              style={this.props.isMobile ?{
                border: "solid 1px rgba(54, 118, 145, 0.2)",
                borderRadius: "5px",
                margin: 0,
                marginBottom: "70px",
                padding: "20px",
                }:
                {
                border: "solid 1px rgba(54, 118, 145, 0.2)",
                borderRadius: "5px",
                margin: 0,
                marginBottom: "20px",
                padding: "20px",
                minHeight: '505px',
              }}
            >
              <Form>
                <UniversalInput 
                  isEmail={true}
                  type="email"
                  value={this.state.register_email}
                  onChange={(event) => this.setState({ register_email: event.target.value })}
                  label='Email addresss'
                  errorMessage={this.state.register_message}
                  isError={this.state.registerError}
                />
                <PasswordInput 
                  label='Password'
                  value={this.state.register_password}
                  onChange={(event) => this.setState({ register_password: event.target.value })}
                  type={this.state.showRegisterPassword}
                  eyeClick={this.eyeRegisterClick.bind(this)}
                />
                {this.state.register_made ? <></> : 
                  <BlueButton 
                  onClick={this.signup.bind(this)}
                  content = 'Sign up'
                  margin= "37px calc(50% - 95px) 15px"
                />
                }
              </Form>
              <div style={{ width: "100%", textAlign: "center" }}>
                <h4
                  style={this.props.isMobile ?{
                    fontSize: "16px",
                    fontFamily: "Montserrat",
                    fontWeight: 700,
                    marginTop: "15px",
                  }:
                  {
                    fontSize: "16px",
                    fontFamily: "Montserrat",
                    fontWeight: 700,
                    marginTop: "15px",
                  }}
                >
                  Or sign in with
                </h4>
                <Row style={this.props.isMobile ?{ width: "45%", margin: "15px auto", marginTop:'20px' }:{ width: "33%", margin: "15px auto" }}>
                  <Col xs={6}>
                    <FacebookLogin
                      appId="1038837456514519"
                      callback={this.responseFacebook.bind(this)}
                      fields="name,email"
                      render={renderProps => (
                        <FacebookF                      
                        style={{cursor: 'pointer'}}
                        onClick={renderProps.onClick} />
                      )}
                    />

                  </Col>
                  <Col xs={6}>
                    <GoogleLogin
                      clientId="794548790556-rd5iscb6evjueslq22jf5q823tc9krg5.apps.googleusercontent.com"
                      render={renderProps => (
                        <Google 
                        style={{cursor: 'pointer'}}
                        onClick={renderProps.onClick} />
                      )}
                      buttonText="Login"
                      onSuccess={this.responseGoogle.bind(this)}
                      onFailure={this.responseGoogle.bind(this)}
                      cookiePolicy={'single_host_origin'}
                    />
                  </Col>
                </Row>
              </div>
              <p
                style={this.props.isMobile ?{
                  fontSize: "14px",
                  color: colors.graphite,
                  fontFamily: "Montserrat",
                  fontWeight: 500,
                  marginTop: '26px',
                  textAlign:'center',
                  }:
                  {
                  fontSize: "14px",
                  color: colors.graphite,
                  fontFamily: "Montserrat",
                  fontWeight: 500,
                  marginTop: '26px'
                }}
              >
                By signing up, I accept Fräya’s <Link style={{textDecoration: 'none', color: colors.blue, fontWeight: 'bold'}} to='/terms-and-conditions'> terms {'&'} conditions
                  </Link> and <Link style={{textDecoration: 'none', color: colors.blue, fontWeight: 'bold'}} to='/privacy-policy'> privacy policy</Link>.
                </p>
            </div>
          </Col>
          <Col md={2}></Col>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isMobile: state.isMobile, // (1)
    cart: state.cart,
    user: state.user,
  };
};
const mapDispatchToProps = { isMobileFetched, cartFetched, userFetched }; // (2)
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginPage));
