import React from "react";
import colors from "../../colors/colors";
import { ReactComponent as CheckCircle } from "../../svg/checkCircle.svg";
import { Row, Col, Image, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import ListingPage from "../../pages/ListingPage";
import Price from "../general/Price";
import Request from "../../api/Request";

export default class RequestPopup extends ListingPage {

  constructor(props) {
    super(props);
    this.state = {
      closeTimeout: null
    };
  }
  close() {
    window.hideTopInfo();
  }
  render() {
    const paragraph = {
      fontSize: "12px",
      color: colors.graphite,
      fontFamily: "Montserrat",
      fontWeight: 500,
      marginBottom: 0,
    };

    const paragraphBold = {
      fontSize: "12px",
      color: colors.graphite,
      fontFamily: "Montserrat",
      fontWeight: 600,
      marginBottom: 0,
      width: '100%',
    };

    return (
      <div
        onMouseEnter={this.props.mouseEnter}
        onMouseLeave={this.props.mouseLeave}
        className={this.props.showRequsetMessage}
        style={
          this.props.isMobile ? {
            backgroundColor: colors.white,
            height: "max-content",
            width: "100vw",
            borderRadius: "5px",
            position: "fixed",
            top: "0px",
            right: "0",
            padding: "10px",
            zIndex: 99999,
            transition: "1s",
            border: "solid",
            borderColor: 'rgba(54, 118, 145, 0.2)',
            borderWidth: "1px",
            height: '100vh',
          }
            :
            {
              backgroundColor: colors.white,
              height: "max-content",
              width: "250px",
              borderRadius: "5px",
              position: "fixed",
              top: "90px",
              right: "15px",
              padding: "10px",
              zIndex: 100,
              transition: "1s",
              border: "solid",
              borderColor: 'rgba(54, 118, 145, 0.2)',
              borderWidth: "1px",
            }}
      >
        <div style={{ display: "flex" }}>
          <h3
            style={{
              fontSize: "16px",
              color: colors.black,
              fontFamily: "Montserrat",
              fontWeight: 700,
              marginTop: "5px",
              marginBottom: "5px",
              width: '100%',
            }}
          >
            {this.props.message}
            <span onClick={this.close} style={{ cursor: 'pointer' }} className="float-right">X</span>
          </h3>
        </div>
       
      </div>
    );
  }
}
