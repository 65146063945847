import React from "react";
import { Row, Col, Form, Image, Carousel, Button } from "react-bootstrap";
import colors from "../colors/colors";
import { connect } from "react-redux";
import {
  isMobileFetched,
  cartFetched,
  productToShowFetched,
  localeFetched,
} from "../actions";
import { Plus, Dash } from "react-bootstrap-icons";
import Price from "../components/general/Price";
import Request from "../api/Request";
import DeliveryCost from "../components/general/DeliveryCost";
import QuickBuyForm from "../components/payment/QuickBuyForm";
import BlueButton from "../components/general/BlueButton";
import RecommendetProductOnList from "../components/productList/RecommendetProductOnList";
import MobileProductPage from "../components/mobile/MobileProductPage";
import DocumentMeta from "react-document-meta";

class ProducPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      product: {},
      quantity: 1,
      hideAddToCart: false,
      products: [],
    };
  }

  componentDidMount() {
    if (this.props.productToShow == 0) {
      if (
        !this.props.match ||
        !this.props.match.params ||
        !this.props.match.params.productUri
      ) {
        this.props.history.push("/products");
      } else {
        Request.getProductByUri(
          this.props.match.params.productUri,
          (response) => {
            if (response.success) {
              this.setState({ product: response.product });
              this.props.productToShowFetched(response.product);
            } else {
              this.props.history.push("/products");
            }
          }
        );
      }
    } else {
      this.setState({ product: this.props.productToShow });
    }

    Request.getProducts((response) => {
      if (response.success) {
        const products = response.products.filter(product => this.state.product.similarProducts.indexOf(product.id) >= 0)
        this.setState({ products: products });
      }
    });
  }

  removeDuplicates(myArr, prop) {
    return myArr.filter((obj, pos, arr) => {
        return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos
    })
}

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.match &&
      prevProps.match.params &&
      prevProps.match.params.productUri &&
      prevProps.match.params.productUri != this.props.match.params.productUri
    ) {
      Request.getProductByUri(
        this.props.match.params.productUri,
        (response) => {
          if (response.success) {
            this.setState({ product: response.product, quantity: 1 });
            this.props.productToShowFetched(response.product);
          } else {
            this.props.history.push("/products");
          }
        }
      );
    }
  }

  handleQuantityChange(event, id) {
    const { value } = event.target;
    this.setState((state) => {
      if (parseInt(value) > this.state.product.stock) {
        if (this.state.product.stock < 0) {
          state.quantity = 0;
        } else {
          state.quantity = parseInt(this.state.product.stock);
        }
      } else if (parseInt(value) < 0) {
        state.quantity = 0;
      } else {
        state.quantity = parseInt(value);
      }
      return state;
    });
  }

  quantityPlus(id) {
    this.setState((state) => {
      if (parseInt(state.quantity) + 1 > this.state.product.stock) {
        if (this.state.product.stock < 0) {
          state.quantity = 0;
        } else {
          state.quantity = parseInt(this.state.product.stock);
        }
      } else {
        state.quantity = parseInt(state.quantity) + 1;
      }

      return state;
    });
  }

  quantityMinus(id) {
    this.setState((state) => {
      if (this.state.quantity - 1 < 1) {
        state.quantity = 1;
      } else {
        state.quantity -= 1;
      }
      return state;
    });
  }

  addToCart() {
    const product = this.state.product;
    product.quantity = this.state.quantity;
    const cart = this.props.cart;
    let found = false;

    cart.forEach((element, id) => {
      if (element.id == product.id) {
        found = true;
        cart[id].quantity += product.quantity;
      }
    });

    if (!found) {
      cart.push({ ...product });
    }
    this.props.cartFetched(cart);
    window.localStorage.setItem("cart", JSON.stringify(cart));
    window.showTopInfo();
  }

  hideAddToCartMobile() {
    this.setState({ hideAddToCart: true });
  }

  render() {
    const meta = {
      title: this.state.product.name,
      description: this.state.product.description,
    };

    const paragraph = {
      fontSize: "16px",
      color: colors.graphite,
      fontFamily: "Montserrat",
      fontWeight: 400,
    };

    const paragraphBold = {
      fontSize: "16px",
      color: colors.graphite,
      fontFamily: "Montserrat",
      fontWeight: 600,
    };
    return this.props.isMobile ? (
      <MobileProductPage
        product={this.state.product}
        quantity={this.state.quantity}
        hideAddToCart={this.state.hideAddToCart}
        products={this.state.products}
        quantityMinus={this.quantityMinus.bind(this)}
        handleQuantityChange={this.handleQuantityChange.bind(this)}
        quantityPlus={this.quantityPlus.bind(this)}
        locale={this.props.locale}
        addToCart={() => this.addToCart()}
        hideAddToCartMobile={this.hideAddToCartMobile.bind(this)}
      />
    ) : (
      <DocumentMeta {...meta}>
        <Row style={{ paddingTop: "150px" }}>
          <Col style={{ textAlign: "center" }}>
            {this.state.product.back_image ? (
              <Carousel
                interval={null}
                autoPlay={false}
                className="productGallery"
              >
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    alt={this.state.product.alt_tag}
                    src={Request.realProductImage(
                      this.state.product.small_image
                    )}
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    alt={this.state.product.alt_tag}
                    src={Request.realProductImage(
                      this.state.product.back_image
                    )}
                  />
                </Carousel.Item>
              </Carousel>
            ) : (
              <Image
                style={{ maxWidth: "100%" }}
                alt={this.state.product.alt_tag}
                src={Request.realProductImage(this.state.product.small_image)}
              />
            )}
          </Col>
          <Col>
            <h3
              style={{
                fontSize: "32px",
                color: colors.blue,
                fontFamily: "Raleway",
                fontWeight: 900,
                marginTop: "10px",
                marginBottom: "20px",
              }}
            >
              {this.state.product.name}
            </h3>
            <p
              style={paragraph}
              dangerouslySetInnerHTML={{ __html: this.state.product.capacity }}
            ></p>
            <h3
              style={{
                fontSize: "24px",
                color: colors.black,
                fontFamily: "Montserrat",
                fontWeight: 700,
                marginTop: "10px",
                marginBottom: "20px",
              }}
            >
              {this.state.product.canBeBought ? (
                <Price price={this.state.product.price} />
              ) : (
                <span>Not available</span>
              )}
            </h3>
            <p style={paragraphBold}>Compositions:</p>
            <ul style={paragraph}>
              {this.state.product.compositions ? (
                this.state.product.compositions.map((point, id) => {
                  return (
                    <li
                      key={id}
                      dangerouslySetInnerHTML={{ __html: point }}
                    ></li>
                  );
                })
              ) : (
                <></>
              )}
            </ul>
            <p style={paragraphBold}>Contains:</p>
            <ul style={paragraph}>
              {this.state.product.contains ? (
                this.state.product.contains.map((point, id) => {
                  return (
                    <li
                      key={id}
                      dangerouslySetInnerHTML={{ __html: point }}
                    ></li>
                  );
                })
              ) : (
                <></>
              )}
            </ul>
            <div
              style={{
                fontSize: "14px",
                color: colors.gray,
                fontFamily: "Montserrat",
                fontWeight: 400,
                marginTop: "25px",
              }}
            >
              {this.state.product.canBeBought ? (
                <Row>
                  <Col md={3} style={{ marginRight: "-38px" }}>
                    <div
                      style={{
                        boxShadow: "0px 3px 6px #35749066",
                        border: "1px solid #35749033",
                        borderRadius: "5px",
                        height: "38px",
                        width: "100px",
                        padding: "0 10px",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <Col
                          md={2}
                          style={{
                            padding: 0,
                            textAlign: "right",
                            height: "max-content",
                          }}
                        >
                          <form>
                            <Button
                              className="ipmb-qty-button"
                              style={{
                                width: "max-content",
                                height: "max-content",
                                background: "none",
                                border: "none",
                                padding: 0,
                                margin: 0,
                                cursor: "pointer",
                              }}
                            >
                              <Dash
                                onClick={this.quantityMinus.bind(this)}
                                style={{ marginTop: "12px", cursor: "pointer" }}
                                color={colors.lightBlue}
                              />
                            </Button>
                          </form>
                        </Col>
                        <Col style={{ padding: "0px", height: "max-content" }}>
                          <Form.Group>
                            <Form.Control
                              value={this.state.quantity}
                              onChange={this.handleQuantityChange.bind(this)}
                              type="number"
                              className="no-spinner addToCartQuantity"
                              min={1}
                            />
                          </Form.Group>
                        </Col>
                        <Col
                          md={2}
                          style={{ padding: 0, height: "max-content" }}
                        >
                          <form>
                            <Button
                              className="ipmb-qty-button"
                              style={{
                                width: "max-content",
                                height: "max-content",
                                background: "none",
                                border: "none",
                                padding: 0,
                                margin: 0,
                                cursor: "pointer",
                              }}
                            >
                              <Plus
                                onClick={this.quantityPlus.bind(this)}
                                style={{
                                  marginTop: "11px",
                                  cursor: "pointer",
                                  marginLeft: "-3px",
                                }}
                                color={colors.lightBlue}
                              />
                            </Button>
                          </form>
                        </Col>
                      </div>
                    </div>
                  </Col>
                  <Col md={4} style={{ padding: 0 }}>
                    <BlueButton
                      content="Add to cart"
                      onClick={() => {
                        this.addToCart();
                      }}
                      margin="0px calc(50% - 95px) 0px"
                    />
                  </Col>
                  <QuickBuyForm
                    product={this.state.product}
                    quantity={this.state.quantity}
                    onClick={() => this.setState({ hideAddToCart: true })}
                  />
                </Row>
              ) : (
                <></>
              )}
              <p
                style={{
                  color: "#2E2E2EBF",
                  fontSize: "14px",
                  fontFamily: "Montserrat",
                  marginTop: "20px",
                  marginBottom: "0px",
                }}
              >
                Our products are sold for professional use only
              </p>
              <p
                style={{
                  color: "#2E2E2EBF",
                  fontSize: "14px",
                  fontFamily: "Montserrat",
                  marginTop: "5px",
                  marginBottom: "30px",
                }}
              >
                Shipping to {this.props.locale.country} from:{" "}
                <DeliveryCost onlyNumber={true} />
              </p>
            </div>
            <div
              style={paragraph}
              dangerouslySetInnerHTML={{
                __html: this.state.product.description,
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <p
              style={
                this.props.isMobile
                  ? {
                      fontSize: "18px",
                      color: colors.graphite,
                      fontWeight: 400,
                      marginBottom: "-20px",
                    }
                  : {
                      fontSize: "24px",
                      color: colors.graphite,
                      fontWeight: 400,
                      marginTop: "40px",
                    }
              }
            >
              You might be also interested in:
            </p>
          </Col>
        </Row>
        <Row>
          {this.removeDuplicates(this.state.products.slice(0, 5), 'id').map((product) => {
            return <RecommendetProductOnList product={product} />;
          })}
        </Row>
      </DocumentMeta>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isMobile: state.isMobile, // (1)
    cart: state.cart,
    white: state.whiteHeader,
    productToShow: state.productToShow,
    locale: state.locale,
  };
};
const mapDispatchToProps = {
  isMobileFetched,
  cartFetched,
  productToShowFetched,
  localeFetched,
}; // (2)
export default connect(mapStateToProps, mapDispatchToProps)(ProducPage);
