import React from "react";
import { Row, Col } from "react-bootstrap";
import colors from "../colors/colors";
import { connect } from "react-redux";
import { isMobileFetched } from "../actions";

class TermsAndConditions extends React.Component {
  render() {
    return (
      <div style={{letterSpacing:'0.5px',}}>
        <Row>
          <Col style={this.props.isMobile ?{ paddingTop: "100px" }:{ paddingTop: "230px" }}>
            <h1
              style={this.props.isMobile ?{
                fontSize: "26px",
                color: colors.blue,
                fontFamily: "Raleway",
                fontWeight: 900,
                paddingBottom: "20px",
                textAlign:'center',
              }:
                {
                fontSize: "90px",
                color: colors.blue,
                fontFamily: "Raleway",
                fontWeight: 900,
                paddingBottom: "50px",
              }}
            >
              Terms & conditions
            </h1>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              1. These terms and conditions of sale (the “Terms and Conditions”)
              apply to all sales and deliveries of products from FRÄYA Group Sp
              z o.o., Baltiq Plaza, Świętojańska 43/18, 81-391 Gdynia, Poland
              (“FRÄYA Group”) to the customer (the “Customer”).
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              2. Unless otherwise agreed in writing, all products sold by FRÄYA
              Group to the Customer are covered by these Terms and Conditions,
              including but not limited to pharmaceuticals (both
              over-the-counter and prescription-only medicines), medical
              devices, cosmeceuticals and disposables (the “Product(s)”).
            </p>
            <h3
              style={{
                fontSize: "16px",
                color: colors.blue,
                fontFamily: "Montserrat",
                fontWeight: 700,
                marginTop: "10px",
                marginBottom: "20px",
              }}
            >
              I. Orders
            </h3>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              1. A binding agreement between FRÄYA Group and the Customer is
              concluded when FRÄYA Group has confirmed the Customer’s purchase
              order for Products (the “Purchase Order”) by issuing a pro forma
              invoice.
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              2. Any statement contained on any Purchase Order or similar
              document, which is not specifically confirmed in writing by FRÄYA
              Group by issuing a pro forma invoice, will not be considered an
              agreement between the parties.
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              3. All pro forma invoices are subject to availability of the
              Products, and FRÄYA Group reserves the right to cancel any pro
              forma invoice or agreement in accordance with clause 14 below.
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              4. A Purchase Order is binding on the part of the Customer.
            </p>
            <h3
              style={{
                fontSize: "16px",
                color: colors.blue,
                fontFamily: "Montserrat",
                fontWeight: 700,
                marginTop: "10px",
                marginBottom: "20px",
              }}
            >
              II. Compliance with applicable laws and regulations
            </h3>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              1. FRÄYA Group acts as an international wholesaler. Except as
              specified in these Terms and Conditions, FRÄYA Group does not
              assume any liability for compliance with legislation applicable to
              the Customer or the Products in jurisdictions in which the
              Customer is established or conducts business.
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              2. By accepting these Terms and Conditions the Customer
              proactively takes the responsibility of being in compliance with
              and strictly follow current international and local legislation in
              force for all handling of the Products.
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              3. All activities are based on validated and certified processes.
              These processes are documented in detail within the frame of
              Standard Operating Procedures (SOP) and being carried out in
              compliance with the local regulations.
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              4. The Customer warrants that it will obtain and maintain all
              permits, licenses and authorisations, and make all required
              notifications to relevant authorities, necessary for the import,
              marketing and distribution of the Products, including for
              reviewing and approving all product packages, labels and product
              information (e.g. the summary of product characteristics and the
              package leaflet) to ensure compliance with applicable laws and
              regulations.
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              5. The Customer warrants that it will be responsible for all post
              marketing obligations (if any), including market surveillance
              activities, such as reporting of substantial changes to the
              product specifications and quality systems, reporting of adverse
              events, handling of complaints, customer notifications and
              recalls.
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              6. The Customer warrants that any handling obligations or selling
              restrictions attached to the Products (following from e.g. these
              Terms and Conditions, the product package or applicable laws and
              regulations) as to further resale or import of the Products will
              be complied with, and that the Customer will impose such
              restrictions on any subsequent customers of the Products.
            </p>
            <h3
              style={{
                fontSize: "16px",
                color: colors.blue,
                fontFamily: "Montserrat",
                fontWeight: 700,
                marginTop: "10px",
                marginBottom: "20px",
              }}
            >
              III. Pricing & Payment
            </h3>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              1. Prices stated in pricelists and previously paid prices are not
              binding with regard to subsequent Purchase Orders.
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              2. Unless otherwise stated, all prices are exclusive of VAT,
              custom duties and taxes.
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              3. The Customer is responsible for collection, transfer and
              payment of any taxes, expenses, charges, duties, fees and other
              payments (the “Charges”), imposed with regard to the purchase,
              sale, export, import and other actions with the Products, or, in
              general, any Charges arising out of, or incidental to, the
              carrying on of its own business, whether or not these Charges
              relate to the Products.
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              4. Unless otherwise agreed in writing, the Products are subject to
              payment in advance to FRÄYA Group. Shipment of the Products will
              not be initiated before payment has been received by FRÄYA Group.
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              5. The Products shall remain the property of FRÄYA Group until the
              Customer has settled all its obligations, including payment in
              full of the purchase price and any default interest.{" "}
            </p>
            <h3
              style={{
                fontSize: "16px",
                color: colors.blue,
                fontFamily: "Montserrat",
                fontWeight: 700,
                marginTop: "10px",
                marginBottom: "20px",
              }}
            >
              IV. Delivery
            </h3>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              1. FRÄYA Group must deliver the Products as agreed between the
              parties to the Customer and perform its obligations in accordance
              with the pro former invoice and these Terms and Conditions.
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              2. Applicable delivery terms (e.g. ex works) are set out in the
              pro forma invoice issued by FRÄYA Group. Unless otherwise stated
              in the pro forma invoice, the Products will be delivered ex works
              (Incoterms 2020) at FRÄYA Group’ appointed facility.
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              3. In case of delay, FRÄYA Group must promptly notify the Customer
              thereof. Delay is only a material breach of these Terms and
              Conditions if such delay persists for more than 14 days. In case
              of multiple shipments, the 14 days will be calculated per
              shipment.
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              4. FRÄYA Group reserves the right to withhold the delivery of the
              Products if any sum due to FRÄYA Group is overdue or if, in the
              opinion of FRÄYA Group, the credit standing of the Customer has
              been impaired for any other reason, until such time as payment is
              received.
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              5. The sole and exclusive remedy of the Customer in case of delay
              is a credit or repayment of the purchase sum, at the discretion of
              FRÄYA Group.
            </p>
            <h3
              style={{
                fontSize: "16px",
                color: colors.blue,
                fontFamily: "Montserrat",
                fontWeight: 700,
                marginTop: "10px",
                marginBottom: "20px",
              }}
            >
              V. Defects
            </h3>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              1. Except as expressly stated in these Terms and Conditions, FRÄYA
              Group makes no representation, statement of fact, promise or
              warranty of any kind or nature, express or implied, with respect
              to the Product or its merchantability or fitness for a particular
              purpose.
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              2. Products delivered by FRÄYA Group are deemed to be free of any
              defects and approved by the Customer, if defects are not reported
              in writing to FRÄYA Group as soon as they are discovered, and in
              no event later than 3 days after receipt of the Products by the
              Customer. The notification to FRÄYA Group must include a full and
              complete description of the complaint and any action taken in
              response to the complaint by the Customer.
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              3. Products, which FRÄYA Group consents or directs in writing to
              be returned, will be returned (Incoterms 2020) by the Customer to
              FRÄYA Group or such other destination directed by FRÄYA Group.
              FRÄYA Group shall decide means of transportation and forwarding
              agent in each case.{" "}
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              4. FRÄYA Group does not accept any returns of pharmaceuticals and
              is not obligated hereto.{" "}
            </p>
            <h3
              style={{
                fontSize: "16px",
                color: colors.blue,
                fontFamily: "Montserrat",
                fontWeight: 700,
                marginTop: "10px",
                marginBottom: "20px",
              }}
            >
              VI. Products damaged in transit
            </h3>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              1. In case FRÄYA Group bears the risk of freight of the Products
              to the Customer according to the pro forma invoice, e.g. if ex
              works is agreed, any claims for loss, shortage, breakage, leakage
              or other damage occurring in transit must be notified to FRÄYA
              Group at info@frayamedsupply.com immediately and no later than 3
              days after the Customer has obtained or should have obtained
              knowledge thereof. Claims received after this time limit are not
              accepted.
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              2. Notification must be accompanied by relevant pictures of the
              loss and the report made by the carrier, shortage, breakage or
              damage. Claims submitted by the Customer without appropriate
              documentation will be rejected.
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              3. The Customer will cooperate fully and loyally with FRÄYA Group
              in its efforts to establish a claim against the carrier.
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              4. The sole and exclusive remedy of the Customer in case of damage
              in transit is a replacement order or repayment of the purchase
              sum, at the discretion of FRÄYA Group. The Customer disclaims all
              other remedies (including but not limited to indirect and
              consequential damages, etc.) in case of damages in transit.
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              5. In any case, FRÄYA Group is not liable for damages in transit
              where such damages amount to less than EUR 1000. The amount is
              calculated per Purchase Order.
            </p>
            <h3
              style={{
                fontSize: "16px",
                color: colors.blue,
                fontFamily: "Montserrat",
                fontWeight: 700,
                marginTop: "10px",
                marginBottom: "20px",
              }}
            >
              VII. Liability
            </h3>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              1. FRÄYA Group is liable in accordance with the ordinary rules of
              Polish law with the limitations and exclusions set out in these
              Terms and Conditions.{" "}
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              2. FRÄYA Group is not liable to the Customer for damages for any
              indirect losses or claims, including but not limited to claims for
              damages not pertaining to the Products, loss of profit, loss of
              production, loss of goodwill, operating losses or any other
              indirect losses.
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              3. FRÄYA Group’ total aggregated liability for any and all claims
              including damages and/or refund of purchase price is limited to an
              amount equal to the purchase price paid for the Products to which
              the claims relate. The aforesaid monetary limitation applies
              irrespective of the basis of the liability and will to the widest
              extent possible include claims based on acts of negligence
              (whether ordinary or gross negligence), strict liability, product
              liability, etc.
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              4. Any claims, including claims for breach, remediation of defects
              and delays, will be barred and obsolete 1 months following
              delivery of the Products, irrespective of whether the Customer was
              or should have been aware of the existence of the claim.
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              5. FRÄYA Group is not liable bodily injury or damage to products
              caused by the Products, save to the extent such liability cannot
              be excluded under applicable mandatory law.
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              6. FRÄYA Group is in no event liable for damage to property caused
              by the Products after delivery has taken place and/or whilst in
              the possession of the Customer, including but not limited to
              damage to products manufactured by the Customer or to products of
              which the Customer’s products form a part.
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              7. FRÄYA Group’ liability will in no event exceed the purchase sum
              for the Products giving rise to the claim per year, except in
              relation to personal injury.
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              8. If FRÄYA Group incurs product liability towards a third party
              that the Customer is rightfully liable for, the Customer shall
              indemnify FRÄYA Group if and to the same extent as the liability
              of FRÄYA Group is limited in accordance with this clause 11, i.e.
              including but not limited to situations where FRÄYA Group is held
              liable towards the third party although no failure or negligence
              by FRÄYA Group is proven or where such failure or negligence by
              FRÄYA Group is proven, but the damages to be paid by FRÄYA Group
              to the third party exceeds the figures mentioned above.
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              9. FRÄYA Group is entitled to file a claim against the Customer at
              the same venue as a third party has filed a claim against FRÄYA
              Group in relation to a dispute concerning product liability
              arising from the Products.
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              10. The parties agree that FRÄYA Group assumes no liability for
              the Products’ possible infringement of any intellectual property
              rights of third parties.
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              11. In the event that the Customer receives notice or is otherwise
              informed of any claim, suit or demand on account of any alleged
              infringement of intellectual property rights of third parties
              relating to the Products, the Customer must promptly notify FRÄYA
              Group thereof.
            </p>
            <h3
              style={{
                fontSize: "16px",
                color: colors.blue,
                fontFamily: "Montserrat",
                fontWeight: 700,
                marginTop: "10px",
                marginBottom: "20px",
              }}
            >
              IX. Confidentiality
            </h3>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              1. The parties treats all information and documents, including
              prices and other delivery terms, received from the other party as
              well as other information relating to the business relationship
              between the parties as confidential and shall not use such
              information, or disclose it to any third party, except insofar as
              strictly necessary for the performance of the parties’ obligations
              towards each other or if expressly required by law. This
              obligation remains in force for 5 years following the latest
              purchase made by the Customer.
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              2. These Terms and Conditions, including disputes regarding their
              existence or validity, are governed by Polish law, irrespective of
              any conflict of laws rules, which could otherwise result in the
              application of the laws of another jurisdiction to the dispute,
              and excluding any international private law rules such as the
              United Nations Convention on Contracts for the International Sale
              of Goods (CISG).
            </p>
            <h3
              style={{
                fontSize: "16px",
                color: colors.blue,
                fontFamily: "Montserrat",
                fontWeight: 700,
                marginTop: "10px",
                marginBottom: "20px",
              }}
            >
              X. Adverse event reporting and recall
            </h3>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              1. The Customer must within 3 days from the time when information
              on an adverse event came or should have come to the Customer’s
              knowledge provide any available information thereon to FRÄYA
              Group. In this respect, an adverse event means any malfunction,
              failure, defect or deterioration in the characteristics and/or
              performance of a Product, as well as any inadequacy in the
              labelling or the instructions for use which, directly or
              indirectly, has, might lead to or might have led to the death of a
              patient or user or of other persons or to a serious deterioration
              in their state of health and any technical or medical reason in
              relation to the characteristics or performance of a product
              leading to systematic recall of Products of the same type by the
              Customer.
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              2. If the Customer becomes aware of any other information
              regarding the Products, including possible infringement of
              intellectual property rights, that the Customer believes is
              reasonably likely to be of importance to FRÄYA Group, it must
              immediately notify FRÄYA Group.
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              3. FRÄYA Group will notify the Customer immediately in the event
              of FRÄYA Group’ knowledge about a product recall and will provide
              instructions on how to assist in returning all affected products.
              FRÄYA Group may determine the recall measures required from the
              Customer on a recall-by-recall basis, and the Customer must comply
              with such measures unless such instructions are in violation of
              laws and regulations applicable to the Customer. Without further
              instruction, the Customer must provide to FRÄYA Group any
              available documentation on seizure notices from public agencies
              and recall notices sent to the Customer’s subsequent customers.
              The Customer bears its own cost in connection with recalls of the
              Products. The Customer provides all reasonable assistance
              requested by FRÄYA Group in the conduct of a recall.
            </p>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isMobile: state.isMobile, // (1)
    cart: state.cart,
    user: state.user,
  };
};
const mapDispatchToProps = { isMobileFetched}; // (2)
export default connect(mapStateToProps, mapDispatchToProps)(TermsAndConditions);
