import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import colors from "../../colors/colors";
import { Eye, EyeSlash } from "react-bootstrap-icons";

export default class PasswordInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      validated: false,
    };
  }

  inputValidation(){
    if(this.props.value !== null && this.props.value.length > 2){
      this.setState({validated: true});
    }else{
      this.setState({validated: false});
    }
  }

  render() {
    return (
      <>
      <Form.Group style={{marginBottom: '30px'}}>
        <Form.Label
          style={{
            fontSize: "18px",
            fontFamily: "Montserrat",
            fontWeight: 500,
          }}
        >
          {this.props.label}
        </Form.Label>
        <Form.Control 
          style={{ backgroundColor: "#f5f5f5", border: this.props.isError ? 'solid 1px #f19cb0' : this.state.validated ? 'solid 1px #35749066' : 'none' }} 
          type={this.props.type ? 'password' : 'input'} 
          value={this.props.value} 
          onChange={this.props.onChange} 
          onBlur={this.inputValidation.bind(this)}
          />
        <InputGroup.Prepend  style={{
          position: 'relative',
          top: '-30px',
          textAlign: 'right',
          color: colors.white,
          paddingRight: '10px',
          height: '0',
          cursor: 'pointer',
        }}>
          <InputGroup.Text style={{
            display: 'flex',
            marginBottom: '0',
            padding: '0',
            marginTop: '4px',
            color: colors.blue,
            border: 'none',
            position: 'absolute',
            right: '15px',
            fontSize: '14px',
          }} 
          onClick={this.props.eyeClick} id="visibilyty">{this.props.type ? <EyeSlash /> : <Eye/>}</InputGroup.Text>
        </InputGroup.Prepend>
      </Form.Group>
      {this.props.isError? <p style={{
        fontSize: '10px',
         color: colors.darkPink, 
         width: '100%', 
         marginBottom: '4px',
         marginTop: '-16px',
         marginLeft: '10px',
         }}>{this.props.errorMessage}</p> : <></>}
      </>
    );
  }
}
