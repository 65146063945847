import React from "react";
import { Row, Col } from "react-bootstrap";
import colors from "../colors/colors";
import ProductInCart from "../components/cart/ProductInCart";
import { connect } from "react-redux";
import { isMobileFetched, cartFetched, userFetched, historicalOrderFetched, localeFetched } from "../actions";
import ListingPage from "./ListingPage";
import Price from "../components/general/Price";
import Request from "../api/Request";
import BlueButton from "../components/general/BlueButton";
import HistoricalProductOnList from "../components/cart/HistoricalProductOnList";
import DeliveryCost from "../components/general/DeliveryCost";
import DeliveryCostCalculator from "../models/DeliveryCostCalculator";

class HistoricalOrder extends ListingPage {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: true,
      headerID: 1,
      checkoutPage: true,
      loggedIn: false,
      products: [],
      costs: {},
      exchange: {},
    };
  }

  componentDidMount() {
    Request.getProducts((response) => {
      if (response.success) {
        let products = response.products;
        let filteredProducts = [];
        if (this.props.historicalOrder && this.props.historicalOrder.number) {


          this.props.historicalOrder.products.forEach((historicalProduct) => {
            let newProduct = { ...historicalProduct };
            newProduct.current = null;
            products.forEach((product) => {
              if (historicalProduct.id == product.id) {
                newProduct.current = { ...product };
              }
            });
            filteredProducts.push(newProduct);
          });


          this.setState({ products: filteredProducts });
          Request.getDeliveryCosts((response) => {
            if (response.success) {
              this.setState({ costs: response.deliveryCosts });
            }
          }, filteredProducts, this.props.locale.country);
        }
      }
    });


    Request.getExchange((response) => {
      if (response.success) {
        this.setState({ exchange: response.exchangeRates });
      }
    });

    if (Object.keys(this.props.historicalOrder).length === 0) {
      this.props.history.push('/')
    }
  }

  componentDidUpdate(prevProps) {
    Request.getDeliveryCosts(response => {
      if (response.success && (!this.state.costs.length || response.deliveryCosts[0].UPS_price != this.state.costs[0].UPS_price)) {
        this.setState({ costs: response.deliveryCosts });
      }
    }, this.state.products, this.props.locale.country);
  }

  handleQuantityChange(event, id) {
    const { value } = event.target;
    this.setState(
      (state) => {
        if (parseInt(value) > state.products[id].stock) {
          state.products[id].quantity = state.products[id].stock;
        } else if (parseInt(value) < 0) {
          state.products[id].quantity = 0;
        } else {
          state.products[id].quantity = parseInt(value);
        }
        return state;
      },
      () => {
        window.localStorage.setItem(
          "cart",
          JSON.stringify(this.state.products)
        );
      }
    );
    this.props.cartFetched(this.state.products);
    window.localStorage.setItem('cart', JSON.stringify(this.state.products));
  }

  quantityPlus(id) {
    this.setState(
      (state) => {
        if (
          parseInt(state.products[id].quantity) + 1 >
          state.products[id].stock
        ) {
          state.products[id].quantity = state.products[id].stock;
        } else {
          state.products[id].quantity =
            parseInt(state.products[id].quantity) + 1;
        }
        return state;
      },
      () => {
        this.props.cartFetched(this.state.products);
        window.localStorage.setItem('cart', JSON.stringify(this.state.products));
      }
    );
  }

  quantityMinus(id) {
    this.setState(
      (state) => {
        if (state.products[id].quantity - 1 < 0) {
          state.products[id].quantity = 0;
        } else {
          state.products[id].quantity -= 1;
        }
        return state;
      },
      () => {
        this.props.cartFetched(this.state.products);
        window.localStorage.setItem('cart', JSON.stringify(this.state.products));
      }
    );
  }

  removeProduct(id) {
    const products = this.state.products;
    products.splice(id, 1);

    this.setState({ products: products }, () => {
      this.props.cartFetched(this.state.products);
      window.localStorage.setItem('cart', JSON.stringify(this.state.products));
    });
  }

  addToCart() {
    this.state.products.forEach((product) => {
      if (product.current) {
        const cart = this.props.cart;

        let found = false;

        cart.forEach((element, id) => {
          if (element.id == product.id) {
            found = true;
            cart[id].quantity += product.quantity;
          }
        });

        if (!found) {
          product.current.quantity = product.quantity;
          cart.push({ ...product.current });
        }
        this.props.cartFetched(cart);

        window.localStorage.setItem('cart', JSON.stringify(cart));
        window.showTopInfo();
      }
    });

  }

  orderPriceSum() {
    let total = 0;
    const products = this.state.products;
    for (let i = 0; i < products.length; i++) {
      if (products[i].current) {
        total = total + products[i].current.price * products[i].quantity;
      }
    }

    return total;
  }

  render() {

    let calculator = new DeliveryCostCalculator(
      this.props.locale,
      this.state.costs,
      this.state.exchange
    );

    let date = new Date;

    return (
      <>
        <Row style={this.props.isMobile ? { paddingTop: '80px', marginBottom: '100px' } : { paddingTop: "150px" }}>

          <Col md={8}>
            <Row>
              <Col>
                <h3
                  style={{
                    fontSize: this.props.isMobile ? '20px' : "32px",
                    color: colors.blue,
                    fontFamily: "Raleway",
                    fontWeight: 900,
                    marginTop: "10px",
                    marginBottom: "20px",
                  }}
                >
                  {this.props.historicalOrder.number}
                </h3>
              </Col>
            </Row>
            <div>
              {this.state.products.map((product, id) => {
                return (
                  <HistoricalProductOnList
                    key={id}
                    number={id}
                    product={product}
                    handleInputChange={this.handleQuantityChange.bind(this)}
                    productId={id}
                    quantityPlus={this.quantityPlus.bind(this)}
                    quantityMinus={this.quantityMinus.bind(this)}
                    isMobile={this.props.isMobile}
                    removeProduct={() => {
                      this.removeProduct(id);
                    }}
                  />
                );
              })}
            </div>
          </Col>
          <Col>
            <h3
              style={{
                fontSize: "24px",
                color: colors.blue,
                fontFamily: "Raleway",
                fontWeight: 700,
                marginTop: "19px",
                marginBottom: "21px",
                textAlign: this.props.isMobile ? "center" : "inherit",
                display: "block",
              }}
            >
              Order Summary  ({date.getFullYear()}/{date.getMonth() + 1}/{date.getDate()})
        </h3>
            <div
              style={{
                border: "solid 1px rgba(54, 118, 145, 0.2)",
                borderRadius: "5px",
                margin: 0,
                marginBottom: "20px",
                padding: "20px",
                paddingBottom: "20px",
              }}
            >
              <Row style={{ display: this.props.isMobile ? "none" : "flex" }}>
                <Col>
                  <h4
                    style={{
                      fontSize: "16px",
                      fontFamily: "Montserrat",
                      fontWeight: 600,
                    }}
                  >
                    Subtotal
              </h4>
                </Col>
                <Col style={{ textAlign: "right" }}>
                  <p
                    style={{
                      fontSize: "16px",
                      fontFamily: "Montserrat",
                      fontWeight: 600,
                      marginBottom: '0px',
                    }}
                  >
                    <Price price={this.orderPriceSum()} />
                  </p>
                </Col>
              </Row>
              <Row style={{ display: this.props.isMobile ? "none" : "flex" }}>
                <Col>
                  <h4
                    style={{
                      fontSize: "16px",
                      fontFamily: "Montserrat",
                      fontWeight: 400,
                    }}
                  >
                    Delivery
              </h4>
                </Col>
                <Col style={{ textAlign: "right" }}>
                  <p
                    style={{
                      fontSize: "16px",
                      fontFamily: "Montserrat",
                      fontWeight: 400,
                    }}
                  >
                    <DeliveryCost onlyNumber={true} />
                  </p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h4
                    style={{
                      fontSize: "15px",
                      fontFamily: "Montserrat",
                      fontWeight: 600,
                    }}
                  >
                    Total
              </h4>
                </Col>
                <Col style={{ textAlign: "right" }}>
                  <p
                    style={{
                      fontSize: "15px",
                      fontFamily: "Montserrat",
                      fontWeight: 600,
                    }}
                  >
                    <Price price={this.orderPriceSum() + calculator.calculateCostRaw("lowest_price")} />
                  </p>
                </Col>
              </Row>
              <p
                style={{
                  fontSize: "15px",
                  fontFamily: "Montserrat",
                  fontWeight: 400,
                  margin: "0",
                }}
              >
                {/* Safe Paypent lorem ipsum lorem ipsm lorem ipsum */}
              </p>
              <BlueButton
                content='Add to cart'
                width='100%'
                onClick={this.addToCart.bind(this)}
                margin='0 0 0px'
              />
            </div>
            <h3
              style={{
                fontSize: "24px",
                color: colors.blue,
                fontFamily: "Raleway",
                fontWeight: 700,
                marginTop: "19px",
                marginBottom: "21px",
                textAlign: this.props.isMobile ? "center" : "inherit",
                display: "block",
              }}
            >
              Historical Order Summary
        </h3>
            <div
              style={{
                border: "solid 1px rgba(54, 118, 145, 0.2)",
                borderRadius: "5px",
                margin: 0,
                marginBottom: "20px",
                padding: "20px",
                paddingBottom: "20px",
              }}
            >
              <Row style={{ display: this.props.isMobile ? "none" : "flex" }}>
                <Col>
                  <h4
                    style={{
                      fontSize: "16px",
                      fontFamily: "Montserrat",
                      fontWeight: 600,
                    }}
                  >
                    Subtotal
              </h4>
                </Col>
                <Col style={{ textAlign: "right" }}>
                  <p
                    style={{
                      fontSize: "16px",
                      fontFamily: "Montserrat",
                      fontWeight: 600,
                      marginBottom: '0px',
                    }}
                  >
                    <Price price={this.props.historicalOrder.subtotal} />
                  </p>
                </Col>
              </Row>
              <Row style={{ display: this.props.isMobile ? "none" : "flex" }}>
                <Col>
                  <h4
                    style={{
                      fontSize: "16px",
                      fontFamily: "Montserrat",
                      fontWeight: 400,
                    }}
                  >
                    Delivery
              </h4>
                </Col>
                <Col style={{ textAlign: "right" }}>
                  <p
                    style={{
                      fontSize: "16px",
                      fontFamily: "Montserrat",
                      fontWeight: 400,
                    }}
                  >
                    <Price price={this.props.historicalOrder.delivery} />
                  </p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h4
                    style={{
                      fontSize: "15px",
                      fontFamily: "Montserrat",
                      fontWeight: 600,
                    }}
                  >
                    Total
              </h4>
                </Col>
                <Col style={{ textAlign: "right" }}>
                  <p
                    style={{
                      fontSize: "15px",
                      fontFamily: "Montserrat",
                      fontWeight: 600,
                    }}
                  >
                    <Price price={this.props.historicalOrder.total} />
                  </p>
                </Col>
              </Row>
              <p
                style={{
                  fontSize: "15px",
                  fontFamily: "Montserrat",
                  fontWeight: 400,
                  margin: "0",
                }}
              >
                {/* Safe Paypent lorem ipsum lorem ipsm lorem ipsum */}
              </p>
            </div>
            <p
              style={{
                color: colors.graphite,
                fontSize: "14px",
                fontFamily: "Montserrat",
              }}
            >
              Our products are sold for professional use only
        </p>
          </Col>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isMobile: state.isMobile, // (1)
    cart: state.cart,
    user: state.user,
    historicalOrder: state.historicalOrder,
    locale: state.locale,
  };
};
const mapDispatchToProps = { isMobileFetched, cartFetched, userFetched, historicalOrderFetched, localeFetched }; // (2)
export default connect(mapStateToProps, mapDispatchToProps)(HistoricalOrder);
