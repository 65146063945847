import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import colors from "../../colors/colors";
import QuickBuyForm from "../payment/QuickBuyForm";
import BlueButton from "./BlueButton";
import DeliveryCost from "./DeliveryCost";
import Price from "./Price";
import { connect } from "react-redux";
import { cartFetched, localeFetched } from "../../actions";
import DeliveryCostCalculator from "../../models/DeliveryCostCalculator";
import Request from "../../api/Request";

class OrderSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      costs: {},
      exchange: {},
    };
  }

  componentDidMount() {

    Request.getExchange((response) => {
      if (response.success) {
        this.setState({ exchange: response.exchangeRates });
      }
    });
  }

  componentDidUpdate(prevProps) {
    Request.getDeliveryCosts(response => {
      if (response.success && (!this.state.costs.length || response.deliveryCosts[0].UPS_price != this.state.costs[0].UPS_price)) {
        this.setState({ costs: response.deliveryCosts });
      }
    }, this.props.cart, this.props.locale.country);
  }

  render() {
    let calculator = new DeliveryCostCalculator(
      this.props.locale,
      this.state.costs,
      this.state.exchange
    );
    return (
      <Col>
        <h3
          style={{
            fontSize: "24px",
            color: colors.blue,
            fontFamily: "Raleway",
            fontWeight: 700,
            marginTop: "19px",
            marginBottom: "21px",
            textAlign: this.props.isMobile ? "center" : "inherit",
            display: this.props.isMobile ? "none" : "block",
          }}
        >
          Order Summary
        </h3>
        <div
          style={{
            border: "solid 1px rgba(54, 118, 145, 0.2)",
            borderRadius: "5px",
            margin: 0,
            marginBottom: "20px",
            padding: "20px",
            paddingBottom: "20px",
          }}
        >
          <Row style={{ display: this.props.isMobile ? "none" : "flex" }}>
            <Col>
              <h4
                style={{
                  fontSize: "16px",
                  fontFamily: "Montserrat",
                  fontWeight: 600,
                }}
              >
                Subtotal
              </h4>
            </Col>
            <Col style={{ textAlign: "right" }}>
              <p
                style={{
                  fontSize: "16px",
                  fontFamily: "Montserrat",
                  fontWeight: 600,
                  marginBottom: '0px',
                }}
              >
                <Price price={this.props.rawTotal()} />
              </p>
            </Col>
          </Row>
          <Row style={{ display: this.props.isMobile ? "none" : "flex" }}>
            <Col>
              <h4
                style={{
                  fontSize: "16px",
                  fontFamily: "Montserrat",
                  fontWeight: 400,
                }}
              >
                Delivery
              </h4>
            </Col>
            <Col style={{ textAlign: "right" }}>
              <p
                style={{
                  fontSize: "16px",
                  fontFamily: "Montserrat",
                  fontWeight: 400,
                }}
              >
                <DeliveryCost costss={this.state.costs} onlyNumber={true} />
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h4
                style={{
                  fontSize: "15px",
                  fontFamily: "Montserrat",
                  fontWeight: 600,
                }}
              >
                Total
              </h4>
            </Col>
            <Col style={{ textAlign: "right" }}>
              <p
                style={{
                  fontSize: "15px",
                  fontFamily: "Montserrat",
                  fontWeight: 600,
                }}
              >
                <Price
                  price={
                    this.props.rawTotal() +
                    calculator.calculateCostRaw("lowest_price")
                  }
                />
              </p>
            </Col>
          </Row>
          {this.props.hideButtons ? (
            <></>
          ) : (
              <>
                <QuickBuyForm
                  width="100%"
                  products={this.props.cart}
                  noCol={true}
                  onClick={() => this.setState({ hideAddToCart: true })}
                />
                <Link to={this.props.loggedIn ? "/checkout" : "/pre-checkout"}>
                  <BlueButton
                    width="100%"
                    content="Check out"
                    margin="20px 0px 0px"
                  />
                </Link>
              </>
            )}
          <p
            style={{
              fontSize: "15px",
              fontFamily: "Montserrat",
              fontWeight: 400,
              margin: "0",
            }}
          >
            {/* Safe Paypent lorem ipsum lorem ipsm lorem ipsum */}
          </p>
        </div>
        <p
          style={{
            color: colors.graphite,
            fontSize: "14px",
            fontFamily: "Montserrat",
          }}
        >
          Our products are sold for professional use only
        </p>
      </Col>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    locale: state.locale,
    cart: state.cart,
  };
};
const mapDispatchToProps = { localeFetched, cartFetched }; // (2)
export default connect(mapStateToProps, mapDispatchToProps)(OrderSummary);
