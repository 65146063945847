import React from "react";
import { Row, Col } from "react-bootstrap";
import colors from "../colors/colors";
import CardMyAccount from "../components/accont/CardMyAccound";
import CardAccountDetails from "../components/accont/CardAccountDetails";
import Request from "../api/Request";
import CardOrderHistory from "../components/accont/CardOrderHistory";
import CardHelp from "../components/accont/CardHelp";
import RequestPopup from "../components/general/RequestPopup";
import { connect } from "react-redux";
import {
  isMobileFetched,
  cartFetched,
  userFetched,
  userPageFetched,
} from "../actions";
import countryCodes from "../components/form/countryCodes";

class MyAccountPage extends React.Component {
  constructor(props) {
    super(props);

    window.showAccountMenu = function(){
      this.setState({card: 0})
    }.bind(this)

    this.state = {
      order: {},
      card: 1,
      // Acocunt Information
      accountType: "corporate",
      name: "",
      surname: "",
      email: "",
      phone: "",
      countryNumber: '',
      // Change password
      currentPassword: "",
      newPassword: "",
      repeatNewPassword: "",
      // Company Information
      companyName: "",
      companyAddress: "",
      companyExtraAddress: "",
      companyPostalCode: "",
      companyCity: "",
      companyCountry: "",
      companyVatNumber: "",
      // Show/Hide password
      showCurrentPassword: true,
      showNewPassword: true,
      showRepeatNewPassword: true,
      shippingInformation: [],
      fillShippingInfo: false,
      changedPassword: false,
      showRequsetMessage: false,
    };
  }

  componentDidMount() {
    if(this.props.isMobile){
      this.setState({card: 0})
    }

    if (!this.props.user || !this.props.user.email) {
      this.props.history.push("/login");
    } else {
      this.props.userPageFetched(true);
      Request.getOrderHistory((response) => {
        if (response.success) {
          this.setState({ order: response.orders });
        }
      });
    }
    this.setState({
      name: this.props.user.name,
      surname: this.props.user.surname,
      email: this.props.user.email,
      phone: this.props.user.phone_number,
      // // Change password
      // currentPassword: this.props.user.,
      // newPassword: this.props.user.,
      // repeatNewPassword: this.props.user.,
      // Company Information
      companyName: this.props.user.company_name,
      companyAddress: this.props.user.address,
      companyExtraAddress: this.props.user.extra_address,
      companyPostalCode: this.props.user.post_code,
      companyCity: this.props.user.city,
      companyCountry: this.props.user.country,
      companyVatNumber: this.props.user.vat_number,
      shippingInformation: this.props.user.shipping_information,
      countryNumber: this.props.user.country_number,
      // // Shipping Information
      // shippingName: this.props.user.,
      // shippingSurname: this.props.user.,
      // shippingAddress: this.props.user.address,
      // shippingPostalCode: this.props.user.,
      // shippingCity: this.props.user.,
      // shippingCountry: this.props.user.,
    }, () => {
      if (this.state.shippingInformation.length == 0) {
        this.addShippingInformation();
        this.setState({ fillShippingInfo: true });
      }
    });
  }

  componentWillUnmount() {
    this.props.userPageFetched(false);
  }

  handleAccountTypeChange(event) {
    const value = event.target.value;
    this.setState({ accountType: value });
  }

  handleNameChange(event) {
    const value = event.target.value;
    this.setState({ name: value });
    if (this.state.fillShippingInfo) {
      const shippingInformation = this.state.shippingInformation;
      shippingInformation[0].name = value;

      this.setState({ shippingInformation: shippingInformation });
    }
  }

  handleSurnameChange(event) {
    const value = event.target.value;
    this.setState({ surname: value });

    if (this.state.fillShippingInfo) {
      const shippingInformation = this.state.shippingInformation;
      shippingInformation[0].surname = value;

      this.setState({ shippingInformation: shippingInformation });
    }
  }

  handleEmailChange(event) {
    const value = event.target.value;
    this.setState({ email: value });
  }

  handlePhoneChange(event) {
    const value = event.target.value;
    this.setState({ phone: value });
  }

  handleCountryNumberChange(event) {
    const value = event.target.value;
    this.setState({ countryNumber: value });
  }

  handleCurrentPasswordChange(event) {
    const value = event.target.value;
    this.setState({ currentPassword: value });
  }

  handleNewPasswordChange(event) {


    const value = event.target.value;
    this.setState({ newPassword: value, changedPassword: true });
  }

  handleRepeatNewPasswordChange(event) {
    const value = event.target.value;
    this.setState({ repeatNewPassword: value });
  }

  handleCompanyNameChange(event) {
    const value = event.target.value;
    this.setState({ companyName: value });

    if (this.state.fillShippingInfo) {
      const shippingInformation = this.state.shippingInformation;
      shippingInformation[0].companyName = value;

      this.setState({ shippingInformation: shippingInformation });
    }

  }

  handleCompanyAddressChange(event) {
    const value = event.target.value;
    this.setState({ companyAddress: value });

    if (this.state.fillShippingInfo) {
      const shippingInformation = this.state.shippingInformation;
      shippingInformation[0].address = value;

      this.setState({ shippingInformation: shippingInformation });
    }
  }

  handleCompanyExtraAddressChange(event) {
    const value = event.target.value;
    this.setState({ companyExtraAddress: value });

    if (this.state.fillShippingInfo) {
      const shippingInformation = this.state.shippingInformation;
      shippingInformation[0].extraAddress = value;

      this.setState({ shippingInformation: shippingInformation });
    }
  }

  handleCompanyPostalCodeChange(event) {
    const value = event.target.value;
    this.setState({ companyPostalCode: value });

    if (this.state.fillShippingInfo) {
      const shippingInformation = this.state.shippingInformation;
      shippingInformation[0].postalCode = value;

      this.setState({ shippingInformation: shippingInformation });
    }
  }

  handleCompanyCityChange(event) {
    const value = event.target.value;
    this.setState({ companyCity: value });

    if (this.state.fillShippingInfo) {
      const shippingInformation = this.state.shippingInformation;
      shippingInformation[0].city = value;

      this.setState({ shippingInformation: shippingInformation });
    }
  }

  handleCompanyCountryChange(event) {
    const value = event.target.value;

    if(this.state.countryNumber == '' || this.state.countryNumber == null){
      this.setState({
          companyCountry: value,
          countryNumber: '+' + countryCodes[value],
      });
      }else{
        this.setState({ companyCountry: value });
      }


    if (this.state.fillShippingInfo) {
      const shippingInformation = this.state.shippingInformation;
      shippingInformation[0].country = value;

      this.setState({ shippingInformation: shippingInformation });
    }
  }

  handleCompanyVatNumberChange(event) {
    const value = event.target.value;
    this.setState({ companyVatNumber: value });
  }

  handleShippingNameChange(event) {
    const value = event.target.value;
    this.setState({ shippingName: value });
  }

  handleShippingSurnameChange(event) {
    const value = event.target.value;
    this.setState({ shippingSurname: value });
  }

  handleShippingAddressChange(event) {
    const value = event.target.value;
    this.setState({ shippingAddress: value });
  }

  handleShippingPostalCodeChange(event) {
    const value = event.target.value;
    this.setState({ shippingPostalCode: value });
  }

  handleShippingCityChange(event) {
    const value = event.target.value;
    this.setState({ shippingCity: value });
  }

  handleShippingCountryChange(event) {
    const value = event.target.value;
    this.setState({ shippingCountry: value });
  }

  eyeCurrentClick() {
    this.setState({ showCurrentPassword: !this.state.showCurrentPassword });
  }

  eyeNewClick() {
    this.setState({ showNewPassword: !this.state.showNewPassword });
  }

  eyeRepeatNewClick() {
    this.setState({ showRepeatNewPassword: !this.state.showRepeatNewPassword });
  }

  setCardOne() {
    this.setState({ card: 1 });
  }

  setCardTwo() {
    this.setState({ card: 2 });
  }

  setCardThree() {
    this.setState({ card: 3 });
  }

  setCardFour() {
    this.setState({ card: 4 });
  }

  setCardFive() {
    this.setState({ card: 5 });
  }

  save() {
    if (!this.validate()
      && this.validateShippingInfo()
    ) {
      this.setState({ hasBeenSubmited: true });
      return;
    }
    this.setState({ hasBeenSubmited: false });
    Request.saveMyData(
      {
        email: this.state.email,
        account_type: this.state.email,
        currentPassword: this.state.currentPassword,
        newPassword: this.state.newPassword,
        repeatPassword: this.state.repeatNewPassword,
        name: this.state.name,
        surname: this.state.surname,
        phone_number: this.state.phone,
        country_number: this.state.countryNumber,
        company_name: this.state.companyName,
        address: this.state.companyAddress,
        extra_address: this.state.companyExtraAddress,
        post_code: this.state.companyPostalCode,
        city: this.state.companyCity,
        country: this.state.companyCountry,
        vat_number: this.state.companyVatNumber,
        shippingInformation: this.state.shippingInformation
      },
      (response) => {
        if (response.success) {
          window.localStorage.setItem('user', JSON.stringify({ ...response.me }));
          this.props.userFetched({ ...response.me });
          this.setState({ saveMessage: "Saved successfully!", showRequsetMessage: true });
          setTimeout(() => {this.setState({showRequsetMessage: false})} , 2000 );
        } else {
          this.setState({ saveMessage: "There was an error!" , showRequsetMessage: true });
          setTimeout(() => {this.setState({showRequsetMessage: false}) }, 2000 );
        }
      }
    );
  }

  deleteAccount() {
    Request.deleteAccount((response) => {
      if (response.success) {
        this.props.history.push("/");
      }
    });
  }

  saveAccountInformation() {
    if (!this.validate()) {
      this.setState({ hasBeenSubmited: true });
      return;
    }

    console.log("zapisane");
  }

  addShippingInformation() {
    const shippingInformation = this.state.shippingInformation;

    var object = {
      id: this.state.shippingInformation.length == 0 ? 0 : this.state.shippingInformation[this.state.shippingInformation.length - 1].id + 1,
      name: '',
      surname: '',
      company_name: '',
      address: "",
      extra_address: '',
      post_code: "",
      city: "",
      country: "",
    };

    shippingInformation.push(object);

    this.setState({ shippingInformation: shippingInformation });
  }

  saveSingleAddress(id, data) {
    const shippingInformation = this.state.shippingInformation;
    shippingInformation.forEach((element) => {
      if (element.id == id) {
        element.name = data.name;
        element.surname = data.surname;
        element.company_name = data.companyName;
        element.address = data.address;
        element.extra_address = data.extraAddress;
        element.post_code = data.postalCode;
        element.city = data.city;
        element.country = data.country;
      }
    });

    this.setState({ shippingInformation: shippingInformation });
  }

  validateShippingInfo() {
    if (this.state.accountType == "corporate") {
      let result;
      this.state.shippingInformation.forEach((element) => {
        result = (element.companyName != null &&
          element.companyName.length > 1 &&
          element.address != null &&
          element.address.length > 1 &&
          element.postalCode != null &&
          element.postalCode.length > 1 &&
          element.city != null &&
          element.city.length > 1 &&
          element.country != null &&
          element.country.length > 1)
        if (!result) return false;
      })
      return true;
    } else if (this.state.accountType == "private") {
      let result;
      this.state.shippingInformation.forEach((element) => {
        result = (element.name != null &&
          element.name.length > 1 &&
          element.surname != null &&
          element.surname.length > 1 &&
          element.address != null &&
          element.address.length > 1 &&
          element.postalCode != null &&
          element.postalCode.length > 1 &&
          element.city != null &&
          element.city.length > 1 &&
          element.country != null &&
          element.country.length > 1)
        if (!result) return false;
      })
      return true;
    }
  }

  validatePasswords() {
    if (this.state.changedPassword == false) {
      return true;
    }
    if (this.state.newPassword != this.state.repeatNewPassword) {
      return false;
    }
  }

  validate() {
    if (this.state.accountType == "corporate") {
      return (
        this.state.companyName != null &&
        this.state.companyName.length > 1 &&
        this.state.companyAddress != null &&
        this.state.companyAddress.length > 1 &&
        this.state.email != null &&
        this.state.email.length > 1 &&
        this.state.phone != null &&
        this.state.phone.length > 1 &&
        this.state.countryNumber != null &&
        this.state.countryNumber.length > 1 &&
        this.state.companyPostalCode != null &&
        this.state.companyPostalCode.length > 1 &&
        this.state.companyCity != null &&
        this.state.companyCity.length > 1 &&
        this.state.companyCountry != null &&
        this.state.companyCountry.length > 1 &&
        this.state.companyVatNumber != null &&
        this.state.companyVatNumber.length > 1
      );
    } else if (this.state.accountType == "private") {
      return (
        this.state.name != null &&
        this.state.name.length > 1 &&
        this.state.surname != null &&
        this.state.surname.length > 1 &&
        this.state.email != null &&
        this.state.email.length > 1 &&
        this.state.phone != null &&
        this.state.phone.length > 1 &&
        this.state.countryNumber != null &&
        this.state.countryNumber.length > 1
      );
    }
  }

  deleteShippingInfo(id) {
    const shippingInformation = this.state.shippingInformation;
    let index;

    shippingInformation.forEach((element) => {
      if (element.id == id) {
      index = shippingInformation.indexOf(element);
      }
    });

    shippingInformation.splice(index, 1);
    this.setState({ shippingInformation: shippingInformation });

  }

  render() {
    const navigationItem = {
      fontSize:  this.props.isMobile ? '20px' :  "24px",
      color: colors.blue,
      fontFamily: "Raleway",
      fontWeight: 900,
      marginTop: "10px",
      marginBottom: "30px",
      cursor: "pointer",
    };

    const showRequsetMessageClass = this.state.showRequsetMessage ? 'showRequsetMessage' : 'showRequsetMessage showRequsetMessageHidden'

    return (
      <>
      <RequestPopup  message={this.state.saveMessage} showRequsetMessage={showRequsetMessageClass}/>
      <Row style={this.props.isMobile ? {paddingTop: '80px'} : { paddingTop: "150px" }}>
        {this.props.isMobile && this.state.card != 0 ? <></> :
        <Col md={4}>
          {this.props.isMobile ? <></> :
          <h3
            className="accountNavItem"
            onClick={this.setCardOne.bind(this)}
            style={{
              fontSize: "24px",
              color: this.state.card == 1 ? colors.pink : colors.blue,
              fontFamily: "Raleway",
              fontWeight: 900,
              marginTop: "10px",
              marginBottom: "30px",
              cursor: "pointer",
            }}
          >
            My account
          </h3>}
          <h3
            className="accountNavItem"
            onClick={this.setCardTwo.bind(this)}
            style={{
              fontSize: this.props.isMobile ? '20px' : "24px",
              color: this.state.card == 2 ? colors.pink : colors.blue,
              fontFamily: "Raleway",
              fontWeight: 900,
              marginTop: "10px",
              marginBottom: "30px",
              cursor: "pointer",
            }}
          >
            Account details
          </h3>
          <h3
            className="accountNavItem"
            onClick={this.setCardThree.bind(this)}
            style={{
              fontSize: this.props.isMobile ? '20px' :  "24px",
              color: this.state.card == 3 ? colors.pink : colors.blue,
              fontFamily: "Raleway",
              fontWeight: 900,
              marginTop: "10px",
              marginBottom: "30px",
              cursor: "pointer",
            }}
          >
            Order history
          </h3>
          <h3
            className="accountNavItem"
            onClick={this.setCardFour.bind(this)}
            style={{
              fontSize: this.props.isMobile ? '20px' : "24px",
              color: this.state.card == 4 ? colors.pink : colors.blue,
              fontFamily: "Raleway",
              fontWeight: 900,
              marginTop: "10px",
              marginBottom: "30px",
              cursor: "pointer",
            }}
          >
            Help & contact
          </h3>
          <h3
            className="accountNavItem"
            onClick={() => {
              this.props.userFetched({});
              window.localStorage.removeItem('user');
              Request.setBearerFromStorage('');
              this.props.history.push("/login");
            }}
            style={navigationItem}
          >
            Log out
          </h3>
          </Col> }
        <Col xs={12} md={8}>
          {this.state.card == 1 ? <CardMyAccount /> : <></>}
          {this.state.card == 2 ? (
            <CardAccountDetails
              isMobile={this.props.isMobile}
              accountType={this.state.accountType}
              handleAccountTypeChange={this.handleAccountTypeChange.bind(this)}
              name={this.state.name}
              handleNameChange={this.handleNameChange.bind(this)}
              surname={this.state.surname}
              handleSurnameChange={this.handleSurnameChange.bind(this)}
              email={this.state.email}
              handleEmailChange={this.handleEmailChange.bind(this)}
              phone={this.state.phone}
              handlePhoneChange={this.handlePhoneChange.bind(this)}
              countryNumber={this.state.countryNumber}
              handleCountryNumberChange={this.handleCountryNumberChange.bind(this)}
              currentPassword={this.state.currentPassword}
              handleCurrentPasswordChange={this.handleCurrentPasswordChange.bind(
                this
              )}
              newPassword={this.state.newPassword}
              handleNewPasswordChange={this.handleNewPasswordChange.bind(this)}
              repeatNewPassword={this.state.repeatNewPassword}
              handleRepeatNewPasswordChange={this.handleRepeatNewPasswordChange.bind(
                this
              )}
              eyeCurrentClick={this.eyeCurrentClick.bind(this)}
              showCurrentPassword={this.state.showCurrentPassword}
              eyeNewClick={this.eyeNewClick.bind(this)}
              showNewPassword={this.state.showNewPassword}
              eyeRepeatNewClick={this.eyeRepeatNewClick.bind(this)}
              showRepeatNewPassword={this.state.showRepeatNewPassword}
              companyName={this.state.companyName}
              handleCompanyNameChange={this.handleCompanyNameChange.bind(this)}
              companyAddress={this.state.companyAddress}
              handleCompanyAddressChange={this.handleCompanyAddressChange.bind(
                this
              )}
              companyExtraAddress={this.state.companyExtraAddress}
              handleCompanyExtraAddressChange={this.handleCompanyExtraAddressChange.bind(
                this
              )}
              companyPostalCode={this.state.companyPostalCode}
              handleCompanyPostalCodeChange={this.handleCompanyPostalCodeChange.bind(
                this
              )}
              companyCity={this.state.companyCity}
              handleCompanyCityChange={this.handleCompanyCityChange.bind(this)}
              companyCountry={this.state.companyCountry}
              handleCompanyCountryChange={this.handleCompanyCountryChange.bind(
                this
              )}
              companyVatNumber={this.state.companyVatNumber}
              handleCompanyVatNumberChange={this.handleCompanyVatNumberChange.bind(
                this
              )}
              shippingName={this.state.shippingName}
              handleShippingNameChange={this.handleShippingNameChange.bind(
                this
              )}
              shippingSurname={this.state.shippingSurname}
              handleShippingSurnameChange={this.handleShippingSurnameChange.bind(
                this
              )}
              shippingAddress={this.state.shippingAddress}
              handleShippingAddressChange={this.handleShippingAddressChange.bind(
                this
              )}
              shippingPostalCode={this.state.shippingPostalCode}
              handleShippingPostalCodeChange={this.handleShippingPostalCodeChange.bind(
                this
              )}
              shippingCity={this.state.shippingCity}
              handleShippingCityChange={this.handleShippingCityChange.bind(
                this
              )}
              shippingCountry={this.state.shippingCountry}
              handleShippingCountryChange={this.handleShippingCountryChange.bind(
                this
              )}
              saveAccount={this.save.bind(this)}
              hasBeenSubmited={this.state.hasBeenSubmited}
              deleteAccount={this.deleteAccount.bind(this)}
              addShippingInformation={this.addShippingInformation.bind(this)}
              shippingInformation={this.state.shippingInformation}
              saveSingleAddress={(
                singleAddres,
                name,
                surname,
                companyName,
                address,
                postalCode,
                city,
                country
              ) =>
                this.saveSingleAddress(
                  singleAddres,
                  name,
                  surname,
                  companyName,
                  address,
                  postalCode,
                  city,
                  country
                )
              }
              deleteShippingInfo={(id) => this.deleteShippingInfo(id)}
              changedPassword={this.state.changedPassword}
              validatePasswords={this.validatePasswords.bind(this)}
            />
          ) : (
              <></>
            )}
          {this.state.card == 3 ? (
            <CardOrderHistory isMobile={this.props.isMobile} orders={this.state.order} />
          ) : (
              <></>
            )}
          {this.state.card == 4 ? <CardHelp /> : <></>}
        </Col>
      </Row>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isMobile: state.isMobile, // (1)
    cart: state.cart,
    user: state.user,
    userPage: state.userPage,
  };
};
const mapDispatchToProps = {
  isMobileFetched,
  cartFetched,
  userFetched,
  userPageFetched,
}; // (2)
export default connect(mapStateToProps, mapDispatchToProps)(MyAccountPage);
