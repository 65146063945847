import React from "react";
import { Row, Col } from "react-bootstrap";
import colors from "../colors/colors";
import image3 from "../img/companyInfoImg.png";
import { connect } from "react-redux";
import { isMobileFetched } from "../actions";

class CompanyInfoPage extends React.Component {
  render() {
    return (
      <Row style={{marginBottom: '-100px', letterSpacing:'0.5px',}}>
        <Col>
          <Row >
            <Col>
            <h1
              style={this.props.isMobile ?{
                fontSize: "20px",
                color: colors.blue,
                fontFamily: "Raleway",
                fontWeight: 900,
                paddingBottom: "20px",
                paddingTop: "80px",
                textAlign:'center',
              }:
                {
                fontSize: "90px",
                color: colors.blue,
                fontFamily: "Raleway",
                fontWeight: 900,
                paddingBottom: "50px",
                paddingTop: "150px",
              }}
            >
              Company
              <br />
              information
            </h1>
            </Col>
          </Row>
          <Row style={{paddingBottom: '50px'}}>
            <Col md={2}></Col>
            <Col md={6}>
              <h4
                style={{
                  fontSize: "16px",
                  color: colors.blue,
                  fontFamily: "Montserrat",
                  fontWeight: 900,
                }}
              >
                Fräya Group LTD
              </h4>
              <p
                style={{
                  fontSize: "16px",
                  color: "#969696",
                  fontFamily: "Montserrat",
                  fontWeight: 600,
                  marginTop: "20px",
                  marginBottom: "0",
                }}
              >
                NIP: PL586 235 06 92<br/>
              </p>
              <p
                style={{
                  fontSize: "16px",
                  color: colors.graphite,
                  fontFamily: "Montserrat",
                  fontWeight: 600,
                  marginTop: "20px",
                  marginBottom: "0",
                }}
              >
                +48 730 205 444
              </p>
              <a href="mailto:fraya@frayagroup.com"
              target="_blank"
                style={{
                  fontSize: "16px",
                  color: colors.graphite,
                  fontFamily: "Montserrat",
                  fontWeight: 600,
                  marginRight: "11px",
                  textDecoration: 'none',
                }}
              >
                fraya@frayagroup.com
              </a>
              <p
                style={{
                  fontSize: "16px",
                  color: colors.graphite,
                  fontFamily: "Montserrat",
                  fontWeight: 600,
                  marginTop: "20px",
                  marginBottom: "0",
                  textDecoration: 'none',
                }}
              >
                Baltiq Plaza
                <br />
                Świętojańska 43/18
                <br />
                81-391 Gdynia
                <br />
                Poland
              </p>
              <p
                style={{
                  fontSize: "16px",
                  color: "#969696",
                  fontFamily: "Montserrat",
                  fontWeight: 600,
                  marginTop: "20px",
                  marginBottom: "0",
                }}
              >
                Bank: Credit Agricole Bank Polska S.A.<br/>
                SWIFT: AGRIPLPR<br/>
                </p>
                <p
                style={{
                  fontSize: "16px",
                  color: "#969696",
                  fontFamily: "Montserrat",
                  fontWeight: 600,
                  marginTop: "20px",
                  marginBottom: "0",
                }}
              >
                PLN: PL39194010763207843500000000<br/>
                EUR: PL29194010763207844800000000<br/>
                USD: PL54194010763207846400000000<br/>
                GBP: PL64194010763207845100000000<br/>
              </p>
            </Col>
            <Col style={{ textAlign: "right" }}></Col>
            <Col md={1}></Col>
          </Row>
        </Col>

        <Col
          md={5}
          style={{
            backgroundImage: "url(" + image3 + ")",
            backgroundPosition: 'center',
            backgroundSize: 'cover',
          }}
        ></Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isMobile: state.isMobile, // (1)
  };
};
const mapDispatchToProps = { isMobileFetched}; // (2)
export default connect(mapStateToProps, mapDispatchToProps)(CompanyInfoPage);