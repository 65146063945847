import React from "react";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Image,
} from "react-bootstrap";
import { DashCircle, PlusCircle } from "react-bootstrap-icons";
import colors from "../../colors/colors";
import Request from "../../api/Request";
import Price from "../general/Price";
import { connect } from "react-redux";
import { isMobileFetched, productToShowFetched } from "../../actions";


class RecommendetProductOnList extends React.Component {
  setProductToShow(id) {
    this.props.productToShowFetched(id);
  }

  render() {

    return (
      <div className='my-auto' style={this.props.isMobile ? { padding: '0px 30px 0px 30px', width:'50%'} : { padding: '0px 30px 0px 30px', width:'20%' }}>
        <Link to={'/product/' + this.props.product.uri} onClick={() => {
          this.setProductToShow(this.props.product);
          window.scrollTo(0, 0);
          }}>
          <Image
            style={{ width: "110%" }}
            src={Request.realProductImage(this.props.product.small_image)}
            alt={this.props.product.alt_tag}
          />
        </Link>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isMobile: state.isMobile, // (1)
    productToShow: state.productToShow,
  };
};
const mapDispatchToProps = { isMobileFetched, productToShowFetched }; // (2)
export default connect(mapStateToProps, mapDispatchToProps)(RecommendetProductOnList);