import React from "react";
import { Row, Col } from "react-bootstrap";
import colors from "../colors/colors";
import ProductOnList from "../components/productList/ProductOnList";
import { connect } from "react-redux";
import {
  isMobileFetched,
  cartFetched,
  userFetched,
  currentCategoryFetched,
  searchValueFetched,
  currentTypeFetched,
} from "../actions";
import Request from "../api/Request";
import Dropdown from "../components/productList/Dropdown";
import { ReactComponent as Filters } from "../svg/filters.svg";
import BlueButton from "../components/general/BlueButton";

class CategoryProductListPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showTopInfo: false,
      addedProductName: "",
      addedQuantity: "",
      typeName: '',
      products: [],
      brands: [],
      producers: [],
      purposes: [],
      showFilters: false,
      purpose: 0,
      brandValue: 0,
      producerValue: 0,
      sort: 0,
      showMobileFilters: false,
    };
  }

  componentDidMount() {
    this.setState({ user: this.props.user });
    Request.getProducts((response) => {
      if (response.success) {
        this.setState({ products: response.products });
      }
    });

    Request.getCategories((response) => {
      if (response.success) {
        const type = response.categories.filter((category) => category.id == this.props.currentType);
        if (type) {
          this.setState({ typeName: type[0].name, brands: type[0].brands, producers: type[0].producers, typeDescription: type[0].typeDescription });
        }
      }
    });

    Request.getPurposes((response) => {
      if (response.success) {
        let purposes = [];
        Object.keys(response.categories).forEach(element => purposes.push(response.categories[element]));
        this.setState({ purposes: purposes });
      }
    });

    if (!this.props.currentType) {
      if (
        !this.props.match ||
        !this.props.match.params ||
        !this.props.match.params.typeUri
      ) {
        this.props.history.push("/");
      } else {
        Request.getCategoryTypeByUri(
          this.props.match.params.typeUri,
          (response) => {
            if (response.success) {
              this.props.currentTypeFetched(response.productType.id);
            } else {
              this.props.history.push("/");
            }
          }
        );
      }
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.currentType != this.props.currentType
    ) {
      Request.getCategories((response) => {
        if (response.success) {
          const type = response.categories.filter((category) => category.id == this.props.currentType);
          this.setState({ 
            typeName: type[0].name, 
            brands: type[0].brands, 
            producers: type[0].producers, 
            typeDescription: type[0].typeDescription });
        }
      });
    }
  }

  handleNewValueForMultiDropdown(value, old) {
    let newValue = null;
    value = parseInt(value);

    if (value == 0 || value === old) {
      newValue = 0;
    } else if (Array.isArray(old) && old.includes(value)) {
      let index = old.indexOf(value);
      newValue = [...old];
      newValue.splice(index, 1);
    } else if (old && !Array.isArray(old)) {
      newValue = [old, value];
    } else if (!old) {
      newValue = value;
    } else if (Array.isArray(old)) {
      newValue = [...old, value];
    }
    if (Array.isArray(newValue) && !newValue.length) {
      newValue = 0;
    }
    return newValue;
  }

  setPurposeValue(value) {
    let newValue = this.handleNewValueForMultiDropdown(value, this.state.purpose);
    this.setState({
      purpose: newValue,
      brandValue: 0,
      producerValue: 0,
    });
  }

  setSortValue(value) {
    this.setState({ sort: parseInt(value) });
  }

  setBrandValue(value) {
    let newValue = this.handleNewValueForMultiDropdown(value, this.state.brandValue);
    this.setState({
      brandValue: newValue,
      purpose: 0,
      producerValue: 0,
    });
  }

  setProducerValue(value) {
    let newValue = this.handleNewValueForMultiDropdown(value, this.state.producerValue);
    this.setState({
      producerValue: newValue,
      purpose: 0,
      brandValue: 0,
    });
  }

  showHideFilters() {
    this.setState({ showFilters: !this.state.showFilters });
    for(let i = 0; i < window.hideDropdown.length; i++) {
      window.hideDropdown[i]();
    }
  }

  filteredProducts() {
    if (this.state.currentType == 0) {
      return this.state.products.filter(
        this.props.searchValue != ""
          ? (product) =>
            product.name.toLowerCase().includes(this.props.searchValue)
          : (product) => product.type == this.props.currentType
      );
    } else {
      return this.state.products
        .filter(
          this.props.searchValue != ""
            ? (product) =>
              product.name.toLowerCase().includes(this.props.searchValue)
            : (product) => product.type == this.props.currentType
        )
        .filter(
          this.state.purpose != 0
            ? (product) => Array.isArray(this.state.purpose) && this.state.purpose.includes(product.purpose) || product.purpose == this.state.purpose
            : (product) => true
        )
        .filter(
          this.state.brandValue != 0
            ? (product) => Array.isArray(this.state.brandValue) && this.state.brandValue.includes(product.brand) || product.brand == this.state.brandValue
            : (product) => true
        )
        .filter(
          this.state.producerValue != 0
            ? (product) => Array.isArray(this.state.producerValue) && this.state.producerValue.includes(product.producer) || product.producer == this.state.producerValue
            : (product) => true
        );
    }
  }

  sortedProducts() {
    const products = this.filteredProducts();

    if (this.state.sort == 1) {
      return products.sort((a, b) => {
        return a.price - b.price;
      })
    } else if (this.state.sort == 2) {
      return products.sort((a, b) => {
        return b.price - a.price;
      })
    } else {
      return products;
    }
  }

  setShowMobileFilters(){
    this.setState({showMobileFilters: !this.state.showMobileFilters})
  }

  render() {
    const showMobileFiltersClass = this.state.showMobileFilters ? 'mobileFilters' : 'mobileFilters mobileFiltersHidden';
    const showHideFiltersClass = this.state.showFilters ? 'showHideFilters' : 'showHideFilters showHideFiltersHidden';
    const filtersColClass = this.state.showFilters ? ' filtersCol' : ' filtersCol  filtersColHidden';

    const showFiterClass = this.state.showFilters
      ? "productFilter"
      : "productFilter productFilterHidden";

    const producersForDropdown = this.state.producers.map(producer => {
      return { name: producer.producerName, id: producer.producerId };
    });
    const purposesForDropdown = this.state.purposes.map(purpose => {
      return { name: purpose.name, id: purpose.id };
    });
    const brandsForDropdown = this.state.brands.map(brand => {
      return { name: brand.brandName, id: brand.brandId };
    });
    return (
      <>
        <Row style={ this.props.isMobile ?  {paddingTop: "80px"} :{ paddingTop: "150px" }}>
          <Col md={2}></Col>
          <Col className={filtersColClass}>
            <h3
              style={{
                fontSize: "24px",
                color: colors.blue,
                fontFamily: "Raleway",
                fontWeight: 900,
                paddingTop: "25px",
                paddingBottom: "20px",
                background: colors.white,
                position: 'relative',
                zIndex: 1,
              }}
            >
              {this.state.typeName}
            </h3>
            {this.props.isMobile ? 
            <div className={showMobileFiltersClass} style={{width: '100vw', height: '100vh', position: 'fixed', top: 0, left: 0, transition: '1s', background: colors.white, padding: '80px 20px 20px', zIndex: 2}}>
                  <p 
                  onClick={this.setShowMobileFilters.bind(this)}
                  style={{
                    fontSize: "16px",
                    color: colors.blue,
                    fontFamily: "Montserrat",
                    fontWeight: 700,
                    position: "absolute",
                    top: '80px',
                    left: '20px',
                }}>
                  {'< Back'}
                </p>
                  <h3
              style={{
                fontSize: "20px",
                color: colors.blue,
                fontFamily: "Raleway",
                fontWeight: 900,
                marginTop: "40px",
                paddingBottom: "20px",
                position: 'relative',
                zIndex: 1,
                width: '100%',
                textAlign: 'center',
              }}
            >
              Filter & sort
            </h3>
              <div style={{paddingTop: '50px'}}>
               <Dropdown isMobile={true} className={showFiterClass} title="Producer" selectedId={this.state.producerValue} onChange={this.setProducerValue.bind(this)} options={producersForDropdown} />
               <Dropdown isMobile={true} className={showFiterClass} title="Purpose" selectedId={this.state.purpose} onChange={this.setPurposeValue.bind(this)} options={purposesForDropdown} />
               <Dropdown isMobile={true} className={showFiterClass} title="Brand" selectedId={this.state.brandValue} onChange={this.setBrandValue.bind(this)} options={brandsForDropdown} />
               <Dropdown isMobile={true} className={showFiterClass} title="Sort by" selectedId={this.state.sort} onChange={this.setSortValue.bind(this)} options={[{ id: 1, name: 'Lowest price' }, { id: 2, name: 'Highest price' }]} />
               </div>
               <div style={{textAlign: 'center', position: 'absolute', bottom: '100px', width: '100%', left: '0'}}>
               <BlueButton content='okay' onClick={this.setShowMobileFilters.bind(this)} />
               </div>
            </div> 
            : 
            <Row>
              <Col>
                <Dropdown className={showFiterClass} title="Producer" selectedId={this.state.producerValue} onChange={this.setProducerValue.bind(this)} options={producersForDropdown} />
              </Col>
              <Col>
                <Dropdown className={showFiterClass} title="Purpose" selectedId={this.state.purpose} onChange={this.setPurposeValue.bind(this)} options={purposesForDropdown} />
              </Col>
              <Col>
                <Dropdown className={showFiterClass} title="Brand" selectedId={this.state.brandValue} onChange={this.setBrandValue.bind(this)} options={brandsForDropdown} />
              </Col>
              <Col>
                <Dropdown className={showFiterClass} title="Sort by" selectedId={this.state.sort} onChange={this.setSortValue.bind(this)} options={[{ id: 1, name: 'Lowest price' }, { id: 2, name: 'Highest price' }]} />
              </Col>
            </Row> }
          </Col>
          {this.props.isMobile ? 
          <Col className='my-auto text-right' xs={2}>
            <Filters onClick={this.setShowMobileFilters.bind(this)} />
          </Col> : 
          <Col md={2} style={{ textAlign: 'right' }}>
            <p
              className={showHideFiltersClass}
              onClick={this.showHideFilters.bind(this)}
              style={{
                fontSize: "18px",
                fontFamily: "Montserrat",
                fontWeight: 500,
                color: colors.lightBlue,
                marginTop: '88.5px',
                cursor: 'pointer',
                marginRight: '6px'
              }}
            >{this.state.showFilters ? 'Close filters' : 'Filter & sort'}</p>
          </Col> }
        </Row>
        {this.props.isMobile ? <></> : 
        <Row>
          <Col md={{ span: 8, offset: 2 }}>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
                textAlign: 'justify',
              }}
              dangerouslySetInnerHTML={{ __html: this.state.typeDescription }}
            >
            </p>
          </Col>
            </Row> }
        <Row style={{ marginBottom: "-40px" }}>
          <Col md={2}></Col>
          <Col>
            <Row>
              {this.sortedProducts().map((product, id) => {
                return (
                  <ProductOnList
                    isMobile={this.props.isMobile}
                    product={product}
                    key={id}
                    name={product.name}
                    image={product.small_image}
                    price={product.price}
                    productId={id}
                  />
                );
              })}
            </Row>
          </Col>
          <Col md={2}></Col>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isMobile: state.isMobile, // (1)
    cart: state.cart,
    user: state.user,
    currentCategory: state.currentCategory,
    searchValue: state.searchValue,
    currentType: state.currentType,
  };
};
const mapDispatchToProps = {
  isMobileFetched,
  cartFetched,
  userFetched,
  currentCategoryFetched,
  currentTypeFetched,
  searchValueFetched,
}; // (2)
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CategoryProductListPage);
