import React from "react";
import { Button, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import colors from "../../colors/colors";
import { connect } from "react-redux";
import { isMobileFetched } from "../../actions";

class CookieBar extends React.Component {
  render() {
    return (
      <div
        style={{
          width: "100vw",
          position: "fixed",
          bottom: "0",
          backgroundColor: colors.white,
          zIndex: 2,
        }}
      >
        <Row>
          <Col md={11} xs={9}>
              <p style={this.props.isMobile ?{textAlign: 'center', padding: "15px", paddingBottom: '0px', marginBottom: '64px', textAlign:'center', paddingLeft:'120px'} : {textAlign: 'center', padding: "15px", paddingBottom: '0px', margin: '0', textAlign:'center', paddingLeft:'120px'}}>I accept 
              <Link style={{color: colors.blue}} to='/terms-and-conditions'> Terms & Conditions</Link>,
              <Link style={{color: colors.blue}} to='/cookies-policy'> Cookies </Link> and 
              <Link style={{color: colors.blue}} to='/privacy-policy'> Privacy Policy</Link>
              </p>
          </Col>
          <Col md={1} xs={3}>
            <Button style={this.props.isMobile ?{margin: '10px', marginTop:'40px',} : {margin: '10px'}} variant="dark" onClick={this.props.setAcceptCookies}>OK</Button>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isMobile: state.isMobile, // (1)
  };
};
const mapDispatchToProps = { isMobileFetched}; // (2)
export default connect(mapStateToProps, mapDispatchToProps)(CookieBar);
