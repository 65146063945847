import React from "react";
import { Row, Col, Image } from "react-bootstrap";
import background from "../img/ourStoryImg.png";
import backgroundTwo from "../img/ourStoryImgTwo.png";
import colors from "../colors/colors";
import Request from "./../api/Request";
import { connect } from "react-redux";
import { isMobileFetched } from "../actions";

class OurStoryPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      slides: [],
    };
  }

  componentDidMount() {
    Request.getSlides((response) => {
      if (response.success) {
        this.setState({ slides: response.slides });
      }
    });
  }

  render() {
    return (
      <div style={this.props.isMobile ?{padding: '0 0px', margin:'-15px', letterSpacing:'0.5px'}:{padding: '0 0px', letterSpacing:'0.5px'}}>
        <Row style={{ marginBottom: "20px" }}>
          <Col md={5} style={{ paddingTop: "150px" }}>
            <h1
              style={this.props.isMobile ?{
                fontSize: "20px",
                color: colors.blue,
                fontFamily: "Raleway",
                fontWeight: 900,
                paddingBottom: "20px",
                textAlign:"center",
                padding: '0 20px',
                marginBottom:'40px',
                marginTop:'-40px',
              }:
              {
                fontSize: "90px",
                color: colors.blue,
                fontFamily: "Raleway",
                fontWeight: 900,
                paddingBottom: "70px",
              }}
            >
              Our story
            </h1>
            <h3
              style={this.props.isMobile ?{
                fontSize: "16px",
                color: colors.blue,
                fontFamily: "Montserrat",
                fontWeight: 700,
                marginTop: '10px',
                marginBottom: '35px',
                padding: '0 20px'

              }:
                {
                fontSize: "24px",
                color: colors.blue,
                fontFamily: "Montserrat",
                fontWeight: 700,
                marginTop: '10px',
                marginBottom: '35px',
              }}
            >
              For Professionals by Professionals
            </h3>
            <p
              style={this.props.isMobile ?{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
                marginBottom: '35px',
                letterSpacing:"0.58px",
                marginRight:"auto",
                marginLeft:"auto",
                padding: '0 20px'
              }:
                {
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
                marginBottom: '35px',
              }}
            >
              The world is changing in front of us. Traditional models of
              products sourcing became less and less effective.
            </p>
            <p
              style={this.props.isMobile ?{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
                marginBottom: '35px',
                padding: '0 20px'
              }:
                {
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
                marginBottom: '35px',
              }}
            >
              We saw it, we predicted it, we adapted to it.
            </p>
            <p
              style={this.props.isMobile ?{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
                marginBottom: '30px',
                padding: '0 20px'
              }:
                {
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
                marginBottom: 0,
              }}
            >
              By mastering this new trend, we leaded our customers to free them
              form traditional B2B approach while maximizing their profit and
              bottom line.
            </p>
          </Col>
          <Col
            className='no-repeat'
            md={7}
            xs={12}
            style={this.props.isMobile ?{
              backgroundImage: "url(" + background + ")",
              backgroundRepeat: 'no-repeat',
              backgroundPosition: '80%',
              backgroundSize:'100%',
              height: '230px',
              width:"100%",
            }:
              {
              backgroundImage: "url(" + background + ")",
              backgroundPosition: 'right',
              flex:'0 0 100%'
            }}
          ></Col>
        </Row>
        <Row>
          <Col>
            <p
              style={this.props.isMobile ?{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
                padding: '0 20px'
              }:
                {
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
                marginBottom:'30px',
                marginTop:'20px'
              }}
            >
              We carefully select products from international manufacturers
              though always focus on long-term partnerships and commitment. We
              strongly concentrate on building up an added value for our
              customers, partners and suppliers. We utilise an amazing
              opportunity to deliver high-quality products to healthcare
              providers and their patients who depend on us to do what’s
              right - always.
            </p>
          </Col>
        </Row>
        <Row>
          <Col md="6" xs="12" className={this.props.isMobile ? "order-1" : ''} style={this.props.isMobile ?{
              display:'none',
            }:
          {
              backgroundImage: "url(" + backgroundTwo + ")",
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              // margin: '15px',
              height:'auto',
              backgroundPositionX:'15px',
              backgroundRepeat:'no-repeat',
            }}>
            
          </Col>
          <Col md="6" xs="12"className={this.props.isMobile ? "order-1" : ''}>
            <h3
              style={this.props.isMobile ?{
                fontSize: "18px",
                color: colors.blue,
                fontFamily: "Montserrat",
                fontWeight: 700,
                marginTop: '30px',
                marginBottom: '35px',
                padding: '0 20px',
              }:
                {
                fontSize: "24px",
                color: colors.blue,
                fontFamily: "Montserrat",
                fontWeight: 700,
                marginTop: '10px',
                marginBottom: '35px',

              }}
            >
              Our values
            </h3>
            <p
              style={this.props.isMobile ?{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
                marginBottom: '35px',
                padding: '0 20px',
              }:
                {
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
                marginBottom: '35px',
              }}
            >
              Our greatest values are honesty, competitive prices,
              trustworthiness, loyalty, fast delivery providing our customers
              the best possible personal service.
            </p>
            <Image src={backgroundTwo} style={this.props.isMobile ?
            { 
              //backgroundImage: "url(" + backgroundTwo + ")",
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              height:'230px',
              width:'100%',}:
              {
                display:'none',
              }
            }/>
            <h3
              style={this.props.isMobile ?{
                fontSize: "18px",
                color: colors.blue,
                fontFamily: "Montserrat",
                fontWeight: 700,
                marginTop: '18px',
                marginBottom: '35px',
                padding: '0 20px',
              }:
                {
                fontSize: "24px",
                color: colors.blue,
                fontFamily: "Montserrat",
                fontWeight: 700,
                marginTop: '10px',
                marginBottom: '35px',
              }}
            >
              Our mission & vision
            </h3>
            <p
              style={this.props.isMobile ? {
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
                marginBottom: '35px',
                padding: '0 20px',
              }:
                {
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
                marginBottom: '35px',
              }}
            >
              Our mission is to deliver original healthcare products from the
              most popular brands worldwide at the best prices in the market.
            </p>
            <p
              style={this.props.isMobile ?{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
                marginBottom: '35px',
                padding: '0 20px',
              }:
                {
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
                marginBottom: '35px',
              }}
            >
              Our vision is to be the preferred healthcare products provider,
              where our drivers are to be able to supply fast, be the price
              leader and have an eminent service level.
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <h3
              style={this.props.isMobile ?{
                fontSize: "18px",
                color: colors.blue,
                fontFamily: "Montserrat",
                fontWeight: 700,
                marginTop: '10px',
                marginBottom: '35px',
                padding: '0 20px',
              }:
                {
                fontSize: "24px",
                color: colors.blue,
                fontFamily: "Montserrat",
                fontWeight: 700,
                marginTop: '40px',
                marginBottom: '35px',
              }}
            >
              Our specialties
            </h3>
            <p
              style={this.props.isMobile ?{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
                padding: '0 20px',
              }:
                {
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              We are masters to consult those facing limited supply
              complications to help customers find alternative sources for their
              medical purchasing. Due to our large volume business and personal
              contacts we build over the years, we obtain very competitive
              prices and we therefore offer significant discount on the products
              - compared to the normal price official distributors offer. All
              products are 100% authentic from original manufactures from within
              the EU and worldwide.
            </p>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isMobile: state.isMobile, // (1)
  };
};
const mapDispatchToProps = { isMobileFetched}; // (2)
export default connect(mapStateToProps, mapDispatchToProps)(OurStoryPage);