import React from "react";
import { Form } from "react-bootstrap";

export default class AccountTypeSelect extends React.Component {
  render() {
    return (
      <Form.Group style={{ marginBottom: "30px" }}>
        <Form.Label
          style={{
            fontSize: "18px",
            fontFamily: "Montserrat",
            fontWeight: 500,
          }}
        >
          {this.props.label}
        </Form.Label>
        <Form.Control
          style={{
            backgroundColor: "#f5f5f5",
            border: 'none',
            borderRight: '10px solid #f5f5f5',
            cursor: 'pointer',
          }}
          value={this.props.value}
          onChange={this.props.onChange}
          id="CountrySelect"
          as="select"
        >
          <option>choose type</option>
          <option>corporate</option>
          <option>private</option>
        </Form.Control>{" "}
      </Form.Group>
    );
  }
}
