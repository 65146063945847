import React from 'react';
import { Button } from 'react-bootstrap';
import colors from '../../colors/colors';

export default class BlueButton extends React.Component{
    render(){
        return(
            <Button
            disabled={this.props.disabled}
            className='ipmbButton'
            onClick={this.props.onClick}
            style={{
              width: this.props.width ? this.props.width : '190px',
              backgroundColor: colors.lightBlue,
              borderColor: colors.lightBlue,
              fontFamily: "Montserrat",
              fontWeight: 900,
              color: colors.white,
              margin: this.props.margin,
              boxShadow: '0px 5px 15px -6px rgba(53, 116, 144, 0.66)',
              transition: '0.5s',
            }}
            type="button"
          >
            {this.props.content}
        </Button>
        )
    }
}