import React from 'react';
import { ReactComponent as Arrow } from "../../svg/arrow_drop.svg";
import { BorderStyle, CheckSquareFill, SquareFill } from 'react-bootstrap-icons';
import colors from '../../colors/colors';

export default class Dropdown extends React.Component {
    constructor(props) {
        super(props);

        if (!window.hideDropdown) {
            window.hideDropdown = [];
          }
      
          window.hideDropdown.push(function () {
            this.setState({ active: false })
          }.bind(this));
          

        this.state = {
            open: false,
            closeTimeout: null
        };
    }

    toggleDropdown() {
        this.setState({
            active: !this.state.active
        });
    }

    handleClick(i) {
        if (i != -1) {
            this.props.onChange(this.props.options[i].id);
        } else {
            this.props.onChange(0);
        }
        // this.toggleDropdown();
    }

    renderOptions() {
        if (!this.props.options) {
            return;
        }

        let options = this.props.options.map((option, i) => {
            let amIChecked = (this.props.selectedId && (Array.isArray(this.props.selectedId) && this.props.selectedId.includes(this.props.options[i].id) || this.props.options[i].id === this.props.selectedId));
            return (
                <li
                    style={{ fontFamily: "Montserrat", }}
                    onClick={evt => this.handleClick(i)}
                    key={i}
                    className={"dropdown__list-item " +( amIChecked )}
                >
                    {option.name}
                    {   this.props.title == "Sort by" ? <></> :
                        amIChecked ? <CheckSquareFill color={colors.pink} style={{float:'right'}}/>
                        : <SquareFill color="#EAEAEA" style={{borderRadius:'3px', float:'right'}}/>
                    }
                    
                </li>
            );
        });
        if (this.props.selectedId != 0) {
            return [<li 
                style={{ fontFamily: "Montserrat", }}
                onClick={evt => this.handleClick(-1)}
                key={-1}
                className={"dropdown__list-item"}
            >
                Clear {this.props.title}
            </li>].concat(options);
        }
        return options;
    }

    getName() {
        let label = '';
        this.props.options.forEach(option => {
            if (Array.isArray(this.props.selectedId) && this.props.selectedId.includes(option.id) || this.props.selectedId == option.id) {
                label = option.name;
            }
        })
        return label;
    }

    render() {
        return (
            <div 
            
            onMouseEnter={() => {
                this.setState({ closeTimeout: clearTimeout(this.state.closeTimeout) });
              }}
            onMouseLeave={() => {
                this.setState({ closeTimeout: setTimeout( () => {for(let i = 0; i < window.hideDropdown.length; i++) {
                    window.hideDropdown[i]();
                  }}, 300) });
              }}

            className={"dropdown " + this.props.className} style={this.props.isMobile ? {
                overflow: 'scroll',
                maxHeight: '200px',
                height: this.state.active ? '150px' : 'inherit'
            } :{
                // position: 'relative',
                //   max-width: 300px;
            }}>
                <div
                    onClick={() => { for(let i = 0; i < window.hideDropdown.length; i++) {
                           window.hideDropdown[i]();
                         }
                         this.toggleDropdown();
                        }}
                    className="dropdown__toggle dropdown__list-item"
                    style={{
                        borderRadius: '5px',
                        fontFamily: "Montserrat",
                        marginBottom: this.props.isMobile ? this.state.active ? '0px' : '20px' : '0px'
                    }}
                >
                    {this.props.selectedId == 0 ? this.props.title : this.getName()}
                    <Arrow
                        style={{
                            transform: this.state.active ? 'rotate(180deg)' : 'none'
                        }}
                        className="float-right" />
                </div>
                <ul
                    style={{
                        borderRadius: '5px',
                        display: this.state.active ? 'block' : 'none',
                        position: 'absolute',
                        zIndex: 1,
                        maxHeight: '250px',
                        overflowY: 'scroll',
                        width: '100%',
                    }}
                    className={"dropdown__list " + (this.state.active ? 'dropdown__list--active' : '')}>{this.renderOptions()}</ul>
            </div >
        );
    }
}


