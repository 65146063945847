import React from "react";
import colors from "../../colors/colors";
import { Row, Col } from "react-bootstrap";
import UniversalInput from "./UniversalInput";

export default class BillingDetail extends React.Component {
  render() {
    return (
      <Row>
        <Col>
          <h3
            style={{
              fontSize: "24px",
              color: colors.blue,
              fontFamily: "Raleway",
              fontWeight: 700,
              marginTop: "50px",
              marginBottom: "35px",
            }}
          >
            Blling Details
          </h3>
          <UniversalInput 
          label='Company Name' 
          value={this.props.invoiceCompanyName} onChange={this.props.handleInvoiceCompanyNameChange} 
          isError={this.props.hasBeenSubmited && (this.props.invoiceCompanyName == null || this.props.invoiceCompanyName.length < 2)}
          errorMessage='This field cannot be empty'
          />
          <UniversalInput 
          label='VAT/Company number' 
          value={this.props.invoiceVatNumber} onChange={this.props.handleInvoiceVatNumberChange}
          isError={this.props.hasBeenSubmited && (this.props.invoiceVatNumber == null || this.props.invoiceVatNumber.length < 2)}
          errorMessage='This field cannot be empty'
          />
          <UniversalInput 
          label='Country' 
          value={this.props.invoiceCountry} onChange={this.props.handleInvoiceCountryChange}
          isError={this.props.hasBeenSubmited && (this.props.invoiceCountry == null || this.props.invoiceCountry.length < 2)}
          errorMessage='This field cannot be empty'
          />
          <UniversalInput
          label='Billing address' 
          value={this.props.invoiceBillingAddress} onChange={this.props.handleInvoiceBillingAddressChange}
          isError={this.props.hasBeenSubmited && (this.props.invoiceBillingAddress == null || this.props.invoiceBillingAddress.length < 2)}
          errorMessage='This field cannot be empty'
          />
          <UniversalInput 
          label='Billing address line 2 (optional)' 
          value={this.props.invoiceExtraBillingAddress} onChange={this.props.handleInvoiceExtraBillingAddressChange} 
          />
          <Row>
              <Col md={6} xs={12}><UniversalInput 
              label='Postal Code' 
              value={this.props.invoicePostalCode} onChange={this.props.handleInvoicePostalCodeChange} 
              isError={this.props.hasBeenSubmited && (this.props.invoicePostalCode == null || this.props.invoicePostalCode.length < 2)}
              errorMessage='This field cannot be empty'
              /></Col>
              <Col md={6} xs={12}><UniversalInput 
              label='City' 
              value={this.props.invoiceCity} onChange={this.props.handleInvoiceCityChange} 
              isError={this.props.hasBeenSubmited && (this.props.invoiceCity == null || this.props.invoiceCity.length < 2)}
              errorMessage='This field cannot be empty'
              /></Col>
          </Row>
        </Col>
      </Row>
    );
  }
}
