import React from "react/";
import colors from "../../colors/colors";
import UniversalInput from "../form/UniversalInput";
import PhoneInput from "../form/PhoneInput";
import PasswordInput from "../form/PasswordInput";
import { Row, Col, Form } from "react-bootstrap";
import CountrySelect from "../form/CountrySelect";
import AccountTypeSelect from "../form/AccountTypeSelect";
import ShippingInformation from "../form/ShippingInformation";
import BlueButton from "../general/BlueButton";

export default class CardAccountDetails extends React.Component {
  componentDidMount()
  {
    // console.log(this.props);
  }
  render() {
    return (
      <div style={this.props.isMobile ? {marginTop: '15px'} : {marginRight: '30%', marginTop: '15px'}}>
        {/* Account Information */}
        {this.props.isMobile ?  <h3
          style={{
            fontSize: "20px",
            fontFamily: "Raleway",
            fontWeight: 900,
            marginBottom: '30px',
            color: colors.pink,
          }}
        >
          Account details
        </h3> : <></>}
        <h3
          style={{
            fontSize: "18px",
            fontFamily: "Montserrat",
            fontWeight: 900,
            marginBottom: '30px'
          }}
        >
          Account information
        </h3>
        <AccountTypeSelect value={this.props.accountType} onChange={this.props.handleAccountTypeChange} label='Account type' />
       {/* CORPORATE ACCOUNT */}
        {this.props.accountType == 'corporate' ? 
        <>
        <UniversalInput 
          value={this.props.companyName} 
          onChange={this.props.handleCompanyNameChange} 
          label="Company name" 
          isError={this.props.hasBeenSubmited && (this.props.companyName == null || this.props.companyName.length < 2)}
          errorMessage='This field cannot be empty'
          />
        <Row>
          <Col md={6} xs={12}><UniversalInput value={this.props.name} onChange={this.props.handleNameChange} label="Name (optional)" /></Col>
          <Col md={6} xs={12}><UniversalInput value={this.props.surname} onChange={this.props.handleSurnameChange} label="Surname (optional)" /></Col>
        </Row>
        <UniversalInput 
          value={this.props.companyAddress} 
          onChange={this.props.handleCompanyAddressChange}  
          label="Address"
          isError={this.props.hasBeenSubmited && (this.props.companyAddress == null || this.props.companyAddress.length < 2)}
          errorMessage='This field cannot be empty'
           />
        <UniversalInput 
          value={this.props.companyExtraAddress} 
          onChange={this.props.handleCompanyExtraAddressChange}  
          label="Address line 2 (optional)"
          isError={this.props.hasBeenSubmited && (this.props.companyExtraAddress == null || this.props.companyExtraAddress.length < 2)}
          errorMessage='This field cannot be empty'
           />
        <UniversalInput 
          isEmail={true}
          disabled={true}
          value={this.props.email} 
          onChange={this.props.handleEmailChange} 
          label="E-mail" 
          isError={this.props.hasBeenSubmited && (this.props.email == null || this.props.email.length < 2)}
          errorMessage='This field cannot be empty'
          />
        <PhoneInput 
          value={this.props.phone} 
          onChange={this.props.handlePhoneChange} 
          handleCountryNumberChange={this.props.handleCountryNumberChange}
          countryNumber={this.props.countryNumber}
          label="Phone number" 
          isError={this.props.hasBeenSubmited && (this.props.phone == null || this.props.phone.length < 2 || this.props.countryNumber == null || this.props.countryNumber < 2)}
          errorMessage='This field cannot be empty'
          />
        <Row>
          <Col  md={6} xs={12}>
            <UniversalInput 
              value={this.props.companyPostalCode} 
              onChange={this.props.handleCompanyPostalCodeChange} 
              label="Postal code"
              isError={this.props.hasBeenSubmited && (this.props.companyPostalCode == null || this.props.companyPostalCode.length < 2)}
              errorMessage='This field cannot be empty' 
              />
          </Col>
          <Col  md={6} xs={12}>
            <UniversalInput 
              value={this.props.companyCity} 
              onChange={this.props.handleCompanyCityChange}  
              label="City"
              isError={this.props.hasBeenSubmited && (this.props.companyCity == null || this.props.companyCity.length < 2)}
              errorMessage='This field cannot be empty' 
              />
          </Col>
        </Row>
        <CountrySelect 
          value={this.props.companyCountry} 
          onChange={this.props.handleCompanyCountryChange}  
          label="Country" 
          errorMessage='This field cannot be empty'
          />
        <UniversalInput 
          value={this.props.companyVatNumber} 
          onChange={this.props.handleCompanyVatNumberChange}  
          label="Vat/Company number"
          isError={this.props.hasBeenSubmited && (this.props.companyVatNumber == null || this.props.companyVatNumber.length < 2)}
          errorMessage='This field cannot be empty'
           />
        </>
        : <> </>}

        {/* PRIVATE ACCOUNT */}
        {this.props.accountType == 'private' ? <>
        <UniversalInput 
          value={this.props.name} 
          onChange={this.props.handleNameChange} 
          label="Name" 
          isError={this.props.hasBeenSubmited && (this.props.name == null || this.props.name.length < 2)}
          errorMessage='This field cannot be empty'
          />
        <UniversalInput 
          value={this.props.surname} 
          onChange={this.props.handleSurnameChange} 
          label="Surname"
          isError={this.props.hasBeenSubmited && (this.props.surname == null || this.props.surname.length < 2)}
          errorMessage='This field cannot be empty'
          />
        <UniversalInput 
          isEmail={true}
          disabled={true}
          value={this.props.email} 
          onChange={this.props.handleEmailChange}
          label="E-mail"
          isError={this.props.hasBeenSubmited && (this.props.email == null || this.props.email.length < 2)}
          errorMessage='This field cannot be empty'
          />
        <PhoneInput 
          value={this.props.phone} 
          onChange={this.props.handlePhoneChange} 
          handleCountryNumberChange={this.props.handleCountryNumberChange}
          countryNumber={this.props.countryNumber}
          label="Phone number" 
          isError={this.props.hasBeenSubmited && (this.props.phone == null || this.props.phone.length < 2 || this.props.countryNumber == null || this.props.countryNumber < 2)}
          errorMessage='This field cannot be empty'
          /> </>   : <></> }
        <div style={{ width: "100%", textAlign: "center" }}>
        <BlueButton 
            content='Save'
            onClick={this.props.saveAccount}
            margin= {this.props.isMobile ? '0px auto 25px' : "25px auto"}
          />
          {this.props.hasBeenSubmited? <p style={{
        fontSize: '10px',
         color: colors.darkPink, 
         width: '100%', 
         marginBottom: '4px',
         marginTop: '-16px',
         }}>Please check your data</p> : <></>}
        </div>
        {/* Change Password */}
        <h3
          style={{
            fontSize: "18px",
            fontFamily: "Montserrat",
            fontWeight: 900,
            marginBottom: '30px'
          }}
        >
          Change password
        </h3>
        <PasswordInput type={this.props.showCurrentPassword} eyeClick={this.props.eyeCurrentClick} value={this.props.currentPassword} onChange={this.props.handleCurrentPasswordChange} label="Current password" />
        <PasswordInput 
          type={this.props.showNewPassword} 
          eyeClick={this.props.eyeNewClick} 
          value={this.props.newPassword} 
          onChange={this.props.handleNewPasswordChange}  
          label="New password" 
          isError={this.props.changedPassword ? (this.props.validatePasswords && this.props.hasBeenSubmited && (this.props.newPassword == null || this.props.newPassword.length < 2)): false}
          errorMessage='This field cannot be empty'
          />
        <PasswordInput 
          type={this.props.showRepeatNewPassword} 
          eyeClick={this.props.eyeRepeatNewClick} 
          value={this.props.repeatNewPassword} 
          onChange={this.props.handleRepeatNewPasswordChange}  
          label="Repeat new password" 
          isError={this.props.changedPassword ? (this.props.newPassword !== this.props.repeatNewPassword && this.props.hasBeenSubmited && (this.props.repeatnewPassword == null || this.props.repeatNewPassword.length < 2)): false}
          errorMessage='Does not match'
          />
        <div style={{ width: "100%", textAlign: "center" }}>
          <BlueButton 
            content='Save'
            onClick={this.props.saveAccount}
            margin= {this.props.isMobile ? '0px auto 25px' : "25px auto"}
          />
          {this.props.hasBeenSubmited? <p style={{
        fontSize: '10px',
         color: colors.darkPink, 
         width: '100%', 
         marginBottom: '4px',
         marginTop: '-16px',
         }}>Please check your data</p> : <></>}
        </div>
     
        <Form.Check
          type="checkbox"
          label="Default invoice settings"
          style={{
            fontSize: "16px",
            fontFamily: "Montserrat",
            fontWeight: 500,
            marginTop: "30px",
            marginBottom: '50px'
          }}
        />
        {/* Shipping information */}
        <h3
          style={{
            fontSize: "18px",
            fontFamily: "Montserrat",
            fontWeight: 900,
            marginBottom: '30px'
          }}
        >
          Shipping information
        </h3>
        {/* <ShippingInformation /> */}
        {this.props.shippingInformation.map((address) => {
          return(
            <ShippingInformation 
              isMobile={this.props.isMobile}
              accountType={this.props.accountType} 
              address={address} saveSingleAddress={this.props.saveSingleAddress} 
              key={address.id} id={address.id}
              hasBeenSubmited={this.props.hasBeenSubmited} 
              deleteShippingInfo={this.props.deleteShippingInfo}
              />
              )
            })}
            <Row>
              <Col md={6} xs={12} style={this.props.isMobile ? {textAlign: 'center'} : {textAlign: 'right'}}>
              <BlueButton 
                content='Save'
                onClick={this.props.saveAccount}
                margin= {this.props.isMobile ? '0px auto 25px' : "25px auto"}
              />
                {this.props.hasBeenSubmited? <p style={{
                  fontSize: '10px',
                  color: colors.darkPink, 
                  width: '100%', 
                  marginBottom: '4px',
                  marginTop: '-16px',
                  paddingRight: '13%',
                }}>
              Please check your data</p> : <></>}
              </Col>
              <Col  md={6} xs={12} style={this.props.isMobile ? {textAlign: 'center'} : {textAlign: 'left' }}>
              <BlueButton 
                content={this.props.isMobile ? 'Add new address' : 'Add'}
                onClick={this.props.addShippingInformation}
                margin= {this.props.isMobile ? '0px auto 25px' : "25px auto"}
              />
              </Col>
            </Row>
            <Row style={this.props.isMobile ? {marginBottom: '100px', marginLeft: '0px'} : {marginLeft:'0',}}>
            <h3
              onClick={this.props.deleteAccount}
              style={{
                fontSize: "18px",
                fontFamily: "Montserrat",
                fontWeight: 500,
                marginBottom: '15px',
                color: colors.pink,
                marginTop: '30px',
                cursor: 'pointer'
              }}
            >
              Delete account
            </h3>
            </Row>
      </div>
    );
  }
}
