import React from "react";
import { Row, Col } from "react-bootstrap";
import colors from "../colors/colors";
import { connect } from "react-redux";
import { isMobileFetched } from "../actions";

class FailedPaymentPage extends React.Component {
  render() {
    return (
      <div>
        <Row style={{ marginBottom: "0px" }}>
          <Col style={this.props.isMobile ?{ paddingTop: "100px" }:{ paddingTop: "230px" }}>
            <h1
              style={this.props.isMobile ?{
                fontSize: "26px",
                color: colors.darkPink,
                fontFamily: "Raleway",
                fontWeight: 900,
                paddingBottom: "10px",
                textAlign:'center',
              }:
                {
                fontSize: "90px",
                color: colors.darkPink,
                fontFamily: "Raleway",
                fontWeight: 900,
                paddingBottom: "50px",
              }}
            >
              Payment error
            </h1>
          </Col>
        </Row>
        <Row>
          <Col md={2}></Col>
          <Col>
            <h3
              style={{
                fontSize: "18px",
                color: colors.darkPink,
                fontFamily: "Montserrat",
                fontWeight: 700,
                marginTop: "10px",
                marginBottom: "20px",
              }}
            >
              Your payment has failed
            </h3>
            <p
              style={{
                fontSize: "18px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
                letterSpacing:'0.5px',
              }}
            >
               Try again later.
            </p>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isMobile: state.isMobile, // (1)
  };
};
const mapDispatchToProps = { isMobileFetched }; // (2)
export default connect(mapStateToProps, mapDispatchToProps)(FailedPaymentPage);