import React from "react";
import { Row, Col, Form, InputGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import colors from "../../colors/colors";
import SearchScreen from './SearchScreen';
import { connect } from "react-redux";
import { isMobileFetched, currentCategoryFetched, cartFetched, searchValueFetched, whiteHeaderFetched, currentTypeFetched, cartPageFetched, localeFetched, showCurrencyPopupFetched, showNewsletterPopupFetched, homePageFetched } from "../../actions";
import Request from '../../api/Request';
import { ReactComponent as Cart } from "../../svg/cart.svg";
import { ReactComponent as Search } from "../../svg/search.svg";
import { ReactComponent as User } from "../../svg/user.svg";
import { ReactComponent as CartWhite } from "../../svg/cartWhite.svg";
import { ReactComponent as SearchWhite } from "../../svg/searchWhite.svg";
import { ReactComponent as UserWhite } from "../../svg/userWhite.svg";
import { ReactComponent as CartPink } from "../../svg/cartPink.svg";
import { ReactComponent as UserPink } from "../../svg/userPink.svg";
import SubMenu from "./SubMenu";
import AddedToCartPopup from "../addedToCart/AddedToCartPopup";
import Icon from "../Icon";
import { X } from "react-bootstrap-icons";
import { withRouter } from "react-router-dom";
import DocumentMeta from 'react-document-meta';

class MainHeader extends React.Component {
  constructor(props) {
    super(props);

    window.closeMenu = () => {
      if (this.state.showSubMenuBG && !this.state.showSearch) {
        for (let i = 0; i < window.hideSubMenu.length; i++) {
          window.hideSubMenu[i]();
        }
        this.hideSubMenuBackgroundClass();
      }
    }

    window.showTopInfo = function () {
      if (this.props.cart.length) {
        this.setState({ showTopInfo: true, closeCartPopupTimeout: setTimeout(window.hideTopInfo, 6000) });
      }
    }.bind(this);

    window.hideTopInfo = function () {
      this.setState({ showTopInfo: false, closeCartPopupTimeout: null });
    }.bind(this);

    window.maciek = function () {
      // console.log(this.state.brands);
    }.bind(this);

    this.state = {
      showSearch: false,
      brands: [],
      showTopInfo: false,
      subMenuBackgroundClass: "subMenuBackground",
      subMenuWiteBg: 'subMenuWiteBg',
      showSubMenuBG: false,
      countries: [],
      searchInputValue: "",
      hints: [],
      searchInputLenght: false,
      hideMenu: false,
      cartTimeout: null,
      closeCartPopupTimeout: null,
      showBrandList: null,
      defaultMeta: {},
    };
  }

  componentDidMount() {
    Request.getCategories(response => {
      if (response.success) {
        this.setState({ brands: response.categories });
      }
    });

    Request.getCountries(response => {
      this.setState({ countries: response });
    });

  }

  getFlag() {
    if (this.state.countries.length) {
      let i = 0;
      for (i; i < this.state.countries.length; i++) {
        if (this.state.countries[i].name == this.props.locale.country) {
          return "https://www.countryflags.io/" + this.state.countries[i].alpha2Code + "/flat/64.png";
        }
      }
    }
  }

  // hideSearchScreen() {
  //   this.setState({ showSearch: false });
  // }

  // showSearchScreen() {
  //   this.setState({ showSearch: true });
  // }

  showSearchScreen() {
    this.setState({
      showSearch: true,
      subMenuBackgroundClass: "subMenuBackground subMenuBackgroundShown",
      subMenuWiteBg: 'subMenuWiteBg subMenuWiteBgShown',
      hideMenu: true,
    });
    setTimeout(() => {
      this.setState({
        searchInputLenght: true, showSubMenuBG: true,
      })
    }, 120);
  }

  hideSearchScreen() {
    this.setState({
      showSubMenuBG: false,
      searchInputLenght: false,
      showSearch: false,
    });

    setTimeout(() => {
      this.setState({
        subMenuBackgroundClass: "subMenuBackground",
        subMenuWiteBg: 'subMenuWiteBg',
        hideMenu: false,
      })
    }, 500);


    setTimeout(() => {
      this.setState({
        searchInputValue: "",
      })
    }, 1000);

  }

  setCategory(id) {
    this.props.currentCategoryFetched(id);
    this.props.searchValueFetched("");
  }

  setType(type) {
    this.props.currentTypeFetched(type);
    this.props.searchValueFetched("");
  }

  showSubMenuBackgroundClass() {
    this.setState({
      subMenuBackgroundClass: "subMenuBackground subMenuBackgroundShown",
      subMenuWiteBg: 'subMenuWiteBg subMenuWiteBgShown',
      showSubMenuBG: true,
    });
  }

  hideSubMenuBackgroundClass() {
    this.setState({
      subMenuBackgroundClass: "subMenuBackground",
      subMenuWiteBg: 'subMenuWiteBg',
      showSubMenuBG: false,
    });
  }


  handleSearchValueChange(event) {
    const { value } = event.target;
    this.setState({ searchInputValue: value });
    if (value.length > 2) {
      Request.getSearchHints(value, (response) => {
        let hints = response.hints;
        this.setState({ hints: hints });
      });
    }
  }

  setShowBranListNumber(id){
    this.setState({showBrandList: id});
  }

  setShowBranListNull(){
    this.setState({showBrandList: null});
  }

  setDefaultMeta(){
    this.setState({defaultMeta: {
      title: 'Fraya med supply',
      description: 'Fraya med supply webside',
    }})
  }

  render() {
    const icon = {
      height: '23px',
      width: 'auto',
      marginTop: '27px',
      cursor: 'pointer',
      left: 0,
      posiion: 'relative'
    };

    const cartNumber = {
      fontSize: "9px",
      borderRadius: "28px",
      textAlign: "center",
      letterSpacing: 0,
      fontWeight: "bold",
      width: "13px",
      position: "relative",
      top: "-26px",
      left: "15px",
      background: this.props.cartPage ? colors.pink : this.props.white ? colors.white : colors.graphite,
      color: this.props.white ? colors.graphite : colors.white,
      textDecoration: 'none',
    };

    const showTopInfoClass = this.state.showTopInfo
      ? "topInfo"
      : "topInfo  topInfoHidden";

    const showTopInfoClassMobile = this.state.showTopInfo
      ? "topInfoMobile"
      : "topInfoMobile  topInfoHiddenMobile";
    // : 'topInfo';

    const searchIconClass = !this.state.showSearch
      ? 'navIconIPMB searchScreenIcon'
      : 'navIconIPMB searchScreenIcon searchScreenIconLeft';

    const searchIconClassWhite = !this.state.showSearch
      ? 'navIconIPMBWhite searchScreenIconWhite'
      : 'navIconIPMBWhite searchScreenIconWhite searchScreenIconLeftWhite';

    const searchInputlass = !this.state.searchInputLenght
      ? 'searchInput'
      : 'searchInput searchInputActive'

    const meta = this.state.defaultMeta;

    return (
      <DocumentMeta {...meta}>
        <AddedToCartPopup
          isMobile={this.props.isMobile}
          showTopInfoClassMobile={showTopInfoClassMobile}
          showTopInfoClass={showTopInfoClass}
          cart={this.props.cart}
          mouseEnter={() => {
            this.setState({ closeCartPopupTimeout: clearTimeout(this.state.closeCartPopupTimeout) });
          }}
          mouseLeave={() => {
            this.setState({ closeCartPopupTimeout: setTimeout(window.hideTopInfo, 6000) });
          }}
        />
        <div
          className={this.state.subMenuBackgroundClass}
          style={{
            // position: "absolute",
            // height: this.state.showSubMenuBG ? 'max-content' : '0px',
            maxHeight: '90vhx',
            top: "0",
            // width: "100%",
            left: "0",
            // backgroundColor: colors.white,
            // transition: "max-height 1s ease-out",
          }}
        >
          <div
            className={this.state.subMenuWiteBg}
            style={{
              backgroundColor: colors.white,
              height: '0%',
              width: '100%',
              position: 'absolute',
              transition: '0.5s',
              left: 0,
              boxShadow: '0px 3px 8px #35749033',
            }}
          ></div>
          {this.props.isMobile ? <></> : <Row style={{ height: '30px', background: this.props.white ? 'rgba( 255, 255 ,255 ,0.5)' : 'rgba( 46, 46 ,46 ,0.1)', }}>
            <Col md={12}>
              <p
                className="float-right"
                onClick={() => this.props.showCurrencyPopupFetched(true)}
                style={{
                  fontSize: "16px",
                  color: this.state.showSubMenuBG == false && this.props.white ? colors.white : colors.black,
                  fontFamily: "Montserrat",
                  fontWeight: 500,
                  cursor: 'pointer',
                  paddingTop: '3px',
                  display: 'flex',
                }}>
                {this.props.locale.country == null || this.props.locale.country.length < 2 ? 'choose your country and currency' : <>
                  {this.props.locale.country}
                  <div style={{
                    height: '21px',
                    width: '21px',
                    marginRight: '10px',
                    marginLeft: '10px',
                    borderRadius: '50%',
                    background: "url(" + this.getFlag() + ") no-repeat center center",
                    border: '1px solid #CECECE81'
                  }}></div> </>}
              </p>
              {/* <p
                className="float-right"
                onClick={() => this.props.showNewsletterPopupFetched(true)}
                style={{
                  fontSize: "16px",
                  color: colors.black,
                  fontFamily: "Montserrat",
                  fontWeight: 500,
                  cursor: 'pointer',
                  paddingTop: '3px',
                  paddingRight: '20px',
                }}>Sign up for newsletter</p> */}
            </Col>
          </Row>}
          <Row>
            <Col
              md="2"
              xs='12'
              style={this.props.isMobile ? { textAlign: "center", zIndex: 999, boxShadow: this.props.homePage ? 'none' : '0px 3px 8px #35749033', paddingBottom: '10px' } : {}}
            >
              <Link onClick={this.setDefaultMeta.bind(this)} to="/">
                {this.state.showSubMenuBG == false && this.props.white ?
                  <Icon style={this.props.isMobile ? { marginTop: "10px", width: '120px', marginLeft: '-14px', filter: 'invert(80%) sepia(92%) saturate(1011%) hue-rotate(-40deg) brightness(450%) contrast(100%)' } :
                    { marginTop: "10px", width: '160px', marginLeft: '-14px', filter: 'invert(80%) sepia(92%) saturate(1011%) hue-rotate(-40deg) brightness(450%) contrast(100%)' }} />
                  : <Icon
                    style={
                      this.props.isMobile ? { marginTop: "10px", width: '120px', marginLeft: '-14px' } :
                        { marginTop: "10px", width: '160px', marginLeft: '-14px' }}
                  />
                }
              </Link>
            </Col>
            {this.state.hideMenu ?
              <Col md={8}>
                <form onSubmit={() => {
                  return false;
                }}>
                  <Form.Group controlId="search">
                    <InputGroup
                      className={searchInputlass}
                      style={{
                        width: "0vw",
                        marginLeft: "auto",
                        marginRight: '0px',
                        top: "26px",
                        transition: '1s',
                      }}
                    >
                      <Form.Control
                        type="text"
                        value={this.state.searchInputValue}
                        onChange={this.handleSearchValueChange.bind(this)}
                      />
                    </InputGroup>
                    <div
                      className="tips"
                      style={{
                        color: colors.white,
                        width: "70vw",
                        margin: "auto",
                        textAlign: "left",
                        marginTop: "30px",
                        fontSize: "18px",
                      }}
                    >
                      {
                        this.state.hints.map((hint) => (
                          <span
                            className='searchHint'
                            onClick={() => {
                                window.location.href = '/product/' + hint.uri;
                            }} style={{ color: colors.graphite, cursor: 'pointer', position: 'relative', display: 'block', width: 'max-content' }}>{hint.name}</span>
                        ))
                      }
                    </div>
                  </Form.Group>
                </form>
              </Col> :
              <Col
                style={
                  this.props.isMobile ? { display: "none" } : { marginTop: "25px", marginLeft: '15px' }
                }
              >
                <Row style={{ display: 'block' }}>
                  {this.state.brands.filter((category) => category.name != 'Enhance' && category.name != 'Intraline' && category.name != 'Man').map((category, id) => (
                    <SubMenu
                      id={id}
                      key={id}
                      showSubMenuBG={this.state.showSubMenuBG}
                      white={this.props.white}
                      showMenuBackgroundClass={() =>
                        this.showSubMenuBackgroundClass()
                      }
                      hideMenuBackgroundClass={() =>
                        this.hideSubMenuBackgroundClass()
                      }
                      setCategory={this.setCategory.bind(this)}
                      setType={this.setType.bind(this)}
                      category={category}
                      currentCategory={this.props.currentCategory}
                      currentType={this.props.currentType}
                      showBrandList={this.state.showBrandList}
                      setShowBranListNumber={()=> this.setShowBranListNumber(id)}
                      setShowBranListNull={this.setShowBranListNull.bind(this)}
                      setDefaultMeta={this.setDefaultMeta.bind(this)}
                    />
                  ))}
                </Row>
              </Col>}

            <Col
              md="2"
              style={
                this.props.isMobile ? { display: "none" } : { minWidth: "210px" }
              }
            >
              <Row style={{ marginLeft: "30px" }}>
                <Col>
                  {this.state.showSubMenuBG == false && this.props.white ? <SearchWhite
                    className={searchIconClassWhite}
                    onClick={this.showSearchScreen.bind(this)}
                    style={icon}
                  /> :
                    <Search
                      className={searchIconClass}
                      onClick={this.showSearchScreen.bind(this)}
                      style={icon}
                    />}
                  {this.state.showSearch ?
                    <X
                      className='navIconIPMB'
                      onClick={this.hideSearchScreen.bind(this)}
                      color={colors.graphite}
                      size={45}
                      style={{
                        position: "absolute",
                        top: "14px",
                        right: "10%",
                        cursor: "pointer",
                      }}
                    /> : <></>}
                </Col>
                <Col>
                  <Link style={{ textDecoration: 'none' }} className="cartNumber" to="/cart"
                    onMouseEnter={() => this.setState({ cartTimeout: setTimeout(window.showTopInfo, 200) })}
                    onMouseLeave={() => this.setState({ cartTimeout: clearTimeout(this.state.cartTimeout) })}
                  >
                    {this.state.showSubMenuBG == false && this.props.white ? <CartWhite className='navIconIPMBWhite' style={icon} /> : this.props.cartPage ? <CartPink style={icon} /> : <Cart className='navIconIPMB' style={icon} />}
                    {this.props.cartLength > 0 ? (
                      <p style={cartNumber}>{this.props.cart.length}</p>
                    ) : (
                        <></>
                      )}
                    {this.props.cart.length > 0 ? <p className="cartNumberIpmb" style={cartNumber}>{this.props.cart.length}</p> : <></>}
                  </Link>
                </Col>
                <Col>
                  {/* <Link to="/account"> */}
                  <Link to="/login">
                    {this.state.showSubMenuBG == false && this.props.white ? <UserWhite className='navIconIPMBWhite' style={icon} /> :
                      this.props.userPage ? <UserPink style={icon} /> :
                        <User className='navIconIPMB' style={icon} />}
                  </Link>
                </Col>
              </Row>
            </Col>
            {/* <SearchScreen
              showSearch={this.state.showSearch}
              hide={this.hideSearchScreen.bind(this)} /> */}
          </Row>
        </div>
      </DocumentMeta >
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isMobile: state.isMobile, // (1)
    currentCategory: state.currentCategory,
    cart: state.cart,
    white: state.whiteHeader,
    currentType: state.currentType,
    cartPage: state.cartPage,
    userPage: state.userPage,
    locale: state.locale,
    showNewsletterPopup: state.showNewsletterPopup,
    showCurrencyPopup: state.showCurrencyPopup,
    homePage: state.homePage,
  };
};
const mapDispatchToProps = { isMobileFetched, currentCategoryFetched, cartFetched, searchValueFetched, whiteHeaderFetched, currentTypeFetched, cartPageFetched, localeFetched, showCurrencyPopupFetched, showNewsletterPopupFetched, homePageFetched }; // (2)
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MainHeader));
