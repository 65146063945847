import React from "react";
import { Row, Col } from "react-bootstrap";
import colors from "../../colors/colors";
import { connect } from "react-redux";
import { historicalOrderFetched } from "../../actions";
import { Link } from "react-router-dom";
import BlueButton from "../general/BlueButton";
import Request from "../../api/Request";

class CardOrderHistory extends React.Component {
  render() {

    const status = [
      'Pay now',
      'Payment Confirmed',
      'In Preparation',
      'Completed',
    ]

    return (
      <div style={this.props.isMobile ? {} : { marginBottom: '-40px' }}>
        {this.props.isMobile ? <h3
          style={{
            fontSize: "20px",
            fontFamily: "Raleway",
            fontWeight: 900,
            marginBottom: '30px',
            color: colors.pink,
          }}
        >
          Order history
        </h3> : <></>}
        {this.props.orders && this.props.orders.length ? this.props.orders.map((order) => {
          return (
            <Row style={this.props.isMobile ? { marginBottom: '20px' } : { marginBottom: '40px', marginTop: '10px' }}>
              <Col md={3} xs={5} style={this.props.isMobile ? { paddingRight: 0 } : {}}>
                <p
                  style={{
                    fontSize: "16px",
                    color: colors.blue,
                    fontFamily: "Montserrat",
                    fontWeight: this.props.isMobile ? 600 : 600,
                  }}
                >
                  {order.number}
                </p>
              </Col>
              <Col md={3} xs={4}>
                <p
                  style={{
                    fontSize: "16px",
                    color: colors.grapihite,
                    fontFamily: "Montserrat",
                    fontWeight: this.props.isMobile ? 600 : 'inherit',
                  }}
                >
                  {order.date}
                </p>
              </Col>
              <Col md={2} xs={3} style={{ textAlign: this.props.isMobile ? 'right' : 'inherit' }}>
                <Link style={{ textDecoration: 'none' }} to='/history'><p
                  id='viewButton'
                  onClick={() => this.props.historicalOrderFetched(order)}
                  style={{
                    fontSize: "16px",
                    color: colors.blue,
                    fontFamily: "Montserrat",
                    fontWeight: 900,
                    cursor: 'pointer',
                    textDecoration: 'none'
                  }}
                >View</p></Link>
              </Col>
              {this.props.isMobile ? <></> :
                <Col md={2} style={{padding: 0}}>
                  {order.status == 1 ?
                    <BlueButton
                      onClick={() => {
                        Request.getPaymentButton(order.id, response => {
                          if (response.url) {
                            window.location.href = response.url;
                          }
                        })
                      }}
                      content={status[0]}
                      margin="-10px calc(50% - 95px) 0px"
                    />
                    :
                    <p style={{
                      fontSize: "16px",
                      color: colors.grapihite,
                      fontFamily: "Montserrat",
                      fontWeight: this.props.isMobile ? 600 : 'inherit',
                      width: 'max-content',
                    }}>
                      {status[order.status - 1]}
                    </p>}
                </Col>}
            </Row>
          );
        }) : <></>}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    historicalOrder: state.historicalOrder, // (1)
  };
};
const mapDispatchToProps = { historicalOrderFetched }; // (2)
export default connect(mapStateToProps, mapDispatchToProps)(CardOrderHistory);
