import React from "react";
import { Row, Col } from "react-bootstrap";
import colors from "../colors/colors";
import ContactForm from "../components/form/ContacForm";
import { connect } from "react-redux";
import { isMobileFetched } from "../actions";

class ContactPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nameInputValue: '',
      emailInputValue: '',
      textInputValue: '',
      acceptTerms: false,
    }
  }

  handleNameInputVaueChange(event){
    const {value} = event.target;
    this.setState({nameInputValue: value})
  }

  handleEmailInputVaueChange(event){
    const {value} = event.target;
    this.setState({emailInputValue: value})
  }

  handleTextInputVaueChange(event){
    const {value} = event.target;
    this.setState({textInputValue: value})
  }

  temsCheckClick(){
    this.setState({acceptTerms: !this.state.acceptTerms})
  }


  render() {
    return (
        <div style={this.props.isMobile ? {letterSpacing:'0.5px', marginBottom: '100px'} : {letterSpacing:'0.5px',}}>
      <Row style={this.props.isMobile ? {paddingTop: '80px'} : {paddingTop: '150px'}}>
        <Col>
        <h1
          style={
            this.props.isMobile ? {
            fontSize: "20px",
            color: '#357490',
            fontFamily: "Raleway",
            fontWeight: 900,
            paddingBottom: "20px",
            textAlign: "center",
            opacity:"1",           
          } :
            {
            fontSize: "90px",
            color: colors.blue,
            fontFamily: "Raleway",
            fontWeight: 900,
            paddingBottom: "50px",
          }}
        >
          Contact
        </h1>
        </Col>
      </Row>
      <Row>
          <Col md={2}></Col>
          <Col md={5}><ContactForm
            name={this.state.nameInputValue}
            email={this.state.emailInputValue}
            text={this.state.textInputValue}
            acceptTerms={this.state.acceptTerms}
            handleNameChange={this.handleNameInputVaueChange.bind(this)}
            handleEmailChange={this.handleEmailInputVaueChange.bind(this)}
            handleTextChange={this.handleTextInputVaueChange.bind(this)}
            temsCheckClick={this.temsCheckClick.bind(this)}
          /></Col>
          <Col style={{textAlign: this.props.isMobile ? 'center' : 'right'}}>
          <h4
              style={
                this.props.isMobile ?{
                fontSize: "18px",
                color: colors.blue,
                fontFamily: "Montserrat",
                fontWeight: 700,
                textAlign:"center",
                paddingBottom:"2px",
                paddingTop:"10px",
                letterSpacing:"1px",
                }:
                {
                fontSize: "18px",
                color: colors.blue,
                fontFamily: "Raleway",
                fontWeight: 900,
                marginRight: '40px',
              }}
            >
              Get in touch with us
            </h4>
            <p
              style={
              this.props.isMobile ?{
              fontSize: "16px",
              color: colors.graphite,
              fontFamily: "Montserrat",
              fontWeight: 600,
              marginTop: '30px',
              marginBottom: '4px',
              textAlign:"center",
              opacity:"1",
              }:    
                {
                fontSize: "18px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 600,
                marginTop: '20px',
                marginBottom: '0',
                marginRight: '60px'
              }}
            >
              +48 730 205 444
            </p>
            <a href="mailto:fraya@frayagroup.com"
              target="_blank"
              style={this.props.isMobile ?{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 600,
                textAlign:"center",
              }:
                {
                fontSize: "18px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 600,
                marginRight: '10px',
                textDecoration: 'none',
              }}
            >
              fraya@frayagroup.com
            </a>
          </Col>
          <Col md={1}></Col>
      </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isMobile: state.isMobile, // (1)
  };
};
const mapDispatchToProps = { isMobileFetched}; // (2)
export default connect(mapStateToProps, mapDispatchToProps)(ContactPage);
