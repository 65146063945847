import React from "react";
import { Link } from "react-router-dom";
import { Row, Col, Image } from "react-bootstrap";
import colors from "../../colors/colors";
import Request from "../../api/Request";
import Price from "../general/Price";
import { connect } from "react-redux";
import { isMobileFetched, productToShowFetched } from "../../actions";

class ProductOnList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      quantity: 0,
    };
  }

  setProductToShow(id) {
    this.props.productToShowFetched(id);
  }

  render() {
    const paragraph = {
      fontSize: "14px",
      fontWeight: "600",
      fontFamily: "Montserrat",
      textDecoration: "none",
      color: colors.graphite,
      position: "relative",
      dispaly: "block",
    };

    const paragraphMobile = {
      fontSize: "12px",
      fontWeight: "600",
      fontFamily: "Montserrat",
      textDecoration: "none",
      color: colors.graphite,
    };

    let words = this.props.name.split(" ");
    let firstLane = "";
    let secondLane = "\u00A0";
    if (words.length > 3) {
      firstLane = words.splice(0, 3).join(" ");
      secondLane = words.join(" ");
    } else {
      firstLane = words.join(" ");
    }

    return (
      <Col md="4" xs="6" style={this.props.isMobile ? {} : { padding: "30px" }}>
        <Link
          className="productOnLisst"
          style={{ textDecoration: "none" }}
          to={"/product/" + this.props.product.uri}
          onClick={() => this.setProductToShow(this.props.product)}
        >
          <Row style={{ height: this.props.isMobile ? "230px" : "290px" }}>
            <Col
              className="mt-auto"
              style={{
                backgroundImage:
                  "url('" + Request.realProductImage(this.props.image) + "')",
                backgroundSize: "contain",
                backgroundPosition: "center",
                height: "100%",
                width: "100%",
                backgroundRepeat: "no-repeat",
              }}
            ></Col>
          </Row>
          <Row>
            <Col style={this.props.isMobile ? { textAlign: "center" } : {}}>
              <span style={this.props.isMobile ? paragraphMobile : paragraph}>
                {firstLane}
              </span>
              <br />
              <span style={this.props.isMobile ? paragraphMobile : paragraph}>
                {secondLane}
              </span>
            </Col>
            <Col
              xs={12}
              md={2}
              style={
                this.props.isMobile ? { textAlign: "center" } : { padding: 0 }
              }
            >
              <Row><Col>
              <p
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                  fontFamily: "Montserrat",
                  textDecoration: "none",
                  color: colors.graphite,
                  position: "relative",
                  dispaly: "block",
                  marginBottom: '6px',
                }}
              >
                {this.props.product.listing_capacity}
                
              </p>
              </Col></Row>
              <Row><Col>
              <p style={paragraph}>
                <Price price={this.props.price} />
              </p>
              </Col></Row>
            </Col>
          </Row>
        </Link>
      </Col>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isMobile: state.isMobile, // (1)
    productToShow: state.productToShow,
  };
};
const mapDispatchToProps = { isMobileFetched, productToShowFetched }; // (2)
export default connect(mapStateToProps, mapDispatchToProps)(ProductOnList);
