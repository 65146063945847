import React from "react";
import {Row, Col } from "react-bootstrap";
import colors from "../colors/colors";
import ProductOnList from "../components/productList/ProductOnList";
import { connect } from "react-redux";
import {
  isMobileFetched,
  cartFetched,
  userFetched,
  currentCategoryFetched,
  searchValueFetched,
  currentTypeFetched,
} from "../actions";
import Request from "../api/Request";

class MensProductListPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showTopInfo: false,
      showMobileInfo: false,
      timeOut: null,
      addedProductName: "",
      addedQuantity: "",
      cartSum: 0,
      products: [],
      purpose: 0,
      purposeValue: 0,
    };
  }

  componentDidMount() {
    this.setState({ user: this.props.user });
    Request.getProducts((response) => {
      if (response.success) {
        this.setState({ products: response.products });
      }
    });
  }

  removeDuplicates(myArr, prop) {
    return myArr.filter((obj, pos, arr) => {
        return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos
    })
}

  render() {
    return (
      <>
        <Row style={{ paddingTop: "150px" }}>
          <Col md={2}></Col>
          <Col>
            <h3
              style={this.props.isMobile ?{
                fontSize: "24px",
                color: colors.blue,
                fontFamily: "Raleway",
                fontWeight: 900,
                marginTop: "-50px",
                marginBottom: "25px",
                textAlign:'center',
              }:
              {
                fontSize: "24px",
                color: colors.blue,
                fontFamily: "Raleway",
                fontWeight: 900,
                marginTop: "10px",
                marginBottom: "20px",
              }}
            >
              Men’s range
            </h3>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              Dedicated men’s dermal filler range is a variety of products which
              are specifically formulated at a molecular level to provide some
              of the smoothest, most natural results available today.
              </p>
              <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
                textAlign: 'justify'
              }}
            >
              Over the last decade, men’s interest in aesthetic medicine procedures has
              increased significantly. In light of the fact that men’s facial
              skin is thicker with less subcutaneous fat, products of this range
              contain <strong>the highest concentration of hyaluronic acid</strong> (up to 28
              mg/ml). It helps to preserve a masculine facial structure and
              eventually provides a healthier and happier look as well as
              significant increase in confidence, regardless of gender.
            </p>
          </Col>
          <Col md={2}></Col>
        </Row>
        <Row style={{ marginBottom: "-40px" }}>
          <Col md={2}></Col>
          <Col style={this.props.isMobile ?{marginBottom:'70px',}:{}}>
            <Row>
              {
              this.removeDuplicates(
              this.state.products
                .filter(
                  (product) =>
                    product.is_for_man == 1
                ), 'id' )
                .map((product, id) => {
                  return (
                    <ProductOnList
                      product={product}
                      key={id}
                      name={product.name}
                      image={product.small_image}
                      altTag={product.alt_tag}
                      price={product.price}
                      productId={product.id}
                    />
                  );
                })}
            </Row>
          </Col>
          <Col md={2}></Col>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isMobile: state.isMobile, // (1)
    cart: state.cart,
    user: state.user,
    currentCategory: state.currentCategory,
    searchValue: state.searchValue,
    currentType: state.currentType,
  };
};
const mapDispatchToProps = {
  isMobileFetched,
  cartFetched,
  userFetched,
  currentCategoryFetched,
  currentTypeFetched,
  searchValueFetched,
}; // (2)
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MensProductListPage);
