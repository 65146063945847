import React from "react";
import colors from "../../colors/colors";
import { connect } from "react-redux";
import {
  isMobileFetched,
  localeFetched,
  showNewsletterPopupFetched,
} from "../../actions";
import { X, Cursor } from "react-bootstrap-icons";
import UniversalInput from "../form/UniversalInput";
import { Row, Col } from "react-bootstrap";
import Request from "../../api/Request";

class NewsletterPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      emailValue: "",
      signInMessage: false,
      error: false,
    };
  }

  componentDidMount() { }

  hendleEmialChange(event) {
    const { value } = event.target;
    this.setState({ emailValue: value });
  }

  closeNewsletterPopup() {
    this.props.showNewsletterPopupFetched(false);
  }

  signUp(event) {
    event.preventDefault();
    if (this.state.emailValue.length > 2) {

      Request.newsletter(this.state.emailValue, response => {
        if (response.success) {
          this.setState({ signInMessage: true });
          setTimeout(() => this.closeNewsletterPopup(), 5000);
        } else {
          this.setState({ error: true })
        }
      });

    } else {
      this.setState({ error: true })
    }
  }

  render() {
    return (
      <div
        style={{
          width: "100vw",
          height: "100vh",
          position: "fixed",
          top: 0,
          left: 0,
          backgroundColor: "rgba(255, 255, 255, 0.5)",
          zIndex: 999999999,
        }}
      >
        <div
          style={{
            backgroundColor: colors.white,
            width: "500px",
            height: "190px",
            position: "absolute",
            top: "calc( ( 100vh - 190px ) / 2 )",
            left: "calc( ( 100vw - 500px ) / 2 )",
            textAlign: "center",
            padding: "30px",
            borderRadius: "5px",
            zIndex: 999999999,
          }}
        >
          <h3
            style={{
              fontSize: "16px",
              fontWeight: "700",
              fontFamily: "Montserrat",
              textDecoration: "none",
              color: colors.graphite,
              marginBottom: "20px",
            }}
          >
            Sign up for newsletter
          </h3>
          {this.state.signInMessage ? (
            <Row>
              <p
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  fontFamily: "Montserrat",
                  textDecoration: "none",
                  color: colors.graphite,
                  marginTop: "20px",
                }}
              >
                Thank you for signing up. You will be the first to know about
                new arrivals and discounts. Stay tuned!
              </p>
            </Row>
          ) : (
              <Row style={{ textAlign: "left" }}>
                <form style={{ width: '100%', display: 'inherit' }} onSubmit={this.signUp.bind(this)}>
                  <Col>
                    {" "}
                    <UniversalInput
                      type="email"
                      style={{ width: "100%" }}
                      onChange={this.hendleEmialChange.bind(this)}
                      value={this.state.emailValue}
                      label="Email address"
                      errorMessage="This field cannot be empty"
                      isError={this.state.error}
                      required={true}
                    />
                  </Col>
                  <Col className="my-auto" style={{ padding: "7px" }} md={1}>
                    {" "}
                    <button
                      style={{
                        border: 'none',
                        background: 'none',
                        margin: 'none',
                        padding: 'none'
                      }}
                      type="submit">
                      <Cursor
                        size={30}
                        style={{
                          float: "right",
                          transform: "rotate(45deg)",
                          marginTop: "10px",
                          cursor: "pointer",
                        }}
                        color={colors.blue}
                      />
                    </button>
                  </Col>
                </form>
              </Row>
            )}
          <X
            color={colors.black}
            size={20}
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              cursor: "pointer",
            }}
            onClick={this.closeNewsletterPopup.bind(this)}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isMobile: state.isMobile, // (1)
    locale: state.locale,
    showNewsletterPopup: state.showNewsletterPopup,
  };
};
const mapDispatchToProps = {
  isMobileFetched,
  localeFetched,
  showNewsletterPopupFetched,
}; // (2)
export default connect(mapStateToProps, mapDispatchToProps)(NewsletterPopup);
