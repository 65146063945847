import React from "react";
import { Row, Col, Button, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import colors from "../../colors/colors";
import forMan1 from "../../img/forMan1.png";
import forMan2 from "../../img/forMan2.png";
import forMan3 from "../../img/forMan3.png";
import forMan4 from "../../img/forMan4.png";
import forManBackground from "../../img/forManBackground.png";


export default class ForMan extends React.Component {
  render() {
    return (
      <Row style={this.props.isMobile ? {marginTop: '50px'} : { marginBottom: '120px' }}>
        <Col sm={12} md={6}>
        {this.props.isMobile ? 
              <h3
                style={{
                  fontFamily: "Playfair Display",
                  fontSize: "24px",
                  fontWeight: 900,
                  marginBottom: '60px',
                  width: '100%',
                  textAlign: 'center'
                }}
              >
                Beauty has no gender
              </h3> : <></> }
          <Row style={{ backgroundImage: "url(" + forManBackground + ")", borderRadius: '0 10px 10px 0'}}>
            <Col style={this.props.isMobile ? {padding: 0} : {}} xs={3} sm={3}>
              <Image style={
                this.props.isMobile ?
                {width: '36vw',
                position: "relative",
                top: '-13px',
                left: '-10px',
              } :
                {
                position: "relative",
                top: '-28px',
                left: '-25px',
                width: '17vw',
              }} src={forMan1} />
            </Col>
            <Col style={this.props.isMobile ? {padding: 0} : {}} xs={3} sm={3}>
              <Image style={
                  this.props.isMobile ?
                  {
                  position: "relative",
                  top: '60px',
                  width: '30vw'
                } :
                {
                position: "relative",
                top: '125px',
                width: '13vw',
              }} src={forMan2} />
            </Col>
            <Col style={this.props.isMobile ? {padding: 0} : {}} xs={3} sm={3}>
              <Image style={
                  this.props.isMobile ?
                  {
                  position: "relative",
                  top: '-22px',
                  left: '-28px',
                  width: '44vw',
                } :
                {
                position: "relative",
                top: '-40px',
                left: '-40px',
                width: '20vw',
              }} src={forMan3} />
            </Col>
            <Col style={this.props.isMobile ? {padding: 0, overflow: 'hidden'} : {}} xs={3} sm={3}>
              <Image style={
                  this.props.isMobile ?
                  {
                  position: "relative",
                  top: '55px',
                  left: '7px',
                  width: '26vw',
                } :
                {
                position: "relative",
                top: '145px',
                left: '10px',
                width: '12vw',
              }} src={forMan4} />
            </Col>
          </Row>
        </Col>
        <Col sm={12} md={6}>
          <div
            style={
              this.props.isMobile ? {
                border: 'none',
                width: "100%",
                height: "100%",
              }:
              {
              borderRadius: "10px",
              width: "100%",
              height: "100%",
              border: "solid 2px rgba(18, 18, 18, 0.08)",
              textAlign: "center",
              position: 'relative',
            }}
          >
            <div xs={12} style={
              this.props.isMobile ? 
              {padding: '10%', textAlign: 'center'}:
              {
              position: 'absolute',
              top: '50%',
              transform: 'translateY(-50%)',
              padding: '10%'
            }}
            className={'align-self-center'}>
              {this.props.isMobile ? <></> : 
              <h3
                style={{
                  fontFamily: "Playfair Display",
                  fontSize: "24px",
                  fontWeight: 900,
                  marginBottom: '60px',
                }}
              >
                Beauty has no gender
              </h3> }
              <p
                style={{
                  fontFamily: "Montserrat",
                  fontSize: "16px",
                  marginBottom: '60px',
                  fontWeight: 500,
                }}
              >
                Men care about the way they look just as much as women do - that’s
                why we offer you a selection of products designed specifically for
                men.
            </p>
            <Link to='/mens-range' >
              <Button
                className='forManCTAButton'
                style={{
                  backgroundColor: colors.graphite,
                  borderRadius: "30px",
                  border: 'solid 2px #2e2e2e',
                  fontSize: '18px',
                  fontWeight: 700,
                  width: '250px',
                  paddingTop: '3px',
                  paddingBottom: '4px',
                }}
              >
                read more
              </Button>
            </Link>
            </div>
          </div>
        </Col>
      </Row>
    );
  }
}
