import React from 'react';

export default class LoaderSpinner extends React.Component{
    render(){
        return(
            <div class="lds-spinner">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>  
        )         
    }
}