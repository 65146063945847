import React from "react";
import UniversalInput from "./UniversalInput";
import { Row, Col, Button } from "react-bootstrap";
import CountrySelect from "./CountrySelect";
import colors from "../../colors/colors";
import { X } from "react-bootstrap-icons";

export default class ShippingInformation extends React.Component{
  render() {
    return (
      <div style={{marginTop: this.props.id == 0 ? '50px' : '100px'}}>
        <Row>
        <Col>
        <h3
          style={{
            fontSize: "18px",
            fontFamily: "Montserrat",
            fontWeight: 600,
            marginBottom: '15px',
            color: colors.blue,
          }}
        >
          Address {this.props.id + 1}
        </h3>
        </Col>
        <Col style={{textAlign: 'right'}}><X size={24} color={colors.blue} style={{cursor: 'pointer', marginTop: '-11px'}} onClick={() => this.props.deleteShippingInfo(this.props.id)} /></Col>
        </Row>
        <UniversalInput
          onChange={ (event) => {
            let address = this.props.address;
            address.name = event.target.value;
            this.props.saveSingleAddress(this.props.id, address);
          }}
          value={this.props.address.name}
          label={ this.props.accountType == 'corporate' ? "Name (optional)" : "Name"}
          isError={this.props.accountType == 'corporate' ? (this.props.hasBeenSubmited && (this.props.address.name == null || this.props.address.name.length < 2)) : false}
          errorMessage='This field cannot be empty'
        />
        <UniversalInput
         onChange={ (event) => {
          let address = this.props.address;
          address.surname = event.target.value;
          this.props.saveSingleAddress(this.props.id, address);
        }}
          value={this.props.address.surname}
          label={ this.props.accountType == 'corporate' ? "Surname (optional)" : "Surname"}
          isError={this.props.accountType == 'corporate' ? (this.props.hasBeenSubmited && (this.props.address.surname == null || this.props.address.surname.length < 2)) : false}
          errorMessage='This field cannot be empty'
        />
        <UniversalInput
         onChange={ (event) => {
          let address = this.props.address;
          address.companyName = event.target.value;
          this.props.saveSingleAddress(this.props.id, address);
        }}
          value={this.props.address.company_name}
          label={ this.props.accountType == 'corporate' ? "Company Name" : "Company Name (optional)"}
          isError={this.props.accountType == 'corporate' ? false : (this.props.hasBeenSubmited && (this.props.address.companyName == null || this.props.address.companyName < 2))}
          errorMessage='This field cannot be empty'
        />
        <UniversalInput
         onChange={ (event) => {
          let address = this.props.address;
          address.address = event.target.value;
          this.props.saveSingleAddress(this.props.id, address);
        }}
          value={this.props.address.address}
          label="Address"
          isError={this.props.hasBeenSubmited && (this.props.address.address == null || this.props.address.address.length < 2)}
          errorMessage='This field cannot be empty'
        />
        <UniversalInput
         onChange={ (event) => {
          let address = this.props.address;
          address.extraAddress = event.target.value;
          this.props.saveSingleAddress(this.props.id, address);
        }}
          value={this.props.address.extra_address}
          label="Address line 2 (opntional)"
        />
        <Row>
          <Col md={6} xs={12}>
            <UniversalInput
              onChange={ (event) => {
                let address = this.props.address;
                address.postalCode = event.target.value;
                this.props.saveSingleAddress(this.props.id, address);
}}            value={this.props.address.post_code}
              label="Postal code"
              isError={this.props.hasBeenSubmited && (this.props.address.post_code == null || this.props.address.post_code.length < 2)}
              errorMessage='This field cannot be empty'
            />
          </Col>
          <Col  md={6} xs={12}>
            <UniversalInput
               onChange={ (event) => {
                let address = this.props.address;
                address.city = event.target.value;
                this.props.saveSingleAddress(this.props.id, address);
              }}
              value={this.props.address.city}
              label="City"
              isError={this.props.hasBeenSubmited && (this.props.address.city == null || this.props.address.city.length < 2)}
              errorMessage='This field cannot be empty'
            />
          </Col>
        </Row>
        <CountrySelect
           onChange={ (event) => {
            let address = this.props.address;
            address.country = event.target.value;
            this.props.saveSingleAddress(this.props.id, address);
          }}
          value={this.props.address.country}
          label="Country"
          isError={this.props.hasBeenSubmited && (this.props.address.country == null || this.props.address.country.length < 2)}
          errorMessage='Please to choose country'
        />
        
      </div>
    );
  }
}
