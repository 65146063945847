import React from "react";
import { Form } from "react-bootstrap";
import colors from "../../colors/colors";
import { connect } from "react-redux";
import { isMobileFetched } from "../../actions";

class UniversalInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      validated: false,
      realMail: true,
    };
  }

  validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  inputValidation(){
    if(this.props.isEmail){
      this.setState({ validated: this.validateEmail(this.props.value), realMail: this.validateEmail(this.props.value)});
    }else if(this.props.value !== null && this.props.value.length > 2 ){
      this.setState({validated: true});
    }else{
      this.setState({validated: false});
    }
  }

  render() {
    return (
      <>
      <Form.Group style={{marginBottom: '30px',}}>
        <Form.Label
          style={this.props.isMobile ?{
            fontSize: "16px",
            fontFamily: "Montserrat",
            fontWeight: 500,
          }:
            {
            fontSize: "18px",
            fontFamily: "Montserrat",
            fontWeight: 500,
          }}
        >
          {this.props.label}
        </Form.Label>
        <Form.Control 
        name={this.props.name} 
        disabled={this.props.disabled} 
        style={{ backgroundColor: "#f5f5f5", border: this.props.isError  || !this.state.realMail? 'solid 1px #f19cb0' : this.state.validated ? 'solid 1px #35749066' : 'none' }} 
        type={this.props.type} 
        value={this.props.value} 
        onChange={this.props.onChange} 
        required={this.props.required || false}
        onBlur={this.inputValidation.bind(this)}
        />
      </Form.Group>
       {this.props.isError || !this.state.realMail? <p style={{
        fontSize: '10px',
         color: colors.darkPink, 
         width: '100%', 
         marginBottom: '4px',
         marginTop: '-16px',
         marginLeft: '10px',
         }}>{!this.state.realMail ? 'Please check your email address' : this.props.errorMessage}</p> : <></>}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isMobile: state.isMobile, // (1)
  };
};
const mapDispatchToProps = { isMobileFetched}; // (2)
export default connect(mapStateToProps, mapDispatchToProps)(UniversalInput);
