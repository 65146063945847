import React from "react/";
import colors from "../../colors/colors";
import {
  userFetched,
} from "../../actions";
import { connect } from "react-redux";

class CardMyAccount extends React.Component {
  render() {
    return (
      <div>
        <p
          style={{
            fontSize: "16px",
            color: colors.graphite,
            fontFamily: "Montserrat",
            fontWeight: 500,
          }}
        >
          Hello, {this.props.user.name}. Good to see you again.
        </p>
        <p
          style={{
            fontSize: "16px",
            color: colors.graphite,
            fontFamily: "Montserrat",
            fontWeight: 500,
          }}
        >
          Welcome to your Fräya account.
          <br />
          You can manage your data and orders using the tabs on the left hand
          side.
        </p>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
const mapDispatchToProps = {
  userFetched,
};
export default connect(mapStateToProps, mapDispatchToProps)(CardMyAccount);