import React from "react";
import { Row, Col } from "react-bootstrap";
import colors from "../colors/colors";
import { connect } from "react-redux";
import { isMobileFetched } from "../actions";

class CookiesPolicy extends React.Component {
  render() {
    return (
      <div style={{letterSpacing:'0.5px' }}>
        <Row>
          <Col style={this.props.isMobile ?{ paddingTop: "100px" }:{ paddingTop: "230px" }}>
            <h1
              style={this.props.isMobile ?{
                fontSize: "26px",
                color: colors.blue,
                fontFamily: "Raleway",
                fontWeight: 900,
                paddingBottom: "20px",
                textAlign:'center',
              }:
                {
                fontSize: "90px",
                color: colors.blue,
                fontFamily: "Raleway",
                fontWeight: 900,
                paddingBottom: "50px",
              }}
            >
              Cookies policy
            </h1>
            <h3
              style={{
                fontSize: "16px",
                color: colors.blue,
                fontFamily: "Montserrat",
                fontWeight: 700,
                marginTop: "10px",
                marginBottom: "20px",
              }}
            >
              I. General provisions
            </h3>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              1. The Frayamedsupply.com Website is operated by FRÄYA Group Sp. z
              o.o. with its registered office in Baltiq Plaza, Świętojańska
              43/18, 81-391 Gdynia, Poland.
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              2. The Website performs the functions of obtaining information
              about users and their behaviour in the following way: <br />
              • through information entered voluntarily in the forms <br />
              • by saving cookies on terminal devices <br />• by saving
              technical logs at the www server level
            </p>
            <h3
              style={{
                fontSize: "16px",
                color: colors.blue,
                fontFamily: "Montserrat",
                fontWeight: 700,
                marginTop: "10px",
                marginBottom: "20px",
              }}
            >
              II. Information about the forms
            </h3>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              1. The Website collects information provided voluntarily by the
              user.
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              2. The Website can also save information on connection parameters
              (time, IP address).
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              3. The Website can save information that will help to relate data
              introduced in the form with the User’s email address. In this
              case, the User’s email address will appear within the URL address
              of the page containing the form.
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              4. Data provided in the form are not shared with third parties
              without the consent of the user.
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              5. Data provided in the form may constitute a set of potential
              clients registered by the Website Operator in the register kept by
              the Inspector General for Personal Data Protection.
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              6. Data provided in the form are processed for the purpose
              resulting from a specificfunction of the form, e.g. in order to
              serve a website contact form or a commercial contact.
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              7. Data in the form may be forwarded to third parties responsible
              for performing technical services. In particular, it applies to
              providing information to payment service providers or any other
              parties with whom the Website Operator collaborates.{" "}
            </p>
            <h3
              style={{
                fontSize: "16px",
                color: colors.blue,
                fontFamily: "Montserrat",
                fontWeight: 700,
                marginTop: "10px",
                marginBottom: "20px",
              }}
            >
              III. Cookies information
            </h3>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              1. The Website uses cookies.
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              2. Cookies are IT data, in particular text files stored in the
              user’s terminal device and are designed to use the Operator’s
              websites. Usually, cookies contain the name of the website they
              come from, information on storage time in the terminal device and
              a unique number.
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              3. The Website Operator is the entity placing cookies in the
              Website User’s terminal device and getting access to them.
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              4. Cookies are used for the following purposes: <br />• to develop
              statistics enabling to find out how the Website users use the
              websites, which helps to improve its structure and content <br />
              • to determine the user’s profile in order to display matching
              advertising material, in particular the Google network’s
              advertising material <br />
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              5. Two basic types of cookies are used within the Website:
              “session cookies” and “persistent cookies”. Session cookies are
              temporary cookies that are stored in the user’s terminal device
              until the user logs off, leaves the website or closes the software
              (Internet browser). Persistent cookies are stored in the user’s
              terminal device for a time determined in the parameters of cookies
              or until the user removes them.
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              6. Usually, the software used for browsing websites allows by
              default to store cookies in the user’s terminal device. Website
              users may change it in settings of cookies. Internet browser
              allows to delete cookies. It is also possible to block cookies
              automatically. For more detailed information, consult the help for
              your browser or Internet browser documentation.{" "}
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              7. Restrictions on the use of cookies may influence certain
              functionalities available on the Operator’s Websites.
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              8. Cookies placed in the User’s terminal device may also be used
              by advertisers and associates collaborating with the Website
              Operator.
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              9. We recommend to read the privacy policy of these companies to
              know terms of cookies used in the statistics: The Google Analytics
              Privacy Policy.
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              10. Cookies may be used by advertising networks, in particular by
              the Google network, to display advertisements adjusted to the way
              the user uses the website. For this purpose, they may store
              information on the User’s navigation path or the time spent while
              visiting a given website.
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              11. Forms related cookies - when you submit any data to us through
              a form on our website, such as those found on contact pages or
              comment forms, cookies may be set to remember your user details
              for future correspondence.
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              12. Third-Party Cookies - in some special cases, we may also use
              cookies provided by trusted third parties. The following section
              details which third-party cookies you might encounter when using
              this website.
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              13. This website uses Google Analytics, which is one of the most
              widespread and trusted analytics solutions on the web, for helping
              us to understand how you use our website and ways that we can
              improve your experience. These cookies may track things such as
              how long you spend on the site and the pages that you visit, so we
              can continue to produce engaging content. For more information on
              Google Analytics cookies, see the official Google Analytics page.{" "}
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              14. As we sell products and services, it is important for us to
              understand statistics about how many of the visitors to our
              website actually make a purchase or engage with us through our
              website, and as such, this is the kind of data that these cookies
              will track. This is important to you as it means that we can
              accurately make business predictions that allow us to monitor our
              advertising and costs to ensure the best possible prices.{" "}
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              15. The Google AdSense service we use to serve advertising uses a
              DoubleClick cookie to serve more relevant ads across the web and
              limit the number of times that a given ad is shown to you. For
              more information on Google AdSense see the official Google AdSense
              privacy FAQ.{" "}
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              16. We may also use social media buttons and/or plugins on this
              website that allow you to connect to us with your social network
              in various ways. For these to work, the social media will set
              cookies throughout our website that may be used to enhance your
              profile on their websites or contribute to the data they hold for
              various purposes outlined in their respective privacy policies.
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              17. The user may view and edit information obtained by cookies
              within the scope of information on the User’s preferences
              collected by the Google network by using the tool:
              https://www.google.com/ads/preferences/
            </p>
            <h3
              style={{
                fontSize: "16px",
                color: colors.blue,
                fontFamily: "Montserrat",
                fontWeight: 700,
                marginTop: "10px",
                marginBottom: "20px",
              }}
            >
              IV. Server logs{" "}
            </h3>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              1. Information on certain users’ behaviours is subject to logging
              in the server layer. These data are used exclusively for the
              server management and in order to provide the most efficient
              hosting services.
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              2. The viewed content is identified through URL addresses.
              Additionally, the following data may be saved:
              <br />
              • query arrival time • response sending time • client’s station
              name – identification performed by the HTTP protocol <br />
              • information on errors occurred during the HTTP transaction • the
              URL address of the website previously visited by the user
              (referrer link) – if the Website was entered through a referrer
              link <br />
              • information of the User’s browser
              <br />
              • information on IP address <br />
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              3. The above data are not associated with any specific person
              browsing the websites.
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              4. The above data are used only for the purpose of the server
              administration.
            </p>
            <h3
              style={{
                fontSize: "16px",
                color: colors.blue,
                fontFamily: "Montserrat",
                fontWeight: 700,
                marginTop: "10px",
                marginBottom: "20px",
              }}
            >
              V. Data sharing{" "}
            </h3>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              1. Data are shared with third parties only within the scope
              allowed by the law.
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              2. Data allowing to identify an individual is shared only with the
              consent of this person.
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              3. The Operator may be obliged to provide information collected by
              the Website to authorised bodies on the basis of their lawful
              requests in the scope resulting from such requests.
            </p>
            <h3
              style={{
                fontSize: "16px",
                color: colors.blue,
                fontFamily: "Montserrat",
                fontWeight: 700,
                marginTop: "10px",
                marginBottom: "20px",
              }}
            >
              VI. Cookies management
            </h3>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              1. If the user does not want to receive cookies, he or she may
              change browser settings. Please note that disabling cookies
              necessary for authentication and safety processes and maintaining
              the User’s preferences may hinder and, in extreme cases, even make
              it impossible to use www websites.
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              2. Choose your Internet browser/system to manage cookies settings and follow the instructions.
            </p>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isMobile: state.isMobile, // (1)
  };
};
const mapDispatchToProps = { isMobileFetched}; // (2)
export default connect(mapStateToProps, mapDispatchToProps)(CookiesPolicy);