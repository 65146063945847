import React from "react";
import { Row, Col } from "react-bootstrap";
import colors from "../colors/colors";
import Request from "./../api/Request";
import { connect } from "react-redux";
import { isMobileFetched } from "../actions";

class PaymentInfoPage extends React.Component {
  render() {
    return (
      <div style={{letterSpacing:'0.5px',}}>
        <Row style={{ marginBottom: "20px" }}>
        
          <Col style={this.props.isMobile ?{ paddingTop: "100px" }:{ paddingTop: "230px" }}>
            <h1
              style={this.props.isMobile ?{
                fontSize: "26px",
                color: colors.blue,
                fontFamily: "Raleway",
                fontWeight: 900,
                textAlign:'center',
              }:
                {
                fontSize: "90px",
                color: colors.blue,
                fontFamily: "Raleway",
                fontWeight: 900,
                paddingBottom: "50px",
              }}
            >
              Payment
            </h1>
            </Col>
        </Row>
        <Row>
            <Col md={2}></Col>
            <Col>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
             Be free to choose payment via PayPal, Credit/Debit card or bank transfer. Here are our bank account details: IBAN SWIFT / BIC
            </p>
            <h3
              style={{
                fontSize: "16px",
                color: colors.blue,
                fontFamily: "Montserrat",
                fontWeight: 700,
                marginTop: "10px",
                marginBottom: "20px",
              }}
            >
              REFUND POLICY
            </h3>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
             There will be no refunds on products sold. Defective products will be given a one-to-one exchange within 14 days upon receipt of the item. Proof of purchase shall be presented.
            </p>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isMobile: state.isMobile, // (1)
    cart: state.cart,
    user: state.user,
  };
};
const mapDispatchToProps = { isMobileFetched}; // (2)
export default connect(mapStateToProps, mapDispatchToProps)(PaymentInfoPage);