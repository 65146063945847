import React from "react";
import { Form, Row, Col, InputGroup } from "react-bootstrap";
import colors from "../../colors/colors";

export default class PhoneInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      validated: false,
    };
  }

  inputValidation() {
    if (this.props.value !== null && this.props.value.length > 2) {
      this.setState({ validated: true });
    } else {
      this.setState({ validated: false });
    }
  }

  render() {
    return (
      <>
        <Form.Group style={{ marginBottom: "30px" }}>
          <Form.Label
            style={{
              fontSize: "18px",
              fontFamily: "Montserrat",
              fontWeight: 500,
            }}
          >
            {this.props.label}
          </Form.Label>
          <Row>
            <Col md={2} xs={3} style={{ paddingRight: "0px" }}>
              {" "}
              <InputGroup >
              <InputGroup.Prepend 
                style={{
                  backgroundColor: "#f5f5f5",
                  border: this.props.isError
                    ? "solid 1px #f19cb0"
                    : this.state.validated
                    ? "solid 1px #35749066"
                    : "none",
                    borderWidth: this.props.isError
                    ? "1px 0px 1px 1px"
                    : this.state.validated
                    ? "1px 0px 1px 1px"
                    : "none",
                    borderRadius: '5px 0 0 5px'
                }}
              >
          <InputGroup.Text  style={{paddingLeft: '12px', paddingRight: '12', background: 'none', border: "none"}}>+</InputGroup.Text>
        </InputGroup.Prepend>
              <Form.Control
                className="no-spinner"
                autoComplete='nldslnres'
                name={this.props.name}
                disabled={this.props.disabled}
                style={{
                  paddingLeft: '0px',
                  backgroundColor: "#f5f5f5",
                  border: this.props.isError
                    ? "solid 1px #f19cb0"
                    : this.state.validated
                    ? "solid 1px #35749066"
                    : "none",
                  borderWidth: this.props.isError
                    ? "1px 1px 1px 0px"
                    : this.state.validated
                    ? "1px 1px 1px 0px"
                    : "none",
                }}
                type='number'
                value={parseInt(this.props.countryNumber)}
                onChange={this.props.handleCountryNumberChange}
                required={this.props.required || false}
                // onBlur={this.inputValidation.bind(this)}
              /></InputGroup>
            </Col>
            <Col>
              {" "}
              <Form.Control
                className="no-spinner"
                name={this.props.name}
                disabled={this.props.disabled}
                style={{
                  backgroundColor: "#f5f5f5",
                  border: this.props.isError
                    ? "solid 1px #f19cb0"
                    : this.state.validated
                    ? "solid 1px #35749066"
                    : "none",
                }}
                type='number'
                value={this.props.value}
                onChange={this.props.onChange}
                required={this.props.required || false}
                onBlur={this.inputValidation.bind(this)}
              />
            </Col>
          </Row>
        </Form.Group>
        {this.props.isError ? (
          <p
            style={{
              fontSize: "10px",
              color: colors.darkPink,
              width: "100%",
              marginBottom: "4px",
              marginTop: "-16px",
              marginLeft: "10px",
            }}
          >
            {this.props.errorMessage}
          </p>
        ) : (
          <></>
        )}
      </>
    );
  }
}
