import React from "react";
import { Row, Col} from "react-bootstrap";
import colors from "../colors/colors";
import { connect } from "react-redux";
import { isMobileFetched } from "../actions";

class PrivacyPolicy extends React.Component {
  render() {
    return (
      <div style={{letterSpacing:'0.5px'}}>
        <Row>
          <Col style={this.props.isMobile ?{ paddingTop: "100px" }:{ paddingTop: "230px" }}>
            <h1
              style={this.props.isMobile ?{
                fontSize: "26px",
                color: colors.blue,
                fontFamily: "Raleway",
                fontWeight: 900,
                paddingBottom: "20px",
                textAlign:'center',
              }:
                {
                fontSize: "90px",
                color: colors.blue,
                fontFamily: "Raleway",
                fontWeight: 900,
                paddingBottom: "50px",
              }}
            >
              Privacy policy
            </h1>
            <h3
              style={{
                fontSize: "16px",
                color: colors.blue,
                fontFamily: "Montserrat",
                fontWeight: 700,
                marginTop: "10px",
                marginBottom: "20px",
              }}
            >
              I. General provisions
            </h3>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              1. These “General terms of use by individuals of the
              frayamedsupply.com website” determine the terms of free of charge
              use by individuals of the website titled “frayamedsupply.com”
              where FRÄYA Group puts information related to legal and financial
              topics.
            </p>
            <h3
              style={{
                fontSize: "16px",
                color: colors.blue,
                fontFamily: "Montserrat",
                fontWeight: 700,
                marginTop: "10px",
                marginBottom: "20px",
              }}
            >
              II. Definitions
            </h3>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              1. The expressions used in “General terms of use by individuals of
              the frayamedsupply.com website” shall have the following meanings
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              2. FRÄYA Group means the FRÄYA Group Sp z o.o. with its registered
              office in Baltiq Plaza Świętojańska 43/18, 81-391 Gdynia, Poland.
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              3. Website – means the Website “frayamedsupply.com”
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              4. Website Resources – mean the materials shared according to the
              terms determined in “General terms of use by individuals of the
              frayagroup.com website” including, in particular, information,
              articles and contact forms of the website users. The information
              service is a part of the website resources.
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              5. The user is obliged to use the website in accordance with legal
              regulations, the provisions of these “General terms of use by
              individuals of the frayagroup.com website” and good practices.
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              6. The user is entitled to use the website resources only for
              personal purposes. It means, in particular, that it is not
              admissible to use the data and the materials contained in the
              website resources, both those protected by copyrights and those
              not protected, in order to run a commercial activity.
            </p>
            <h3
              style={{
                fontSize: "16px",
                color: colors.blue,
                fontFamily: "Montserrat",
                fontWeight: 700,
                marginTop: "10px",
                marginBottom: "20px",
              }}
            >
              III. Terms of access to the website resources by individual users
            </h3>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              1. It is not required to complete any formalities by the
              individual user to access the part of the website resources
              defined as the information service. In particular, it is not
              required to register in the website system.
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              2. FRÄYA Group recommends using the browsers enabling cookies and
              JavaScript to be turned on.
            </p>
            <h3
              style={{
                fontSize: "16px",
                color: colors.blue,
                fontFamily: "Montserrat",
                fontWeight: 700,
                marginTop: "10px",
                marginBottom: "20px",
              }}
            >
              IV. Obligations
            </h3>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              1. FRÄYA Group provides the individual users with free of charge
              access to the information service resources for an indefinite
              time.
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              2. The User is obliged to visit the website in accordance with
              legal regulations, the provisions of these “General terms of use
              by individuals of the frayagroup.com website” and good practices.
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              3. The user is entitled to use the website resources only for
              personal use. It means, in particular, that it is not admissible
              to use the data and the materials contained in the website
              resources, both those protected by copyrights and those not
              protected, in order to run a commercial activity.
            </p>
            <h3
              style={{
                fontSize: "16px",
                color: colors.blue,
                fontFamily: "Montserrat",
                fontWeight: 700,
                marginTop: "10px",
                marginBottom: "20px",
              }}
            >
              V. FRÄYA Group responsibility
            </h3>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              1. FRÄYA Group declares that the materials and information
              contained in the website resources are collected and drawn up with
              due diligence. The presented information does not constitute an
              offer as understood in article 71 of the Civil Code (Dz. U. of
              1964, No. 16, item 93).
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              2. All the materials including information, specifications and
              illustrations contained in this website are only for information
              purposes and may not be treated as offer in any specific case.
              FRÄYA Group does not assume responsibility for any errors or
              shortages.
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              3. FRÄYA Group is not responsible for lack of access to the
              website caused by telecommunication systems breakdown or any other
              effects of the malfunction of telecommunication connections and
              the resulting damage.
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              4. FRÄYA Group reserves the right to alter or delete material from
              this Site at any time without notice. Please check often for
              updates, as we reserve the right, in our sole discretion, to
              modify these Terms of Use at any time, and your use of the Site
              indicates your acceptance of any revisions to these terms.
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              5. FRÄYA Group’s failure to enforce any right or provision of
              these Terms of Use shall not constitute a waiver of such right or
              provision. If any provision of these Terms of Use is unlawful,
              void, or unenforceable, then it shall be severed and shall not
              affect the validity and enforceability of any Terms of Use. If any
              provision of these Terms of Use is held to be invalid or
              unenforceable in any jurisdiction, such provision shall be deemed
              modified to the minimum extent necessary so that such provision
              shall no longer be held to be invalid or unenforceable, and these
              Terms of Use shall be interpreted so as to achieve the intent
              expressed herein to the greatest extent possible in the
              jurisdiction in question. Any such modification, invalidity or
              unenforceability shall be strictly limited both to such provision
              and to such jurisdiction.
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              6. FRÄYA Group may assign its ownership, control, or other rights
              to any party at any time without any notice to you. Your rights
              and duties cannot be assigned without FRÄYA Group’s written
              consent. Nothing in these Terms of Use provides any third party
              with any remedy, claim, or cause of action.
            </p>
            <h3
              style={{
                fontSize: "16px",
                color: colors.blue,
                fontFamily: "Montserrat",
                fontWeight: 700,
                marginTop: "10px",
                marginBottom: "20px",
              }}
            >
              VI. Processing of the users’ personal data
            </h3>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              1. Based on separate consents given by the data subject by ticking
              the appropriate box in the registration form, the user’s personal
              data will be processed by FRÄYA Group in order to: • Offer
              products andservices of FRÄYA Group and entities cooperating with
              FRÄYA Group
              <br />• Send commercial information in compliance with article 10
              section 2 of the Act of 18 July 2002 on services provided by
              electronic means (Dz. U. of 2013, item 1422, as amended)
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              2. Your personal data is administered by FRÄYA with its registered
              office in Baltiq Plaza, Świętojańska 43/18, 81-391 Gdynia, Poland.
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              3. Your personal data will be processed for marketing purposes and
              with your consent voluntarily given in order to offer products and
              services of entities collaborating with the administrator and in
              order to send commercial information. Based on your consents given
              separately, the personal data provided in the form may be shared
              with entities collaborating with the administrator. Your personal
              data will also be processed for archiving purposes. Providing your
              personal data is voluntary. You have the right to access and
              correct your data.
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              4. Pursuant to article 7 point 5 of the Act on personal data
              protection, the user has the right to cancel the consent to the
              processing of his or her data by the Data Administrator at any
              time. You can exercise this right by sending the appropriate
              request via electronic mail at admin@frayamedsupply.com containing
              the user’s name and surname.
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              5. The user may refuse or give consent to receive commercial
              information by electronic means by ticking the appropriate box, in
              accordance with the Act of 18 July 2002 on services provided by
              electronic means (Dz. U. of 2013, item 1422, as amended). If the
              user gave his or her consent to receive commercial information, he
              or she has the right to cancel this consent at any time. You can
              exercise this right by sending the appropriate request via
              electronic mail to the Data Administrator at
              admin@frayamedsupply.com, containing the user’s name and surname.
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              6. FRÄYA Group does not transmit, sell or rent the collected
              personal data of the users to other persons or institutions unless
              explicit consent was given or the user asked it to do so or if
              authorised state bodies request it for the purpose of proceedings
              that are carried out.
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              7. For reasons of providing the Website users with the highest
              quality of services, phone calls with FRÄYA Group helpline
              consultants may be registered, to which the User gives the consent
              at any time when contacting FRÄYA Group by telephone using the
              phone numbers contained in the Website.
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              8. Legislation requires your personal information to be kept
              secure and confidential. We store your personal information on
              servers with high security levels, which are located in controlled
              facilities, and our security is checked regularly to determine
              whether our user information is securely handled, always taking
              your rights as a user into account. However, we cannot 100%
              guarantee security for data transmissions through the internet.
              This means that there may be a risk that other unauthorized
              parties gain access to information when data is sent and stored
              electronically. Thus, your personal information is submitted at
              your own risk. In case of a security breach that poses a high risk
              for you, for discrimination, ID theft, financial loss, loss of
              reputation or other significant inconvenience, we will notify you
              of the security breach as soon as possible.
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              9. We protect your personal information and have adopted internal
              guidelines for information security that contain measures that
              protect your personal information against unauthorized disclosure
              and prevent unauthorized persons from gaining access or knowledge
              to the information. To avoid any loss of data, we perform backups
              on a regular basis. We also protect the confidentiality and
              authenticity of your data by encryption when we send out data from
              our network.
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              10. Personal data is deleted or anonymized continuously as the
              purpose for which it was collected is terminated.
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              11. The rapid development of the internet means that changes in
              our processing of personal data may be necessary. Thus, we reserve
              the right to update and amend these guidelines for processing of
              personal data. If we do, we will of course update the “last
              updated” date at the bottom of the page. In case of substantial
              changes, we will notify you in terms of a visible notice on our
              website.
            </p>
            <h3
              style={{
                fontSize: "16px",
                color: colors.blue,
                fontFamily: "Montserrat",
                fontWeight: 700,
                marginTop: "10px",
                marginBottom: "20px",
              }}
            >
              VII. Final provisions
            </h3>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              1. The contents of this Website are protected by copyright. The
              contents of the Website are designed for personal and
              non-commercial use and may not be reproduced, distributed and
              published unless the owner of this website, i.e. FRÄYA Group gives
              a previous written consent to it. Any activities that infringe
              copyrights are prohibited.
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              2. Commercial names, names of products, solutions or any other
              indications contained in this website are registered trademarks
              and/or are reserved by their owners and were used only for
              information purposes.
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              3. These regulations constitute general agreement terms as
              understood by the provisions of the civil code.
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              4. None of the Parties may transfer the rights resulting from the
              Agreement to third parties without a written consent of the other
              Party.
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              5. Any remarks, comments and questions concerning the functioning
              of the Website may be sent at e-mail: admin@frayamedsupply.com
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              6. Information about the result of the complaint procedure is sent
              to the interested Parties by a letter or by e-mail within 14 days
              from the explanation of the presented case. The complaint
              procedure shall not take more than 2 months from the day of
              presenting the complaint.
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              7. The Parties undertake to attempt to resolve amicably any
              disputes arising from or in connection with exercising this
              Agreement.
            </p>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              8. If no amicable solution is reached, disputes between the
              Parties shall be resolved by the Commercial Court of territorial
              and material jurisdiction.
            </p>
          </Col>
        </Row>
      
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isMobile: state.isMobile, // (1)
  };
};
const mapDispatchToProps = { isMobileFetched}; // (2)
export default connect(mapStateToProps, mapDispatchToProps)(PrivacyPolicy);