import React from "react";
import colors from "../../colors/colors";
import { ReactComponent as CheckCircle } from "../../svg/checkCircle.svg";
import { Row, Col, Image, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import ListingPage from "../../pages/ListingPage";
import Price from "../general/Price";
import Request from "../../api/Request";

export default class AddedToCartPopup extends ListingPage {

  constructor(props) {
    super(props);
    this.state = {
      closeTimeout: null
    };
  }
  close() {
    window.hideTopInfo();
  }
  render() {
    const paragraph = {
      fontSize: "12px",
      color: colors.graphite,
      fontFamily: "Montserrat",
      fontWeight: 500,
      marginBottom: 0,
    };

    const paragraphBold = {
      fontSize: "12px",
      color: colors.graphite,
      fontFamily: "Montserrat",
      fontWeight: 600,
      marginBottom: 0,
      width: '100%',
    };

    return (
      <div
        onMouseEnter={this.props.mouseEnter}
        onMouseLeave={this.props.mouseLeave}
        className={this.props.isMobile ? this.props.showTopInfoClassMobile : this.props.showTopInfoClass}
        style={
          this.props.isMobile ? {
            backgroundColor: colors.white,
            height: "max-content",
            width: "100vw",
            borderRadius: "5px",
            position: "fixed",
            top: "0px",
            right: "0",
            padding: "10px",
            zIndex: 99999,
            transition: "1s",
            border: "solid",
            borderColor: 'rgba(54, 118, 145, 0.2)',
            borderWidth: "1px",
            height: '100vh',
          }
            :
            {
              backgroundColor: colors.white,
              height: "max-content",
              width: "250px",
              borderRadius: "5px",
              position: "fixed",
              top: "90px",
              right: "15px",
              padding: "10px",
              zIndex: 100,
              transition: "1s",
              border: "solid",
              borderColor: 'rgba(54, 118, 145, 0.2)',
              borderWidth: "1px",
            }}
      >
        <div style={{ display: "flex" }}>
          <h3
            style={{
              fontSize: "16px",
              color: colors.black,
              fontFamily: "Montserrat",
              fontWeight: 700,
              marginTop: "5px",
              marginBottom: "20px",
              width: '100%',
            }}
          >
            Added to cart:
            <span onClick={this.close} style={{ cursor: 'pointer' }} className="float-right">X</span>
          </h3>
        </div>
        <div className='productsWrapper' style={{ heigth: 'max-content', maxHeight: this.props.isMobile ? '40vh' : '150px', overflowY: 'scroll', overflowX: 'hidden' }}>
          {this.props.cart.map((product, id) => {
            return (
              <Row key={id} style={{ marginBottom: '15px' }}>
                <Col md={4} style={this.props.isMobile ? { textAlign: 'center' } : {}}>
                  <Image style={{ width: this.props.isMobile ? '70%' : "100%" }} src={Request.realProductImage(product.small_image)} />
                </Col>
                <Col style={{ paddingTop: "5px" }}>
                  <p style={paragraphBold}>{product.name}</p>
                  <p style={paragraph}><Price price={product.price} /></p>
                  <p style={paragraph}>Qty {product.quantity}</p>
                </Col>
              </Row>
            );
          })}
        </div>
        <Row style={{ margin: "15px 0px", paddingTop: "10px" }}>
          <Col md={4} style={{ padding: 0 }}>
            <p style={paragraphBold}>Subtotal:</p>
          </Col>
          <Col>
            <p style={paragraphBold}><Price price={this.rawTotal()} /></p>
          </Col>
        </Row>
        <Link to="/cart">
          <Button
            className="payPalButton"
            onClick={this.close}
            style={{
              width: "100%",
              backgroundColor: colors.white,
              borderColor: colors.lightBlue,
              borderWidth: 2,
              fontFamily: "Montserrat",
              fontWeight: 700,
              color: colors.lightBlue,
              marginBottom: "15px",
              boxShadow: '0px 5px 15px -6px rgba(53, 116, 144, 0.66)',
            }}
          >
            View cart
          </Button>
        </Link>

        <Link to="/pre-checkout">
          <Button
            className="payPalButton"
            onClick={this.close}
            style={{
              width: "100%",
              backgroundColor: colors.white,
              borderColor: colors.lightBlue,
              borderWidth: 2,
              fontFamily: "Montserrat",
              fontWeight: 700,
              color: colors.lightBlue,
              marginBottom: "5px",
              boxShadow: '0px 5px 15px -6px rgba(53, 116, 144, 0.66)',
            }}
          >
            Checkout
          </Button>
        </Link>
      </div>
    );
  }
}
