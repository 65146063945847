import React from "react";
import { Row, Col } from "react-bootstrap";
import colors from "../colors/colors";
import ProductOnList from "../components/productList/ProductOnList";
import { connect } from "react-redux";
import {
  isMobileFetched,
  cartFetched,
  userFetched,
  currentCategoryFetched,
  searchValueFetched,
  currentTypeFetched
} from "../actions";
import Request from "../api/Request";

class BrandProductListPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showTopInfo: false,
      showMobileInfo: false,
      addedProductName: "",
      addedQuantity: "",
      products: [],
    };
  }

  componentDidMount() {
    this.setState({ user: this.props.user });
    Request.getProducts((response) => {
      if (response.success) {
        this.setState({ products: response.products });
      }
    });

    if (!this.props.currentCategory || !this.props.currentCategory.brandId) {
      if (
        !this.props.match ||
        !this.props.match.params ||
        !this.props.match.params.brandUri
      ) {
        this.props.history.push("/");
      } else {
        Request.getCategoryByUri(
          this.props.match.params.brandUri,
          (response) => {
            if (response.success) {
              this.props.currentCategoryFetched(response.productType.brand);
              this.props.currentTypeFetched(response.productType.id);
            } else {
              this.props.history.push("/");
            }
          }
        );
      }
    }
  }

  ucFirst(){
    if(this.props.currentCategory.brandName){
    let brandName = this.props.currentCategory.brandName.toLowerCase();
    return brandName[0].toUpperCase() + brandName.slice(1);}
  }

  render() {
    return (
      <>
        <Row style={{ paddingTop: "175px" }}>
          <Col md={2}></Col>
          <Col>
            <h3
              style={{
                fontSize: "24px",
                color: colors.blue,
                fontFamily: "Raleway",
                fontWeight: 900,
                marginTop: "10px",
                marginBottom: "20px",
              }}
            >
              {this.ucFirst()}
            </h3>
            <p
              style={{
                fontSize: "16px",
                color: colors.graphite,
                fontFamily: "Montserrat",
                fontWeight: 500,
                textAlign: 'justify',
              }}
              dangerouslySetInnerHTML={{ __html: this.props.currentCategory.brandDescription }}
            >
            </p>
          </Col>
          <Col md={2}></Col>
        </Row>
        <Row style={{ marginBottom: "-40px" }}>
          <Col md={2}></Col>
          <Col>
            <Row>
              {this.state.products
                .filter(this.props.searchValue != "" ? (product) => product.name.toLowerCase().includes(this.props.searchValue) : (product) => product.type == this.props.currentType)
                .filter((product) => product.brand == this.props.currentCategory.brandId)
                .map((product, id) => {
                  return (
                    <ProductOnList
                      product={product}
                      key={id}
                      name={product.name}
                      image={product.small_image}
                      altTag={product.alt_tag}
                      price={product.price}
                      productId={id}
                    />
                  );
                })}
            </Row>
          </Col>
          <Col md={2}></Col>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isMobile: state.isMobile, // (1)
    cart: state.cart,
    user: state.user,
    currentCategory: state.currentCategory,
    searchValue: state.searchValue,
    currentType: state.currentType,
  };
};
const mapDispatchToProps = {
  isMobileFetched,
  cartFetched,
  userFetched,
  currentCategoryFetched,
  currentTypeFetched,
  searchValueFetched,
}; // (2)
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BrandProductListPage);
