import React from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import colors from "../../colors/colors";
import BlueButton from "../general/BlueButton";

export default class CardHelp extends React.Component {

  expandTextarea(id) {
    document.getElementById(id).addEventListener('keyup', function() {
        this.style.overflow = 'hidden';
        this.style.height = 0;
        this.style.height = this.scrollHeight + 'px';
    }, false);
}

componentDidMount(){
  this.expandTextarea('txtarea');
}


  render() {
    return (
      <div>
        <p
          style={{
            fontSize: "18px",
            color: colors.graphite,
            fontFamily: "Montserrat",
            fontWeight: 900,
            marginBottom: '10px',
          }}
        >
          How can we help you?
        </p>
        <Row>
          <Col md={6}>
            <Form.Group style={{ marginBottom: "20px" }}>
              <Form.Control
                id='txtarea'
                as="textarea"
                style={{ backgroundColor: "#f5f5f5", border: 'none', height: '36px' }}
                type={this.props.type}
                value={this.props.value}
                onChange={this.props.onChange}
              />
            </Form.Group>
          </Col>
          <Col>
            <BlueButton 
              content='Ask us'
            />
          </Col>
        </Row>
      </div>
    );
  }
}
